import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Link, useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import api from '../services/api';
import Privacy from '../signup/login/privacy-policy';
import '../signup/login/user.css';
import axios from 'axios';
import LOGO2 from '../assests/b2bjob logo 1.png';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

function LoginModal({ onClose }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);

  const handleForgotPasswordClick = () => {
    window.location.href = 'https://btobjob.com/forgot_password_pro.php';
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await api.LoginPro(email, password, rememberMe);
      if (response.redirect) {
        window.location.href = response.redirect;
      } else if (response.user) {
        localStorage.setItem('userId', response.user.id);
        window.location.href = "https://btobjob.com/boost.php";
      }
    } catch (error) {
      console.error('Login failed', error);
      setError(
        error.response?.data?.error || 'Login failed. Please check your credentials.'
      );
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const decodedToken = jwtDecode(credentialResponse.credential);
      const { name, email, sub: googleId } = decodedToken;
      const response = await axios.post(
        'https://btobjob.com/google-signup.php',
        { name, email, googleId },
        { withCredentials: true }
      );
      if (response.data.status === 'success') {
        localStorage.setItem('email', email);
        window.location.href = "https://btobjob.com/boost.php";
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Google Login Error:', error);
      alert('Failed to sign up via Google!');
    }
  };

  const handleGoogleFailure = (error) => {
    console.error('Google Sign-In Error:', error);
    alert('Failed to sign in with Google!');
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="relative w-full max-w-md bg-white p-4 rounded-lg shadow-md"
        style={{
          margin: 'auto',
          maxHeight: '80vh',
        }}
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 bg-gray-100 p-1 rounded-full text-gray-600 text-xs"
        >
          ✕
        </button>

        <div className="flex flex-col space-y-4">
          <div className="p-3 rounded-lg shadow-md text-center">
            <img src={LOGO2} alt="Logo" className="mx-auto mb-2 w-16" />

            <h4 className="text-[#75C00A] font-extrabold text-lg">
              Connexion Particulier
            </h4>
            <p className="text-[#75C00A] text-xs">Nous sommes heureux de vous voir</p>

            <form onSubmit={handleLogin} className="space-y-3">
              <div>
                <label
                  className="block text-[#75C00A] text-xs font-semibold mb-1"
                  htmlFor="email"
                >
                  E-mail
                </label>
                <input
                  className="w-full rounded-lg p-2 border border-gray-300 text-sm"
                  type="email"
                  id="email"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div className="relative">
                <label
                  className="block text-[#75C00A] text-xs font-semibold mb-1"
                  htmlFor="password"
                >
                  Passe
                </label>
                <input
                  className="w-full rounded-lg p-2 border border-gray-300 text-sm"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span
                  className="absolute right-3 top-8 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </span>
              </div>

              {error && <div className="text-red-500 text-xs">{error}</div>}

              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="remember"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  className="form-checkbox h-3 w-3"
                />
                <label
                  htmlFor="remember"
                  className="text-xs text-[#75C00A] font-semibold"
                >
                  Souviens-toi de moi
                </label>
              </div>

              <button
                type="submit"
                className="w-full py-2 rounded-lg bg-gradient-to-r from-[#75C00A] to-[#375A05] text-white text-sm font-semibold"
              >
                Se connecter
              </button>
            </form>

            <div className="text-center text-xs space-y-2 mt-2">
              <button
                className="text-[#75C00A] font-semibold"
                onClick={handleForgotPasswordClick}
              >
                Mot de passe oublié?
              </button>
              <p className="text-[#75C00A]">ou</p>
              <p className="text-[#65A406]">Vous n'avez pas de compte ?</p>
              <Link
                to="/individualsignup"
                className="block py-1.5 rounded-lg border-2 border-[#75C00A] text-[#65A406] text-sm font-semibold"
              >
                S'inscrire
              </Link>
            </div>

            <div className="text-center mt-2">
              <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleFailure}
                  theme="outline"
                  size="small"
                />
              </GoogleOAuthProvider>
            </div>

            <p className="text-center text-xs text-[#65A406] mt-2">
              As-tu besoin d'aide? Contactez le support technique.
            </p>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
}

export default LoginModal;
