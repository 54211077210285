import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { IoShareSocialOutline } from "react-icons/io5";
import { FaArrowLeft } from "react-icons/fa";
import api from '../../services/api';
import CommentList from './CommentList';
import Share from '../Share';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const ForumDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [topic, setTopic] = useState(null);
  const [comments, setComments] = useState([]);
  const [isOwner, setIsOwner] = useState(false); 
  const [newComment, setNewComment] = useState('');
  const [openShareModal, setOpenShareModal] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch current user session
        const userResponse = await api.getSession();
        const currentUser = userResponse.user;
        setCurrentUserId(currentUser.id);

        // Fetch topic details
        const topicResponse = await api.fetchForumTopicById(id);
        setTopic(topicResponse);

        // Check if the logged-in user is the owner of the topic
        setIsOwner(currentUser.id === topicResponse.owner_id);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [id]);

  const handleDelete = async () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce sujet ?')) {
      try {
        const response = await fetch(`https://btobjob.com/delete_topic.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          credentials: 'include', // Include cookies for session
          body: new URLSearchParams({ id }), // Send the topic ID
        });
        const result = await response.json();

        if (result.success) {
          alert('Sujet supprimé avec succès');
          navigate('/profile/forum'); // Redirect to the forum listing
        } else {
          alert(`Error deleting topic: ${result.message}`);
        }
      } catch (error) {
        console.error('Error deleting topic:', error);
      }
    }
  };

  useEffect(() => {
    if (!id) return;

    const fetchComments = async () => {
      try {
        const commentsResponse = await api.fetchComments(id);
        setComments(commentsResponse || []);
      } catch (error) {
        console.error('Erreur lors de la récupération des commentaires :', error);
      }
    };

    fetchComments();

   
  }, [id]);

  const handleCommentSubmit = () => {
    const commentData = { topic_id: id, comment: newComment };

    api.submitComment(commentData)
      .then(response => {
        if (response.success) {
          const newCommentObject = {
            id: response.id,
            comment: newComment,
            professional_name: response.professional_name,
            profile_picture: response.profile_picture,
          };
          setComments(prevComments => [...prevComments, newCommentObject]);
          setNewComment('');
          window.location.reload();
        } else {
          console.error('Erreur lors de l\'ajout du commentaire :', response.message);
        }
      })
      .catch(error => {
        console.error('Erreur lors de l\'ajout du commentaire :', error);
      });
  };


  const handleViewProfile = () => {
    if (topic && topic.professional_id) {
      navigate(`/profile/specificUser?professional_id=${topic.professional_id}`);
    }
  };

  if (!topic) {
    return <p>Chargement...</p>;
  }

  let imagesArray = [];
  if (typeof topic.images === 'string') {
    imagesArray = topic.images
      .split(',')
      .map((img) => img.trim())
      .filter((img) => img !== '' && img.toLowerCase() !== 'null');
  }
  
  // 2) Conditionally render images
  const renderImages = imagesArray.length > 0 ? (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
      {imagesArray.map((image, index) => (
        <img
          key={index}
          src={`https://btobjob.com/${image}`}
          alt={`Image ${index}`}
          className="h-100 w-full object-contain rounded-md"
          // Optional fallback to avoid infinite error loops if fallback also fails
          onError={(e) => {
            if (!e.target.src.includes('default-placeholder.png')) {
              e.target.src = 'https://btobjob.com/default-placeholder.png';
            }
          }}
        />
      ))}
    </div>
  ) : (
    <p>Aucune image disponible</p>
  );

  const currentUrl = window.location.href;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{topic.heading || "Détails du sujet"}</title>
          <meta name="description" content={topic.description || "Consultez les détails de ce sujet."} />
          <meta name="keywords" content={`${topic.heading}, forum, discussions`} />
          <meta property="og:title" content={topic.heading || "Détails du sujet"} />
          <meta property="og:description" content={topic.description || "Consultez les détails de ce sujet."} />
          <meta property="og:image" content={imagesArray[0] || "default-image-url.jpg"} />
        </Helmet>
      </HelmetProvider>
      <div className="m-2 md:mx-auto md:mt-12 max-w-6xl">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
        >
          <FaArrowLeft className="inline mr-2" /> Retour
        </button>

        {/* Topic Container */}
        <div className="bg-white rounded-md p-4">
          {/* Top Section: Profile & Buttons */}
          <div className="flex flex-wrap md:flex-nowrap items-center mb-6 gap-4">
            {/* Poster Profile Picture */}
            {topic.poster_profile_picture ? (
              <img
                src={topic.poster_profile_picture.trim()}
                alt={`Photo de profil de ${topic.poster_name}`}
                className="w-12 h-12 rounded-full mr-3"
                onError={(e) => (e.target.src = 'https://btobjob.com/assets/profile.webp')}
              />
            ) : (
              <div className="w-12 h-12 bg-gray-300 rounded-full mr-3" />
            )}

            {/* Poster Name */}
            <div className="flex-1 min-w-[150px]">
              <p className="text-lg font-semibold">{topic.poster_name}</p>
              <p className="text-sm text-gray-500">Publié par</p>
            </div>

            {/* Buttons Group */}
            <div className="flex flex-wrap sm:flex-nowrap gap-2">
              <button
                className="px-4 py-2 bg-lime-500 text-white rounded-md hover:bg-lime-600 transition duration-300"
                onClick={handleViewProfile}
              >
                Voir le profil
              </button>
              <button
                onClick={() => setOpenShareModal(true)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition duration-300"
              >
                <IoShareSocialOutline className="inline mr-2" /> Partager
              </button>
              {topic.canDelete && (
                <button
                  onClick={handleDelete}
                  className="ml-auto px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                >
                  Delete
                </button>
              )}
            </div>
          </div>

          <p className="text-2xl font-bold mb-4">{topic.heading}</p>
          <p className="mb-4">{topic.description}</p>
          {renderImages}

          <div className="mt-6">
            <h3 className="text-xl font-bold mb-2">Commentaires</h3>
            <CommentList comments={comments} />
          </div>

          <div className="mt-4">
            <h4 className="text-lg font-bold mb-2">Ajouter un commentaire</h4>
            <textarea
              className="w-full p-2 border rounded-md"
              rows="4"
              value={newComment}
              onChange={e => setNewComment(e.target.value)}
            />
            <button
              className="mt-2 px-4 py-2 bg-lime-500 text-white rounded-md"
              onClick={handleCommentSubmit}
            >
              Envoyer
            </button>
          </div>
        </div>

        {openShareModal && (
          <Share
            profileUrl={currentUrl}
            closeModal={() => setOpenShareModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default ForumDetail;
 