import React from 'react';
import { PiTelegramLogoLight, PiWhatsappLogoLight } from "react-icons/pi";
import { MdAlternateEmail } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaXTwitter, FaLinkedin } from "react-icons/fa6";

const PostShare = ({ isOpen, onClose, shareUrl }) => {
    if (!isOpen) return null;

    const currentUrl = shareUrl || window.location.href;

    // Function to copy the URL to the clipboard
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(currentUrl);
            alert('Link copied to clipboard!');
        } catch (error) {
            alert('Unable to copy link. Please copy manually.');
        }
    };

    // Function to open a new window with fallback for Safari
    const openShareLink = (url) => {
        const newWindow = window.open(url, '_blank');
        if (!newWindow) {
            // Fallback for Safari popup blocking
            window.location.href = url;
        }
    };

    return (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black/50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[400px] relative">
                {/* Close button */}
                <button className="absolute top-4 right-4 text-gray-500 cursor-pointer" onClick={onClose}>
                    ✕
                </button>

                {/* Title */}
                <h2 className="text-lg font-semibold mb-4 text-center">Partager avec</h2>

                {/* Social Sharing Icons */}
                <div className="flex justify-around mb-6 flex-wrap gap-4">
                    <div
                        onClick={() =>
                            openShareLink(
                                `mailto:?subject=${encodeURIComponent('Check this out')}&body=${encodeURIComponent(currentUrl)}`
                            )
                        }
                        className="flex flex-col items-center cursor-pointer"
                    >
                        <MdAlternateEmail className="h-6 w-6" />
                        <span className="mt-2 text-sm">E-mail</span>
                    </div>
                    <div
                        onClick={() => openShareLink(`https://wa.me/?text=${encodeURIComponent(currentUrl)}`)}
                        className="flex flex-col items-center cursor-pointer"
                    >
                        <PiWhatsappLogoLight className="h-6 w-6" />
                        <span className="mt-2 text-sm">WhatsApp</span>
                    </div>
                    <div
                        onClick={() => openShareLink(`https://t.me/share/url?url=${encodeURIComponent(currentUrl)}`)}
                        className="flex flex-col items-center cursor-pointer"
                    >
                        <PiTelegramLogoLight className="h-6 w-6" />
                        <span className="mt-2 text-sm">Telegram</span>
                    </div>
                    <div
                        onClick={() => openShareLink(`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`)}
                        className="flex flex-col items-center cursor-pointer"
                    >
                        <FaXTwitter className="h-6 w-6" />
                        <span className="mt-2 text-sm">X</span>
                    </div>
                    <div
                        onClick={() => openShareLink(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`)}
                        className="flex flex-col items-center cursor-pointer"
                    >
                        <FaFacebook className="h-6 w-6" />
                        <span className="mt-2 text-sm">Facebook</span>
                    </div>
                    <div
                        onClick={() => openShareLink(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}`)}
                        className="flex flex-col items-center cursor-pointer"
                    >
                        <FaLinkedin className="h-6 w-6" />
                        <span className="mt-2 text-sm">LinkedIn</span>
                    </div>
                </div>

                {/* Link Copy Section */}
                <div className="text-center mb-4 text-gray-500">Copier le lien</div>
                <div className="flex items-center bg-gray-100 p-2 rounded-lg">
                    <input
                        type="text"
                        value={currentUrl}
                        readOnly
                        className="bg-gray-100 text-gray-700 text-sm w-full focus:outline-none"
                    />
                    <button onClick={copyToClipboard} className="bg-red-100 text-red-500 p-2 rounded-full ml-2">
                        <IoShareSocialOutline className="h-6 w-6" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PostShare;
