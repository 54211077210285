import React, { useState, useEffect, useRef } from "react";
import { FaArrowLeft, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaShareAlt } from "react-icons/fa";

import { useNavigate, useLocation } from "react-router-dom";
import api from "../../services/api";
import QRCode from "qrcode.react";
import Star from "../../assests/Star.png";
import LOGO2 from "../../assests/b2bjob logo 1.png";
import axios from "axios";
import html2canvas from "html2canvas";
import { HelmetProvider, Helmet } from "react-helmet-async";
import jsPDF from "jspdf";
import Share from "./ShareCard";
const BusinessCard = () => {
  const [profileData, setProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [avgRating, setAvgRating] = useState(0);
  const [images, setImages] = useState([null, null, null]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const cardRef = useRef(null);
  const [shareItemType, setShareItemType] = useState('');
  const [openShareModal, setOpenShareModal] = useState(false);
  const flyerRef = useRef(null);
  const [photos, setPhotos] = useState([]);
  const [error, setError] = useState(null);
  const fullStars = Math.floor(avgRating);
  
  const halfStar = avgRating % 1 !== 0 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStar;

  const NavigateToBack = () => {
    navigate(-1);
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
const professionalId = queryParams.get("professional_id");
const itemTypeFromQuery = queryParams.get("itemType");

    useEffect(() => {
      const fetchProfileData = async () => {
        try {
          const response = await api.fetchProfileById(professionalId);
          setProfileData(response);
        } catch (error) {
          console.error("Error fetching profile data:", error);
        } finally {
          setIsLoading(false);
        }
      };
  
      if (professionalId) {
        fetchProfileData();
      } else {
        setIsLoading(false);
      }
    }, [professionalId]);
  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await axios.get("https://btobjob.com/get_photos.php", {
          withCredentials: true,
        });
        if (Array.isArray(response.data)) {
          setPhotos(response.data.slice(0, 3));
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (error) {
        console.error("Error fetching photos:", error);
        setError("Error fetching photos");
      } finally {
        setLoading(false);
      }
    };

    fetchPhotos();
  }, []);

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const response = await axios.get("https://btobjob.com/get-user-reviews.php", {
          withCredentials: true,
        });
        const data = response.data;
        const avgRating =
          data.avg_rating !== null && data.avg_rating !== undefined
            ? parseFloat(data.avg_rating)
            : 0;

        setAvgRating(avgRating);
      } catch (error) {
        console.error("Error fetching ratings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRatings();
  }, []);

  const handlePrintCard = () => {
    const printContents = cardRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // Reload the page to reset it after printing
  };

  const handlePrintFlyer = () => {
    const printContents = flyerRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // Reload the page to reset it after printing
  };

  const handleDownloadPDF = async (ref, filename) => {
    const canvas = await html2canvas(ref.current);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    // Calculate width and height for the PDF page
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`${filename}.pdf`);
  };

  const handleDownloadCardPDF = () => handleDownloadPDF(cardRef, "Business_Card");
  const handleDownloadFlyerPDF = () => handleDownloadPDF(flyerRef, "Flyer");

  const handleImageChange = (index, e) => {
    const newImages = [...images];
    newImages[index] = URL.createObjectURL(e.target.files[0]);
    setImages(newImages);
  };
  useEffect(() => {
    if (!itemTypeFromQuery) {
      // If no itemType is specified, show both sections
      setShareItemType('all'); // A new state to indicate both sections should render
    } else {
      setShareItemType(itemTypeFromQuery);
    }
  }, [itemTypeFromQuery]);
  const renderStars = () => {
    const fullStars = Math.floor(avgRating);
    const halfStar = avgRating % 1 !== 0 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;
    return (
      <>
        {Array(fullStars)
          .fill()
          .map((_, index) => (
            <img key={index} src={Star} alt="Star" className="w-3 h-3" />
          ))}
        {halfStar ? <img src={Star} alt="Half Star" className="w-3 h-3 opacity-50" /> : null}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <img key={index} src={Star} alt="Empty Star" className="w-3 h-3 opacity-20" />
          ))}
      </>
    );
  };
  const currentUrl = window.location.href.replace(/#/g, '');
  if (isLoading || loading) {
    return <div>Loading...</div>;
  }

  if (!profileData) {
    return <div>Profile data not found.</div>;
  }
  const truncateText = (text, limit) => {
    const words = text.split(' ');
    if (words.length > limit) {
      return words.slice(0, limit).join(' ') + '...';
    }
    return text;
  };
  const handleShareClick = (itemType) => {
    setOpenShareModal(true);
    setShareItemType(itemType); // Assume setShareItemType is a useState hook to manage itemType
};
  const profileImagePath = `https://btobjob.com/profile/${profileData.ProfilePicture || "default-profile.jpg"}`;
  const coverImagePath = `https://btobjob.com/profile/${profileData.CoverPicture || "default-cover.jpg"}`;
  const profileUrl = `https://btobjob.com/profile/specificUser?professional_id=${profileData.ID}`;

  return (
    <>
      <HelmetProvider>

        <title>{profileData.Name || "Carte de visite professionnelle"}</title>
        <meta
          name="description"
          content={
            profileData.bio ||
            "Consultez les détails du profil professionnel et téléchargez la carte de visite ou le flyer."
          }
        />
        <meta
          name="keywords"
          content={`Carte de visite, Flyer, ${profileData.Name || "Professionnel"}, ${profileData.city}`}
        />
        <meta property="og:title" content={profileData.Name || "Carte de visite professionnelle"} />
        <meta
          property="og:description"
          content={
            profileData.bio ||
            "Découvrez les services, les coordonnées et les évaluations de ce professionnel."
          }
        />
        <meta property="og:image" content={profileImagePath} />
        <meta property="og:url" content={currentUrl} />
      </HelmetProvider>
    <div className="md:mx-auto md:max-w-3xl lg:max-w-4xl bg-white rounded-lg md:mt-12 m-2 mx-2">
      <div className="flex flex-col">
        {/* Navbar Section */}
        <div className="p-6 border-b-2 flex flex-col print:hidden">
          <div className="flex items-center">
            <FaArrowLeft onClick={NavigateToBack} className="h-6 w-6 pr-2 cursor-pointer" />
            <p className="text-xl font-semibold">Mes Cartes de visite</p>
          </div>
        </div>
        

        {(shareItemType === 'business-card' || shareItemType === 'all') && (
        <div className="flex flex-col items-center justify-center my-8 relative card-section-unique" ref={cardRef}>
          <p className="absolute -top-7 left-4 font-semibold card-preview-title">Aperçu</p>

       
          <div className="rounded-lg shadow-lg p-6 max-w-[360px]  relative bg-cover  bg-center business-card-unique">
      
            <div className="flex justify-between items-center relative z-10 profile-info-unique">
              <div>
                <h2 className="text-xs font-bold profile-name-unique">{profileData.Name}</h2>
                <div className="flex items-center mt-1 profile-stars-unique">
         
                  <span className="text-xs font-bold mr-2">{avgRating.toFixed(1)}</span>
    {Array(fullStars)
      .fill()
      .map((_, index) => (
        <img key={index} src={Star} alt="Star" className="w-3 h-3" />
      ))}
    {halfStar ? <img src={Star} alt="Half Star" className="w-3 h-3 opacity-50" /> : null}
    {Array(emptyStars)
      .fill()
      .map((_, index) => (
        <img key={index} src={Star} alt="Empty Star" className="w-3 h-3 opacity-20" />
      ))}
                </div>
                <p className="text-gray-700 text-[10px] text-xs profile-job-title-unique">{truncateText(profileData.job_title, 5)} </p>
              </div>

            
              <div className="flex items-center mt-1 logo-profile-image-unique">
                <img
                  src={profileImagePreview || profileImagePath}
                  alt="Profile"
                  className="w-10 h-10 sm:h-16 sm:w-16 rounded-full profile-image-unique"
                />
              </div>
            </div>

          
            <div className="mt-4 relative z-10 contact-info-unique">
              <div className="flex items-start justify-between contact-details-unique">
                {/* Contact Details */}
                <div className="contact-texts-unique">
                  <p className="text-gray-700 text-[10px] sm:text-[12px] flex items-center contact-email-unique">
                    <FaEnvelope className="text-gray-700 w-3 h-3 mr-1" />
                    <span>{profileData.Email}</span>
                  </p>
                  <p className="text-gray-700 text-[10px] sm:text-[12px] flex items-center contact-phone-unique">
                    <FaPhoneAlt className="text-gray-700 w-3 h-3 mr-1" />
                    <span>{profileData.Phone}</span>
                  </p>
                  <p className="text-gray-700 text-[10px] sm:text-[12px] flex items-center contact-address-unique">
                    <FaMapMarkerAlt className="text-gray-700 w-3 h-3 mr-1" />
                    <span>
                      {profileData.Address},  {profileData.city}, {profileData.zip}
                    </span>
                  </p>
                  <a
                    href={profileUrl}
                    className="text-blue-500 text-[10px] sm:text-[10px] flex items-center contact-profile-url-unique"
                  >
                    <FaEnvelope className="w-3 h-3 mr-1" />
                    <span>{`https://btobjob.com/profile/specificUser?${profileData.Name}`}</span>
                  </a>
                </div>

                {/* QR Code */}
                <div className="qr-code-unique">
                <QRCode
value={profileUrl}
size={50}
fgColor="#004225"
includeMargin={true}
renderAs="svg" // Ensure the QR code is rendered as a canvas for print
/>
                </div>
       

              </div>
            </div>
          </div>
      
     
          {/* Download and Print Buttons for Business Card */}
          <div className="mt-8 flex justify-center print-button-container-unique print:hidden">
            <button
              onClick={handlePrintCard}
              className="text-sm bg-gray-300 py-2 px-4 rounded-full text-gray-600 font-semibold print-button-unique"
            >
              Imprimer une carte de visite
            </button>
            <button
              onClick={handleDownloadCardPDF}
              className="text-sm bg-gray-300 py-2 px-4 rounded-full text-gray-600 font-semibold ml-2"
            >
              Télécharger la carte au format PDF
            </button>
            <button onClick={() => handleShareClick('business-card')} className="bg-gray-300 py-2 px-4 rounded-full text-gray-600 font-semibold ml-2 sm:ml-2">
  
  Partager
        </button>
          </div>
        </div>
   )}
     {(shareItemType === 'flyer' || shareItemType === 'all') && (
        <div className="flex flex-col items-center justify-center my-8 relative print:show" ref={flyerRef}>
<p className="absolute top-0 left-4 font-semibold">Aperçu</p>

<div className="w-full max-w-4xl bg-gray-100 p-4 sm:p-12 rounded-lg text-center mx-auto">
  <h1 className="text-2xl sm:text-4xl font-bold text-gray-800 mb-4">{profileData.Name || "Nom de l'entreprise"}</h1>
  <p className="text-xl sm:text-2xl text-gray-600">{profileData.Phone || "Téléphone"}</p>

  {/* Images Section */}
  <div className="mt-8 flex justify-center space-x-2 sm:space-x-4">
    {photos.map((photo, index) => (
      <div key={index}>
       
        <label htmlFor={`file-input-${index}`}>
          <img
            src={`https://btobjob.com/${photo.photo_url}`}
            alt={`User Photo ${index + 1}`}
            className="w-24 sm:w-48 h-24 sm:h-48 object-cover border"
          />
        </label>
      </div>
    ))}
  </div>

  {/* Description Paragraph */}
  <p className="mt-6 text-gray-700 text-lg sm:text-2xl">
    {profileData.bio || "Ajouter une biographie à afficher ici"}
  </p>
  <div className="flex justify-center mx-auto mt-4">
    <span className="font-bold mr-2">{avgRating.toFixed(1)}</span>
    {Array(fullStars)
      .fill()
      .map((_, index) => (
        <img key={index} src={Star} alt="Star" className="w-4 sm:w-6 h-4 sm:h-6" />
      ))}
    {halfStar ? <img src={Star} alt="Half Star" className="w-4 sm:w-6 h-4 sm:h-6 opacity-50" /> : null}
    {Array(emptyStars)
      .fill()
      .map((_, index) => (
        <img key={index} src={Star} alt="Empty Star" className="w-4 sm:w-6 h-4 sm:h-6 opacity-20" />
      ))}
  </div>
  {/* Flex Section for Contact and QR Code */}
  <div className="flex flex-col sm:flex-row justify-between mt-10">
    {/* Contact Information */}
    <div className="text-center sm:text-end space-y-4">
      <p className="text-gray-800 font-bold flex justify-center sm:justify-end items-center text-md sm:text-lg">
        <FaPhoneAlt className="mr-3" />
        {profileData.Phone || "Téléphone non fourni"}
      </p>
      <p className="text-gray-600 flex justify-center sm:justify-end items-center text-sm sm:text-md">
        <FaMapMarkerAlt className="mr-3" />
        {profileData.Address || "Adresse non fournie"}, {profileData.city},  {profileData.zip}
      </p>
      <p className="text-gray-600 flex justify-center sm:justify-end items-center text-md sm:text-lg">
        <FaEnvelope className="mr-3" />
        {profileData.Email || "Email non fourni"}
      </p>
    </div>

    {/* QR Code Section */}
    <div className="mt-4 sm:mt-0 sm:ml-8">
      <p className="text-gray-800 font-bold mb-4 text-md sm:text-lg">Référencé sur</p>
      <QRCode
value={profileUrl}
size={100}
fgColor="#004225"
includeMargin={true}
renderAs="svg"  // Ensure the QR code is rendered as a canvas for print
/>
      <p className="text-gray-600 text-sm sm:text-lg mt-4">
        Scannez ce QR Code pour accéder à :
        <br />- Mon profil complet
        <br />- Mes coordonnées de contact
        <br />- Mes services et offres
        <br />- Les avis de mes clients
      </p>
    </div>
  </div>
</div>

{/* Download and Print Buttons for Flyer */}
<div className="mt-10 flex flex-col sm:flex-row justify-center print:hidden">
  <button onClick={handlePrintFlyer} className="bg-gray-300 py-2 px-4 rounded-full text-gray-600 font-semibold">
    Imprimer Flyer
  </button>
  <button onClick={handleDownloadFlyerPDF} className="bg-gray-300 py-2 px-4 rounded-full text-gray-600 font-semibold ml-2 sm:ml-2">
    Télécharger le dépliant au format PDF
  </button>
  <button onClick={() => handleShareClick('flyer')} className="bg-gray-300 py-2 px-4 rounded-full text-gray-600 font-semibold ml-2 sm:ml-2">
    Partager
  </button>
</div>
</div>   )}

{openShareModal && (
  <Share
      profileUrl={currentUrl}
      closeModal={() => setOpenShareModal(false)}
      itemType={shareItemType} // this is set by handleShareClick
  />
)}
      </div>
    </div>
    </>
  );
};

export default BusinessCard;
