import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SearchableDropdown from '../SearchableDropdown';
import { HelmetProvider } from 'react-helmet-async';
const API_URL = 'https://btobjob.com';

const AdsAndRequests = () => {
  const [ads, setAds] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [radius, setRadius] = useState('10'); // Default radius i
  const [cities, setCities] = useState([]); // State for citiess 10
  const [searchTheme, setSearchTheme] = useState('');
  const [profession, setProfession] = useState(''); // Profession filter
  const [urgencyFilter, setUrgencyFilter] = useState('all');
  const [loading, setLoading] = useState(false);
  const [isCityLoading, setIsCityLoading] = useState(false); 
  const [hasMore, setHasMore] = useState(true);
    const [clearInput, setClearInput] = useState(false); 
  const [showFilters, setShowFilters] = useState(false);
  const [noResultsMessage, setNoResultsMessage] = useState(''); // Message for no results

  const itemsPerPage = 12;
  const navigate = useNavigate();
  const fetchInitialCities = async () => {
    try {
      setIsCityLoading(true);
      const response = await axios.get('https://geo.api.gouv.fr/communes', {
        params: {
          fields: 'nom,codesPostaux',
          format: 'json',
          geometry: 'centre',
          limit: 100, // Fetch only 100 cities
        },
      });
      const cityData = response.data.map((region) => ({
        name: region.nom,
        postalCode: region.codesPostaux || 'N/A', // Handle cases where postal code is missing
      })).sort((a, b) => a.name.localeCompare(b.name));
      setCities(cityData);
    } catch (error) {
      console.error('Error fetching cities:', error);
    } finally {
      setIsCityLoading(false);
    }
  };
  useEffect(() => {
    fetchInitialCities();
  }, []);
  
  const fetchCitySuggestions = async (query) => {
    try {
      if (query.length < 3) return; // Fetch suggestions only if the query is 3+ characters
      setIsCityLoading(true);
      const response = await axios.get('https://geo.api.gouv.fr/communes', {
        params: {
          fields: 'nom,codesPostaux',
          format: 'json',
          geometry: 'centre',
          nom: query, // Use query to search for cities
          limit: 100,
        },
      });
      const citySuggestions = response.data.map((region) => ({
        name: region.nom,
        postalCode: region.codesPostaux || 'N/A',
      })).sort((a, b) => a.name.localeCompare(b.name));
      setCities(citySuggestions);
    } catch (error) {
      console.error('Error fetching city suggestions:', error);
    } finally {
      setIsCityLoading(false);
    }
  };
  

  
  const fetchCoordinates = async (city) => {
    try {
      const response = await axios.get(
        `https://btobjob.com/geocode.php`,
        {
          params: {
            q: city,
            format: 'json',
            addressdetails: 1,
           
          },
        }
      );
      if (response.data.lat && response.data.lon) {
        console.log('Coordinates for city:', response.data);
        return { lat: parseFloat(response.data.lat), lon: parseFloat(response.data.lon) };
      }
      throw new Error('City not found');
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      alert('Unable to fetch coordinates for the city. Please try another city.');
      return null;
    }
  };
  const handleCitySelect = async (city) => {
    setSelectedCity(city);
    setLatitude(null);
    setLongitude(null);
};
  const fetchAdsAndRequests = async (page, applyFilters = false) => {
    setLoading(true);
    try {
      const params = {
        page: currentPage,
        itemsPerPage,
        search: searchTheme || undefined,
        profession: profession || undefined, // Add profession filter
        urgency: urgencyFilter !== 'all' ? urgencyFilter : undefined,
        radius: radius || undefined,
        location: selectedCity || undefined,
        latitude: latitude || undefined,
        longitude: longitude || undefined,
      };

      console.log('API Request Params:', params);

      const { data } = await axios.get(`${API_URL}/fetch-all-ads.php`, {
        params,
        withCredentials: true,
      });

      console.log('API Response:', data);

      if (data && data.results) {
        setAds((prev) =>
          currentPage === 1 ? data.results.ads : [...prev, ...data.results.ads]
        );
        setHasMore(data.results.ads.length > 0);
        setSuggestions(data.results.suggestions || []);
        setNoResultsMessage(
          currentPage === 1 && data.results.ads.length === 0
            ? data.results.message || `No results available for ${selectedCity}`
            : ''
        );
      } else {
        setHasMore(false);
        setNoResultsMessage(`No results available for ${selectedCity}`);
      }
    } catch (error) {
      console.error('Error fetching ads:', error);
      setHasMore(false);
      setNoResultsMessage(`Error fetching data for ${selectedCity}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdsAndRequests();
  }, [currentPage, radius, searchTheme, profession, urgencyFilter, latitude, longitude]);

  const handleItemClick = (id) => {
    navigate(`/profile/AdsDetails?ad_id=${id}`);
  };
  const clearFilters = () => {
    setSelectedCity('');
    setLatitude(null);
    setLongitude(null);
    setRadius('');
    setSearchTheme('');
    setProfession('');
    setUrgencyFilter('all');
    setCurrentPage(1); // Reset to the first page
    setAds([]); // Clear existing ads
    setNoResultsMessage('');
    setClearInput(true); // Trigger input clearing for SearchableDropdown
    fetchAdsAndRequests(); // Fetch ads with default filters
  
    // Reset the clearInput state after clearing
    setTimeout(() => setClearInput(false), 0);
  };
  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setSelectedCity(''); // Clear selected city
          setCurrentPage(1); // Reset to the first page
          console.log('Using current location:', {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          fetchAdsAndRequests();
        },
        (error) => {
          console.error('Error fetching location:', error);
          alert('Unable to fetch your current location. Please allow location access.');
        }
      );
    } else {
      alert('Geolocation is not supported by your browser.');
    }
  };

  const handleSearchByCity = async () => {
    if (selectedCity) {
      setIsCityLoading(true); // Start loading
      setCurrentPage(1);
      console.log('Searching by city:', selectedCity);
  
      const coordinates = await fetchCoordinates(selectedCity);
      if (coordinates) {
        setLatitude(coordinates.lat);
        setLongitude(coordinates.lon);
        fetchAdsAndRequests();
      }
      setIsCityLoading(false); // Stop loading
    } else {
      alert('Veuillez sélectionner une ville avant de lancer la recherche.');
    }
  };
  const decodeHtmlEntities = (str) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = str;
    return txt.value;
  };
  return (
    <>
      <HelmetProvider>
        <title>Annonces et Demandes | BtoBJob</title>
        <meta
          name="description"
          content="Parcourez les annonces et demandes professionnelles. Filtrez par métier, ville, urgence, et plus."
        />
        <meta property="og:title" content="Annonces et Demandes | BtoBJob" />
        <meta
          property="og:description"
          content="Découvrez les opportunités professionnelles à proximité ou dans une ville spécifique. Filtrez et trouvez ce dont vous avez besoin."
        />
        <meta property="og:image" content="https://btobjob.com/logo.png" />
        <meta property="og:url" content="https://btobjob.com/profile/ads" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Annonces et Demandes | BtoBJob" />
        <meta
          name="twitter:description"
          content="Trouvez les meilleures annonces et demandes professionnelles autour de vous."
        />
        <meta name="twitter:image" content="https://btobjob.com/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </HelmetProvider>
    <div className="max-w-6xl mx-auto p-3 mt-10">
    {/* Hamburger menu for filters */}
    <div className="flex justify-between items-center mb-6">
    <h1 className="text-2xl font-bold">Annonces</h1>
    <div className="flex gap-4">
        <button
            className="bg-gray-800 text-white px-3 py-2 rounded-md focus:outline-none"
            onClick={() => setShowFilters((prev) => !prev)}
        >
            ☰ Filtres
        </button>
       
    </div>
</div>
    {/* Filters section */}
    {showFilters && (
      <div className="mb-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mx-auto">
  {/* Radius Input */}
  <div className="flex flex-col">
    <label htmlFor="radius" className="text-sm font-semibold mb-1">
      Rayon (km): Tous: {radius || 'Toute'}
    </label>
    <input
      type="number"
      id="radius"
      min="0"
      max="100"
      value={radius}
      onWheel={(e) => e.target.blur()} // Disable scroll increment/decrement
      onChange={(e) => setRadius(e.target.value || '')}
      className="w-full px-3 py-2 text-sm border border-gray-300 rounded"
    />
  </div>

  {/* Search by Theme */}
  <div className="flex flex-col">
    <label htmlFor="theme-search" className="text-sm font-semibold mb-1">
      Recherche par mots-clés
    </label>
    <input
      type="text"
      id="theme-search"
      value={searchTheme}
      onChange={(e) => setSearchTheme(e.target.value)}
      className="w-full px-3 py-2 text-sm border border-gray-300 rounded"
    />
  </div>

  {/* Profession Dropdown */}
  <div className="flex flex-col">
    <label htmlFor="profession" className="text-sm font-semibold mb-1">
      Sélectionnez un métier
    </label>
    <select
      id="profession"
      value={profession}
      onChange={(e) => setProfession(e.target.value)}
      className="w-full px-3 py-2 text-sm border border-gray-300 rounded"
    >
      <option value="">Sélectionnez une profession</option>
       <option value="Agent immobilier">Agent immobilier</option>
  <option value="Architecte/décorateur">Architecte/décorateur</option>
  <option value="Autre Maçon">Autre Maçon</option>
  <option value="Carrossier/Peintre">Carrossier/Peintre</option>
  <option value="Charpentier/Couvreur">Charpentier/Couvreur</option>
  <option value="Chauffagiste/frigoriste">Chauffagiste/frigoriste</option>
  <option value="Chauffeur/Livreur">Chauffeur/Livreur</option>
  <option value="Commercial">Commercial</option>
  <option value="Community manager">Community manager</option>
  <option value="Conciergerie">Conciergerie</option>
  <option value="Cuisiniste">Cuisiniste</option>
  <option value="Décoration">Décoration</option>
  <option value="Déménagement">Déménagement</option>
  <option value="Électricien">Électricien</option>
  <option value="Entreprise de sécurité">Entreprise de sécurité</option>
  <option value="Entreprise de nettoyage/menge">Entreprise de nettoyage/ménage</option>
  <option value="Carreleur">Carreleur</option>
  <option value="Facadier">Facadier</option>
  <option value="Formateur/formatrice">Formateur/formatrice</option>
  <option value="Garde d’animaux">Garde d’animaux</option>
  <option value="Informaticien/développeur">Informaticien/développeur</option>
  <option value="Jardinier">Jardinier</option>
  <option value="Maintenance/Bricolage">Maintenance/Bricolage</option>
  <option value="Maçonnerie">Maçonnerie</option>
  <option value="Manutention/débarras">Manutention/débarras</option>
  <option value="Mécanicien">Mécanicien</option>
  <option value="Menuisier">Menuisier</option>
  <option value="Métier de secrétariat">Métier de secrétariat</option>
  <option value="Nettoyage automobile">Nettoyage automobile</option>
  <option value="Nettoyage de vitres">Nettoyage de vitres</option>
  <option value="Organisateur d’évènement">Organisateur d’évènement</option>
  <option value="Paysagiste/jardinier">Paysagiste/jardinier</option>
  <option value="Peintre">Peintre</option>
  <option value="Peintre/décorateur">Peintre/décorateur</option>
  <option value="Plaquiste/plâtrier">Plaquiste/plâtrier</option>
  <option value="Plomberie">Plomberie</option>
  <option value="Poseur de piscine">Poseur de piscine</option>
  <option value="Réparateur électronique">Réparateur électronique</option>
  <option value="Restauration">Restauration</option>
  <option value="Serrurier/dépannage">Serrurier/dépannage</option>
  <option value="Services à la personne">Services à la personne</option>
  <option value="Terrassement">Terrassement</option>
  <option value="Ménage">Ménage</option>
  <option value="Rénovation">Rénovation</option>
  <option value="Chauffeur VTC">Chauffeur VTC</option>
  <option value="Traiteur">Traiteur</option>
    </select>
  </div>

  {/* City Dropdown */}
  <div className="flex flex-col">
    <label htmlFor="city" className="text-sm font-semibold mb-1">
      Sélectionnez la ville
    </label>
    <SearchableDropdown
         options={cities.map((city) => ({
          label: `${city.name} (${city.postalCode})`, // Combine city name and postal code
          value: city.name,
        }))}
        selectedOption={selectedCity}
        setSelectedOption={setSelectedCity}
        fetchSuggestions={fetchCitySuggestions} // Pass the function correctly here
        placeholder="Rechercher ou sélectionner"
        clearInput={clearInput}
      />

  </div>

  {/* Urgency Filter */}
  <div className="flex flex-col">
    <label htmlFor="urgency" className="text-sm font-semibold mb-1">
      Filtre d'urgence
    </label>
    <select
      id="urgency"
      value={urgencyFilter}
      onChange={(e) => setUrgencyFilter(e.target.value)}
      className="w-full px-3 py-2 text-sm border border-gray-300 rounded"
    >
      <option value="all">Tout</option>
      <option value="urgent">Urgent</option>
      <option value="non-urgent">Non-Urgent</option>
    </select>
  </div>

  {/* Buttons */}
  <div className="mt-4 flex gap-4 col-span-full justify-start">
  <button
    className="bg-blue-500 text-white px-3 py-2 rounded-md w-full"
    onClick={handleUseCurrentLocation}
  >
    Géolocalisation de ma position
  </button>
  <button
    className={`bg-green-500 text-white px-3 py-2 rounded-md w-full ${
      isCityLoading ? 'opacity-70 cursor-not-allowed' : ''
    }`}
    onClick={handleSearchByCity}
    disabled={isCityLoading}
  >
    {isCityLoading ? 'Loading...' : 'Rechercher'}
  </button>
  <button
    className="bg-red-500 text-white px-3 py-2 rounded-md w-full"
    onClick={clearFilters}
  >
    Réinitialiser les filtres
  </button>
</div>
</div>


      )}

      {/* No Results Message */}
      {noResultsMessage && (
        <div className="text-center text-lg font-semibold text-red-500 mt-6">
          {noResultsMessage}
        </div>
      )}

      {/* Ads section */}
      {ads.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
         {ads.map((ad) => (
  <div
    key={ad.id} 
              className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow cursor-pointer"
              onClick={() => handleItemClick(ad.id)}
            >
              {ad.image && (
                <img
                  src={ad.image}
                  alt={ad.title}
                  className="w-full h-52 object-cover rounded-md mb-4"
                />
              )}
              <h2 className="text-lg font-bold mb-2">{decodeHtmlEntities(ad.title)}</h2>
              {ad.profession && (
                <p className="text-sm text-gray-700">
                  <span className="font-bold"></span>{decodeHtmlEntities(ad.created_at)}
                </p>
              )}
              {ad.profession && (
                <p className="text-sm text-gray-700">
                  <span className="font-bold">Profession:</span>{decodeHtmlEntities(ad.profession)}
                </p>
              )}
              {ad.address && (
                <p className="text-sm text-gray-700">
                  <span className="font-bold">Adresse:</span> {decodeHtmlEntities(ad.address)}
                </p>
              )}
              {ad.city&& (
                <p className="text-sm text-gray-700">
                  <span className="font-bold">Ville:</span> {decodeHtmlEntities(ad.city)}
                </p>
              )}
              {ad.boosted === 1 && (
                <span className="bg-red-500 text-white text-xs px-2 py-1 rounded-md uppercase font-bold float-end">
                  Urgent
                </span>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Load more button */}
      {hasMore && (
        <div className="mt-6 flex justify-center">
          <button
            onClick={() => setCurrentPage((prev) => prev + 1)}
            disabled={loading}
            className={`px-4 py-2 rounded-md ${
              loading ? 'bg-gray-300 text-gray-500' : 'bg-lime-500 text-white hover:bg-blue-600'
            }`}
          >
            {loading ? 'Loading...' : 'Charger Plus'}
          </button>
        </div>
      )}
    </div>
    </>
  );
};

export default AdsAndRequests;
