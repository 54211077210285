import React, { useState, useEffect, useRef } from "react";
import userProfile from "../assests/people2.png";

import { FaArrowLeft } from "react-icons/fa";

import { useLocation, useNavigate } from "react-router-dom";
import api from "../services/api";
import { FaFilePdf, FaFileWord, FaFileExcel, FaFileAlt, FaFile } from "react-icons/fa";
const Conversation = () => {
  const [contact, setContact] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null); // For image preview
  const [isUploading, setIsUploading] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); // For modal image
  const location = useLocation();
  const navigate = useNavigate();
  const [menuVisibleId, setMenuVisibleId] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [menuVisible, setMenuVisible] = useState(null); // For menu visibility
  const messagesEndRef = useRef(null);
 // Helper function to identify file type
 const getFileIcon = (fileType) => {
  if (fileType.includes("pdf")) return <FaFilePdf className="text-red-500 text-2xl" />;
  if (fileType.includes("word")) return <FaFileWord className="text-blue-500 text-2xl" />;
  if (fileType.includes("excel")) return <FaFileExcel className="text-green-500 text-2xl" />;
  if (fileType.includes("text")) return <FaFileAlt className="text-gray-500 text-2xl" />;
  return <FaFile className="text-gray-500 text-2xl" />;
};
  const queryParams = new URLSearchParams(location.search);
  const receiverId = queryParams.get("id");

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await api.getSession();
        if (response && response.user && response.user.id) {
          setSessionId(response.user.id);
        } else {
          console.error("No user data in response");
        }
      } catch (error) {
        console.error("Failed to fetch session:", error.message);
      }
    };
    fetchSession();
  }, []);

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const contactResponse = await api.fetchContactById(receiverId);
        if (contactResponse.success) {
          setContact(contactResponse.contact);
        } else {
          console.error("Failed to fetch contact:", contactResponse.message);
        }
      } catch (error) {
        console.error("Error fetching contact:", error);
      }
    };

    fetchContact();
  }, [receiverId]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const messagesResponse = await api.fetchConversation(receiverId);
        if (messagesResponse.success) {
          setMessages(messagesResponse.messages);
        } else {
          console.error("Failed to fetch messages:", messagesResponse.message);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    // Fetch messages initially and set up an interval
    fetchMessages();
    const interval = setInterval(fetchMessages, 2000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [receiverId]);

  useEffect(() => {
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Scroll to bottom after a slight delay to ensure DOM is rendered
    if (!hasScrolled) {
      setTimeout(() => {
        scrollToBottom();
        setHasScrolled(true); // Ensure this happens only once
      }, 100); // Adjust delay as needed
    }
  }, [messages, hasScrolled]);

  const handleNewMessageChange = (event) => setNewMessage(event.target.value);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      // Check if the file is an image
      if (file.type.startsWith("image/")) {
        setPreviewImage(URL.createObjectURL(file)); // Create a preview URL for images
      } else {
        setPreviewImage(null); // No preview for non-image files
      }
    }
  };

  const handleSendMessage = async () => {
    if (!sessionId) {
      console.error("Session ID is missing");
      return;
    }
    if (newMessage.trim() === "" && !selectedFile) {
      console.error("Message or file is missing");
      return;
    }

    const tempMessage = {
      id: Date.now(),
      message: newMessage,
      image_url: isUploading ? "loading" : null,
      is_sent_by_current_user: true,
    };
    setMessages((prevMessages) => [...prevMessages, tempMessage]);
    setIsUploading(true);

    const formData = new FormData();
    formData.append("receiver_id", receiverId);
    formData.append("message", newMessage);
    formData.append("session_id", sessionId);
    if (selectedFile) formData.append("file", selectedFile);

    try {
      const response = await api.sendMessage(formData);
      setIsUploading(false);

      if (response.success) {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === tempMessage.id ? { ...msg, image_url: response.image_url } : msg
          )
        );
        setNewMessage("");
        setSelectedFile(null);
        setPreviewImage(null); // Clear the preview image

        // Fetch updated messages after sending
        const updatedMessagesResponse = await api.fetchConversation(receiverId);
        if (updatedMessagesResponse.success) {
          setMessages(updatedMessagesResponse.messages);
        } else {
          console.error("Failed to fetch updated messages:", updatedMessagesResponse.message);
        }
      } else {
        console.error("Failed to send message:", response.message);
      }
    } catch (error) {
      setIsUploading(false);
      console.error("Error sending message:", error.message);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      const response = await api.deleteMessage(messageId);
      if (response.success) {
        setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== messageId));
      } else {
        console.error("Failed to delete message:", response.message);
      }
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const toggleMenu = (messageId, event) => {
    const rect = event.target.getBoundingClientRect(); // Get the position of the ⋮ button
    setMenuPosition({ top: rect.top + window.scrollY + 20, left: rect.left + window.scrollX - 50 }); // Adjust the position
    setMenuVisible((prev) => (prev === messageId ? null : messageId)); // Toggle visibility for the specific message
  };

  const goToUserProfile = () =>
    navigate(`/profile/specificUser?professional_id=${receiverId}`);

  const handleImageClick = (url) => setSelectedImage(url); // Show modal with image
  const closeModal = () => setSelectedImage(null); // Close modal

  return (
    <div className="flex flex-col fixed inset-0 items-center justify-center bg-gray-100  z-50">


      <div className="flex flex-col w-full max-w-4xl h-[80vh] bg-white rounded-lg shadow-lg mt-5">
      <div className="flex justify-start w-full mb-3 mt-2 ml-2">
  <button
    onClick={() => navigate("/profile/chat")}
    className="px-3 py-2 bg-orange-500 text-white text-xs rounded hover:bg-orange-600"
  >
    <FaArrowLeft className="inline mr-2" /> Retour
  </button>
</div>
        <header className="bg-white py-4 px-4 text-gray-700 flex justify-between items-center border-b">
          <div className="flex items-center">
            <img
              className="rounded-full w-12 h-12"
              src={contact?.ProfilePicture || userProfile}
              alt="profile"
            />
            <h1 className="text-base font-medium ml-4">{contact?.Name || "Loading..."}</h1>
            <button
              onClick={goToUserProfile}
              className="bg-lime-500 text-white ml-4 px-3 py-1 rounded-lg"
            >
              Voir le profil
            </button>
          </div>
        </header>

        <div className="flex flex-col flex-1 overflow-y-auto p-4">
          {messages.map((msg) => (
            <div
              key={msg.id}
              className={`flex mb-2 ${msg.is_sent_by_current_user ? "justify-end" : "justify-start"}`}
            >
              {msg.is_sent_by_current_user && (
                <>
                  <button
                    onClick={(e) => toggleMenu(msg.id, e)}
                    className="text-gray-500 hover:text-black text-2xl me-3"
                  >
                    ⋮
                  </button>
                  {menuVisible === msg.id && (
                    <div
                      className="absolute bg-white shadow-md rounded-md p-2 z-50"
                      style={{
                        top: menuPosition.top,
                        left: menuPosition.left,
                      }}
                    >
                      <button
                        onClick={() => handleDeleteMessage(msg.id)}
                        className="text-red-500 hover:underline"
                      >
                        Supprimer
                      </button>
                    </div>
                  )}
                </>
              )}
              <div
                className={`relative flex items-center ${
                  msg.is_sent_by_current_user ? "bg-lime-500 text-white" : "bg-gray-200 text-black"
                } rounded-lg p-3 max-w-xs`}
              >
                <p className="text-sm">{msg.message}</p>
                {msg.created_at && (
                  <p className="text-xs text-gray-500 mt-2">
                    {new Date(msg.created_at).toLocaleString()}
                  </p>
                )}
                {msg.image_url &&
  (msg.image_url.startsWith("data:image") || /\.(jpg|jpeg|png|gif)$/i.test(msg.image_url) ? (
    <img
      src={msg.image_url}
      alt="Attachment"
      className="w-12 h-12 object-cover rounded-lg cursor-pointer"
      onClick={() => handleImageClick(msg.image_url)}
    />
  ) : (
    <div
      className="flex items-center justify-center cursor-pointer w-12 h-12 bg-gray-200 rounded-lg"
      onClick={() => window.open(msg.image_url, "_blank")}
    >
      {getFileIcon(msg.image_url.split(".").pop())}
    </div>
  ))}

              </div>
            </div>
          ))}

          <div ref={messagesEndRef} />
        </div>

        <footer className="bg-white border-t p-4">
  <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-2">
    {/* File Attachment */}
    <label className="text-sm cursor-pointer bg-gray-200 py-2 px-4 rounded-lg w-full sm:w-auto text-center">
      Joindre un fichier
      <input
        type="file"
        accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        onChange={handleFileChange}
        className="hidden"
      />
    </label>

    {/* File Preview */}
    {selectedFile && (
      <div className="flex items-center space-x-2 w-full sm:w-auto">
        {previewImage ? (
          <img
            src={previewImage}
            alt="Preview"
            className="w-12 h-12 object-cover rounded-lg"
          />
        ) : (
          <div className="w-12 h-12 bg-gray-200 flex items-center justify-center rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5V6a2 2 0 012-2h4l2 2h7a2 2 0 012 2v8.5m-1.5 0a2.5 2.5 0 11-5 0m5 0a2.5 2.5 0 01-5 0M16 15v6m-4-6v6"
              />
            </svg>
          </div>
        )}
        <button
          onClick={() => {
            setSelectedFile(null);
            setPreviewImage(null);
          }}
          className="text-red-500 hover:underline"
        >
          Remove
        </button>
      </div>
    )}

    {/* Message Input */}
    <input
      type="text"
      placeholder="Type your message..."
      value={newMessage}
      onChange={handleNewMessageChange}
      className="flex-1 border border-gray-300 rounded-lg py-2 px-4 w-full sm:w-auto"
    />

    {/* Send Button */}
    <button
      onClick={handleSendMessage}
      className="bg-blue-500 text-white px-4 py-2 rounded-lg w-full sm:w-auto text-center"
    >
      Envoyer
    </button>
  </div>
</footer>

      </div>
      {selectedImage && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]">
    <div className="bg-white p-4 rounded-lg">
      <img src={selectedImage} alt="Full View" className="max-w-sm max-h-96 object-contain" />
      <button
        onClick={closeModal}
        className="mt-4 bg-red-500 text-white px-4 py-2 rounded-lg"
      >
        Fermer
      </button>
    </div>
  </div>
)}

    </div>
  );
};

export default Conversation;
