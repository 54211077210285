import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaArrowLeft } from "react-icons/fa";

const OfferHistory = () => {
  const [offers, setOffers] = useState([]); // State to store fetched offers
  const [error, setError] = useState(null); // State to handle errors
  const navigate = useNavigate();

  // Fetch offer history on component mount
  useEffect(() => {
    fetchOffers();
  }, []);

  const fetchOffers = async () => {
    try {
      const response = await axios.get("https://btobjob.com/fetch_offers_history.php", {
        withCredentials: true, // Send cookies for session
      });
      setOffers(response.data.offers);
    } catch (error) {
      console.error("Erreur lors du chargement des offres :", error);
      setError("Échec du chargement des offres.");
    }
  };

  // Handle Delete
  const handleDelete = async (id) => {
    try {
      await axios.post("https://btobjob.com/delete_offer.php", { id }, {
        withCredentials: true, // Send cookies for session
      });
      setOffers(offers.filter((offer) => offer.id !== id)); // Remove offer from UI
    } catch (error) {
      console.error("Erreur lors de la suppression de l'offre :", error);
      setError("Échec de la suppression de l'offre.");
    }
  };

  // Handle Edit
  const handleEdit = (id) => {
    navigate(`/profile/edit_offer/${id}`); // Redirect to an edit page with offer ID
  };

  return (
    <div className="max-w-4xl mx-auto p-4 md:p-8 bg-white shadow-lg rounded-lg mt-6">
      {/* Back button */}
      <button
        onClick={() => navigate(-1)}
        className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
      >
        <FaArrowLeft className="inline mr-2" /> Retour
      </button>

      {/* Title */}
      <h1 className="text-2xl font-bold mb-4">Historique des Offres</h1>

      {/* Error message */}
      {error && <p className="text-red-500">{error}</p>}

      {/* Responsive container for the table */}
      <div className="w-full overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Catégorie</th>
              <th className="py-2 px-4 border-b">Description</th>
              <th className="py-2 px-4 border-b">Image</th>
              <th className="py-2 px-4 border-b">Date</th>
              <th className="py-2 px-4 border-b">actes</th>
            </tr>
          </thead>
          <tbody>
            {offers.map((offer) => (
              <tr key={offer.id}>
                <td className="py-2 px-4 border-b">{offer.category}</td>
                <td className="py-2 px-4 border-b">{offer.description}</td>
                <td className="py-2 px-4 border-b">
                  {offer.image && (
                    <img
                      src={offer.image}
                      alt="Offre"
                      className="w-20 h-20 object-cover rounded-md"
                    />
                  )}
                </td>
                <td className="py-2 px-4 border-b">
                  {new Date(offer.created_at).toLocaleDateString()}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  <button
                    onClick={() => handleEdit(offer.id)}
                    className="bg-lime-500 text-white px-3 py-1 rounded-md mr-2"
                  >
                    Modifier
                  </button>
                  <button
                    onClick={() => handleDelete(offer.id)}
                    className="bg-red-500 text-white px-3 py-1 rounded-md"
                  >
                    Supprimer
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OfferHistory;
