import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // Ensure correct import of useNavigate

const Verify = () => {
  const [subscriptionExists, setSubscriptionExists] = useState(false);
  const [isProfessional, setIsProfessional] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate(); // Ensure useNavigate is initialized correctly

  useEffect(() => {
    const getSubscription = async () => {
      try {
        const data = await api.getSubscription();
        setSubscriptionExists(data.subscription_exists);
        setIsProfessional(data.subscription_exists);
      } catch (error) {
        console.error("Error fetching subscription status:", error);
      }
    };

    getSubscription();
  }, []);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        // Fetch profile data, including latitude and longitude
        const response = await api.fetchProfile();
        setProfileData(response);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="text-gray-700">Loading...</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
      <button
  onClick={() => navigate("/profile/viewprofile")}
  className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 sm:mr-72 mr-0"
>
  <FaArrowLeft className="inline mr-2" /> Retour
</button>

      <div className="bg-white shadow-lg rounded-lg p-8 max-w-sm w-full">
        <h2 className="text-2xl font-bold text-gray-800 text-center mb-6">
          Vérifiez votre profil
        </h2>
        <p className="text-gray-600 text-center mb-4">
          Veuillez compléter les étapes suivantes pour vérifier votre profil.
        </p>
        <div className="space-y-4">
          {/* Email Verification */}
          {profileData?.verification == 1 ? (
            <p>Votre email est vérifié.</p>
          ) : (
            <div className="text-center">
              <p className="text-gray-700">Vérifiez votre adresse e-mail</p>
              <a
                href="https://btobjob.com/verify_email.php"
                className="mt-2 inline-block bg-lime-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-lime-600"
              >
                Vérifier l'email
              </a>
            </div>
          )}

          {/* Profile Documents */}
          {subscriptionExists && (
            <>
              {profileData?.insurance &&
              profileData?.civil_liability &&
              profileData?.kbis_file ? (
                <p>Vos documents de profil sont vérifiés.</p>
              ) : (
                <div className="text-center">
                  <p className="text-gray-700">
                    Téléchargez les documents de profil
                  </p>
                  <a
                    href="https://btobjob.com/profile/profiledoc"
                    className="mt-2 inline-block bg-transparent text-gray-800 border border-lime-500 px-4 py-2 rounded-md shadow-md hover:bg-lime-500 hover:text-white transition-all duration-300"
                  >
                    Télécharger des documents
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Verify;
