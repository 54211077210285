import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import axios from 'axios';
import { FaArrowLeft } from "react-icons/fa";
import ReactPlayer from 'react-player';
import NavbarSection from "../components/Navbar";
import FooterSection from "../components/Footer";
import LOGO2 from '../assests/blog_logo.png';
import Share from '../afterlogin/Share';

const BlogDetail = () => {
    const { postId } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openShareModal, setOpenShareModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`https://btobjob.com/get_blog_details.php?id=${postId}`);
                if (response.data.success) {
                    setPost(response.data.post);
                } else {
                    setError(response.data.error || "Failed to fetch the blog post.");
                }
                setLoading(false);
            } catch (err) {
                console.error("Error fetching blog post:", err);
                setError("Error fetching the blog post.");
                setLoading(false);
            }
        };
        fetchPost();
    }, [postId]);

    if (loading) {
        return <p className="text-center mt-10">Loading...</p>;
    }

    if (error) {
        return <p className="text-center mt-10 text-red-500">{error}</p>;
    }

    return (
        <>
           <HelmetProvider>
            <Helmet>
    <title>{post.title}</title>
    <meta property="og:title" content={post.title} />
    <meta property="og:description" content={post.description.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 150)} />
    <meta property="og:image" content={post.featured_image} />
    <meta property="og:url" content={`https://btobjob.com/blog/${postId}`} />
    <meta property="og:type" content="article" />
</Helmet>

            <NavbarSection />
            <div className="container mx-auto px-4 py-8">
                <button
                    onClick={() => navigate(-1)}
                    className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
                >
                    <FaArrowLeft className="inline mr-2" /> Retour
                </button>

                <div className="flex flex-col justify-center items-center mb-8 p-4 rounded-3xl shadow-lg">
                    <img
                        src={LOGO2}
                        alt="Logo"
                        className="h-48 w-auto object-contain mb-4"
                    />
                </div>

                <div className="bg-white p-6 rounded-lg shadow-md">
                    <button className="bg-[#65A406] text-white md:p-2 p-1 rounded-lg text-xs md:text-sm float-right" onClick={() => setOpenShareModal(true)}>Partager</button>
                    <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
                    <p className="text-gray-600 mb-4">
                        {new Date(post.created_at).toLocaleDateString()}
                    </p>

                    <div className="flex flex-col md:flex-row gap-6 mb-6">
    {post.featured_image && (
        <div className="w-full md:w-1/2">
            <img
                src={post.featured_image}
                alt={post.title}
                className="w-full h-80 object-contain rounded-lg"
            />
        </div>
    )}
    {post.featured_video && (
        <div className="w-full md:w-1/2">
            <ReactPlayer
                url={post.featured_video}
                controls
                light={post.thumbnail || true}
                width="100%"
                height="100%"
                className="rounded-lg"
            />
        </div>
    )}
</div>

                    <div
    className="text-gray-800 leading-relaxed break-words overflow-wrap"
    style={{
        wordWrap: "break-word",
        overflowWrap: "break-word",
        maxWidth: "100%",
    }}
    dangerouslySetInnerHTML={{ __html: post.description }}
/>
                </div>
            </div>
            <FooterSection />
            {openShareModal && (
                <Share
                    profileUrl={window.location.href}
                    closeModal={() => setOpenShareModal(false)}
                />
            )}
            </HelmetProvider>
        </>
    );
};

export default BlogDetail;
