import React from 'react'
import btnArrow from '../assests/inputbtnarrow.png'
import HomeProfessional1 from '../assests/professional1.png'
import HomeProfessional2 from '../assests/professional2.png'
import HomeProfessional3 from '../assests/professional3.png'
import screen from "../assests/imggg/home.png";
import { useNavigate } from "react-router-dom";
import btb from "../assests/b2bUpdated.png"

const Contract = () => {
  const navigate = useNavigate();

  const NavigateToAllservices = () => {
    navigate("/profile/services");
  };

  return (
    <div className="mb-4 lg:mt-20 mt-16 bg-white px-4">
      {/* Heading */}
      <div className="flex items-center justify-center mb-8">
        <h1 className="text-3xl md:text-4xl font-bold text-center leading-[40px]">
          <span className="text-lime-500">Les</span> Professionnels
        </h1>
      </div>
      {/* <div className="max-w-5xl mx-auto flex flex-col items-center justify-center px-4"> */}
        {/* Large screen image in the center */}
        {/* <img
          src={screen}
          alt="Home Screen"
          className="w-full max-w-4xl mx-auto"
        />

          </div> */}
      {/* Grid of Professionals */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-4 text-center">
        
        {/* Professional Item 1 */}
        <div className="flex flex-col items-center">
          <img
            className="mb-2"
            src={HomeProfessional1}
            alt="Professional 1"
            width={200}
          />
          <p className="leading-normal">
            1. Soyez alerté en temps réel de <br />
            demandes autour de vous
          </p>
        </div>

        {/* Professional Item 2 */}
        <div className="flex flex-col items-center">
          <img
            className="mb-2"
            src={HomeProfessional2}
            alt="Professional 2"
            width={200}
          />
          <p className="leading-normal">
            2. Répondre aux demandes et aux services
          </p>
        </div>

        {/* Professional Item 3 */}
        <div className="flex flex-col items-center">
          <img
            className="mb-2"
            src={HomeProfessional3}
            alt="Professional 3"
            width={220}
          />
          <p className="leading-normal">
            3. Soyez payé et évalué
          </p>
        </div>

      </div>
    </div>
  );
};

export default Contract;
