import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../home";
import FaqsPage from "../pages/Faqs";
import ContactPage from "../pages/Contact";
import AboutPage from "../pages/About";
import LogIn from "../signup/login/login";
import IndividualSignup from "../signup/login/Individualsign";
import ProfessionalSignup from "../signup/login/Professionalsignup";
import ProfileUser from "../afterlogin/Profile";
import Choice from "../homecomponents/Choice";
import LoginPro from "../signup/login/login-pro"; 
import BlogPage from "../pages/blogs";
import Cgv from "../pages/cgv";
import BlogDetail from "../pages/BlogDetail";
import CookiePolicy from "../components/cookies-policy";
import SiretVerification from "../signup/login/siret"
function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/Profile/*" element={<ProfileUser />} />
                <Route path="/faqs" element={<FaqsPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/individualsignup" element={<IndividualSignup />} />
                <Route path="/login" element={<LogIn />} />
                <Route path="/professionaluser" element={<ProfessionalSignup />} />
                <Route path="/choice" element={<Choice />} />
                <Route path="/login-pro" element={<LoginPro />} /> 
                <Route path="/BlogPage" element={<BlogPage />} />
                <Route path="/Cgv" element={<Cgv />} />  
                <Route path="/BlogDetail/:postId" element={<BlogDetail />} />
                <Route path="/CookiePolicy" element={<CookiePolicy />} />  
                <Route path="/Siret" element={<SiretVerification/>} />  
                {/* Fallback Route */}
                <Route path="*" element={<h1>404: Page Not Found</h1>} />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;
