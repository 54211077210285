import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MobilePhone from "../assests/mobilephone.png";
import screen from "../assests/imggg/home.png";

const Homephone = () => {
  const navigate = useNavigate();
  const [placeholder, setPlaceholder] = useState("Votre e téléphone");

  const handleRadioChange = (e) => {
    if (e.target.value === "Téléphone") {
      setPlaceholder("Votre e téléphone");
    } else if (e.target.value === "email") {
      setPlaceholder("Votre e email");
    }
  };

  const NavigateToContact = () => {
    navigate("/contact");
  };

  return (
    <div className="bg-white mb-10 sm:mb-20 mt-10 sm:mt-20">
      {/* Container to center the content */}
  
        </div>
    
    
  );
};

export default Homephone;
