import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NavbarProfile from "./Navbarprofile";
import "./login.css";
import Offerpage from "./OfferPage/Offerpage";
import Allthemes1 from "./AllThemes/Allthemes1";
import Request from "./RequestPage/Request";
import MainProfile from "./ProfileView/MainProfile";
import ChatApp from "./ChatApp";
import ProfileEdit from './ProfileView/ProfileEdit';
import ChangePassword from "./ProfilePage/ChangePassword";
import Verifyme from "./ProfilePage/Verifyme";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BuyAWebsite from "./WebsitePage/BuyAWebsite";
import BuyAWebsite2 from "./WebsitePage/BuyAWebsite2";
import PostServices from "./Services/PostServices";
import SubscriptionPage from "../subscription/Subscription";
import ForumHome from "./ForumPage/ForumHome";
import AllPhotos from "./ProfileView/AllPhotos";
import Allthemes2 from "./OfferPage/Allthemes2";
import SpecificUserProfile from "./OfferPage/SpecificUserProfile";
import ServicesAndRental from "./AllThemes/ServicesAndRental";
import FooterSection from "../components/Footer";
import JobDescription from './Services/JobDescription';
import ViewRequests from "./ProfileView/requests";
import AddOffer from "./ProfileView/addoffer";
import AddForum from "./ForumPage/addnewforum";
import ForumDetail from './ForumPage/ForumDetail';
import PrivateRequest from "./ProfileView/privaterequest";
import UpdatePicturePage from "./ProfileView/changepicture";
import Conversation from "./conversation";
import BusinessCard from "./ProfileView/card";
import ProfilePhotos from "./OfferPage/profilephotos";
import Ads from "./ads/ads";
import AdsDetails from "./ads/details";
import ViewAllRequests from "./ProfileView/allrequests";
import RequestDetails from './ads/RequestDetails';
import AdsDescription from './Services/AdsDescription';
import RentalDetails from './AllThemes/RentalDetails';
import RentalAdsDetails from './AllThemes/RentalAdsDetails';
import ChromeNotifications from './ChromeNotifications';
import PostDetails from './RequestPage/PostDetails';
import ProfileDoc from './ProfileView/profiledocs';
import OfferHistory from './ProfileView/OfferHistory';
import EditOffer from './ProfileView/EditOffer';
import EditPost from './ProfileView/EditPost';
import SpecificDoc from './OfferPage/SpecificDoc';
import AppliedJobs from './ProfileView/AppliedJobs';
import Verify from './ProfileView/verify';

import api from '../services/api';
function ProfileUser() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [subscription, setSubscription] = useState(false);

  const [isProfessional, setIsProfessional] = useState(false);

  // Define paths that do not require login
  const publicPaths = [
    '/posts',
    '/adsdescription',
    '/rental',
    '/rentalAds',
    '/ChromeNotifications',
    '/AdsDetails',
    '/ForumDetail',
    '/businesscard', 
    '/specificUser',
     '/jobdescription',
     '/businesscard'
];

useEffect(() => {
  const fetchSessionAndSubscription = async () => {
      try {
          const sessionData = await api.getSession();
          if (sessionData.user && sessionData.user.id) {
              setIsLoggedIn(true);
              setIsProfessional(sessionData.user.professional === '1');

              // Fetch subscription only if user is a professional
              if (sessionData.user.professional === '1') {
                  const subscriptionData = await api.getSubscription();
                  const { subscription_exists, status, time_left } = subscriptionData;
                  setSubscription(subscription_exists);

                  // Redirect if subscription doesn't exist or is expired
                  if (!subscription_exists || status === 'expired' || (!time_left && status === 'inactive')) {
                      navigate('/profile/subscription');
                  }
              }
          } else if (!publicPaths.some(path => location.pathname.includes(path))) {
              navigate('/professionaluser');
          }
      } catch (error) {
          console.error('Error fetching session or subscription:', error);
          if (!publicPaths.some(path => location.pathname.includes(path))) {
              navigate('/professionaluser');
          }
      }
  };

  fetchSessionAndSubscription();
}, [location.pathname, navigate]);

const isSubscriptionPage = location.pathname.includes('/subscription');
const excludeFooterPaths = ['/conversation'];
const hideNavbarPaths = ['/conversation'];
  return (
    <div className="overflow-x-hidden">
      {/* Conditionally render the NavbarProfile only if logged in */}
      {isLoggedIn && !isSubscriptionPage && !hideNavbarPaths.some(path => location.pathname.includes(path)) && (
        <div className="fixed ms-5 top-0 w-full z-[999999] bg-white shadow-md p-4">
          <NavbarProfile />
        </div>
      )}

      {/* Add top padding to offset the fixed navbar */}
      <div className={`${isLoggedIn && !isSubscriptionPage ? 'pt-24' : ''}`}>
        <Routes>
          <Route path="offers" element={<Offerpage />} />
          <Route path="request" element={<Request />} />
          <Route path="subscription" element={<SubscriptionPage />} />
          <Route path="chat" element={<ChatApp />} />
          <Route path="forum" element={<ForumHome />} />
          <Route path="website" element={<BuyAWebsite />} />
          <Route path="viewprofile" element={<MainProfile />} />
          <Route path="editprofile" element={<ProfileEdit />} />
          <Route path="verify" element={<MainProfile />} />
          <Route path="allphotos" element={<AllPhotos />} />
          <Route path="allthemes" element={<Allthemes2 />} />
          <Route path="Allacategories" element={<Allthemes1 />} />
          <Route path="specificUser" element={<SpecificUserProfile />} />
          <Route path="services_rentals" element={<ServicesAndRental />} />
          <Route path="changepassword" element={<ChangePassword />} />
          <Route path="services" element={<PostServices />} />
          <Route path="jobdescription/:jobId" element={<JobDescription />} />
          <Route path="addoffer" element={<AddOffer />} />
          <Route path="viewrequests" element={<ViewRequests />} />
          <Route path="AddForum" element={<AddForum />} />
          <Route path="privaterequest" element={<PrivateRequest />} />
          <Route path="ForumDetail/:id" element={<ForumDetail />} />
          <Route path="updatePicturepage" element={<UpdatePicturePage />} />
          <Route path="conversation" element={<Conversation />} />
          <Route path="businesscard" element={<BusinessCard />} />
          <Route path="profilePhotos" element={<ProfilePhotos />} />
          <Route path="ads" element={<Ads />} />
          <Route path="AdsDetails" element={<AdsDetails />} />
          <Route path="ViewAllRequests" element={<ViewAllRequests />} />
          <Route path="RequestDetails" element={<RequestDetails />} />
          <Route path="adsdescription/:AdId" element={<AdsDescription />} />
          <Route path="rental/:rentalId" element={<RentalDetails />} />
          <Route path="rentalAds/:rentalId" element={<RentalAdsDetails />} />
          <Route path="ChromeNotifications" element={<ChromeNotifications />} />
          <Route path="posts/:postId" element={<PostDetails />} />
          <Route path="profiledoc" element={<ProfileDoc />} />
          <Route path="OfferHistory" element={<OfferHistory />} />
          <Route path="edit_offer/:id" element={<EditOffer />} />
          <Route path="editpost/id/:id" element={<EditPost />} />
          <Route path="SpecificDoc" element={<SpecificDoc />} />
          <Route path="AppliedJobs" element={<AppliedJobs />} />
          <Route path="profileverify" element={<Verify />} />
        </Routes>
      </div>

      {!excludeFooterPaths.some(path => location.pathname.includes(path)) && <FooterSection />}
    </div>
  );
}

export default ProfileUser;