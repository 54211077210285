import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import btnArrow from '../assests/inputbtnarrow.png';
import HomeRoom from '../assests/homeroom.png';
import Slider3 from '../assests/slider3.png';
import Star from '../assests/Star.png';
import Slider4 from '../assests/homerankingimageman.png';
import Slider5 from '../assests/slider5.png';
import SelfEmployed from '../assests/selfemployed.png';
import Company from '../assests/company.png';
import bg1 from '../assests/Rectangle 30.png';
import bg2 from '../assests/Rectangle 18.png';

// Example images
import slider1 from '../assests/imggg/1.png';
import slider2 from '../assests/imggg/1-2.png';
import slider3 from '../assests/imggg/1-3.png';
import slider4 from '../assests/imggg/2.png';
import slider5 from '../assests/imggg/2-2.png';
import slider6 from '../assests/imggg/2-3.png';
import slider7 from '../assests/imggg/3.png';
import slider8 from '../assests/imggg/3-2.png';
import slider9 from '../assests/imggg/3-3.png';

const Professional = () => {
  const navigate = useNavigate();
  const NavigateToProff = () => {
    navigate("/professionaluser");
  };

  const images = [slider1, slider2, slider3, slider4, slider5, slider6, slider7, slider8, slider9];

  // react-slick settings
  const settings = {
    dots: true,
    infinite: true,          // enable infinite looping
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    autoplay: true,          // turn on autoplay
    autoplaySpeed: 3000,     // interval between slides (in ms)
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };

  return (
    <div className="pb-2 relative bg-[#FFF]">
      <div className="w-full mb-10">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="flex justify-center">
              <img
                src={image}
                alt={`Slider Image ${index + 1}`}
                className="w-96 h-96 rounded-lg object-contain"
              />
            </div>
          ))}
        </Slider>
      </div>

      {/* Optional: Example "Connect" button */}
      {/* <div className="flex justify-center mt-8">
        <button
          className="bg-gradient-to-b from-lime-500 to-lime-800 text-white py-2 px-4 rounded-md flex items-center"
          onClick={NavigateToProff}
        >
          Se Connecter
          <img className="ml-2" src={btnArrow} width={17} alt="arrow icon" />
        </button>
      </div> */}
    </div>
  );
};

export default Professional;
