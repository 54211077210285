import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import api from "../../services/api";

const ProfileModal = ({ onClose }) => {
  const [profileImage, setProfileImage] = useState(null);
  const profileCropperRef = useRef(null);

  const handleUpdateProfileImage = async () => {
    const formData = new FormData();

    // Check if the profile image cropper exists and add the cropped image
    if (profileCropperRef.current?.cropper) {
      const profileBlob = await new Promise((resolve) => {
        profileCropperRef.current.cropper.getCroppedCanvas().toBlob(resolve, "image/jpeg");
      });
      if (profileBlob) {
        formData.append("profileImage", profileBlob, "profile.jpg");
      }
    }

    // If no image is selected, alert the user
    if (!formData.has("profileImage")) {
      alert("Veuillez sélectionner une image de profil à mettre à jour.");
      return;
    }

    try {
      const response = await api.updateImages(formData);
      if (response.success) {
        alert("Image de profil mise à jour avec succès.");
        window.location.reload(); // Reload the page to show updated image
      } else {
        alert(`La mise à jour a échoué : ${response.message || "Erreur inconnue."}`);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'image de profil :", error);
      alert("Une erreur s'est produite lors de la mise à jour de l'image de profil.");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setProfileImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center z-[9999]">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-3xl relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
        >
          &times;
        </button>

        <div className="flex flex-col">
          <div className="px-8">
            <div className="flex flex-col items-center mb-6">
              <label className="text-lg font-medium mb-2">Image de profil :</label>
              <input type="file" accept="image/*" onChange={handleImageChange} />
              {profileImage && (
                <Cropper
                  src={profileImage}
                  style={{ height: 200, width: "100%" }}
                  aspectRatio={1}
                  guides={false}
                  ref={profileCropperRef}
                />
              )}
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleUpdateProfileImage}
                className="bg-lime-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Mettre à jour l'image de profil
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;
