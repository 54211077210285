import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import heic2any from "heic2any";

const API_URL = 'https://btobjob.com'; // Ajustez selon votre URL API réelle

const PostModal = ({ isOpen, onClose }) => {
  const [text, setText] = useState('');
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await axios.get(`${API_URL}/get-session.php`, {
          withCredentials: true,
        });
        if (response.data.user && response.data.user.id) {
          setUserId(response.data.user.id);
        } else {
          throw new Error('ID utilisateur non trouvé');
        }
      } catch (error) {
        console.error('Échec de récupération de la session utilisateur:', error);
        alert('Impossible de récupérer la session utilisateur. Veuillez vous connecter.');
        onClose();
      }
    };

    if (isOpen) {
      fetchUserId();
    }
  }, [isOpen, onClose]);

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    const allowedMimeTypes = [
      'image/jpeg', 'image/png', 'image/gif',
      'video/mp4', 'video/webm',
    ];

    const convertedFiles = await Promise.all(selectedFiles.map(file => {
      if (file.type === "image/heic") {
        return heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.8  // Adjust quality from 0.0 to 1.0 as needed
        }).then(convertedBlob => new File([convertedBlob], file.name.replace(/\..+$/, ".jpeg"), {
          type: "image/jpeg",
        })).catch(() => null);  // Handle conversion errors, return null to filter out later
      }
      return Promise.resolve(file);
    }));

    const filteredFiles = convertedFiles.filter(file => file && allowedMimeTypes.includes(file.type));
    setFiles(filteredFiles.slice(0, 5)); // Limit to 5 files
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting || !userId) return;

    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('text', text);
    formData.append('user_id', userId);
    files.forEach(file => {
      formData.append('files[]', file);
    });

    try {
      const response = await axios.post(`${API_URL}/create-post.php`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      console.log('Publication créée:', response.data);
      alert('Publication créée avec succès !');
      onClose();
      navigate(0);
    } catch (error) {
      console.error('Erreur lors de la création de la publication:', error.response || error);
      alert('Échec de la création de la publication. Veuillez réessayer.');
    } finally {
      setIsSubmitting(false);
      setUploadProgress(0);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[99999999]">
      <div className="bg-white p-4 rounded-xl w-full max-w-lg">
        <h2 className="text-lg mb-2">Créer une nouvelle publication</h2>
        <form onSubmit={handleSubmit}>
          <textarea
            className="w-full border-2 p-2 rounded-md mb-2"
            rows="4"
            placeholder="Quoi de neuf ?"
            value={text}
            onChange={(e) => setText(e.target.value)}
            disabled={isSubmitting}
          />
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="w-full mb-2"
            disabled={isSubmitting}
          />
          <div className="flex flex-wrap gap-2 mb-2">
            {files.map((file, index) => (
              <div key={index} className="relative">
                {file.type.startsWith('image/') ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Fichier sélectionné"
                    className="w-20 h-20 object-cover rounded-md"
                  />
                ) : file.type.startsWith('video/') ? (
                  <video controls className="w-20 h-20 rounded-md">
                    <source src={URL.createObjectURL(file)} type={file.type} />
                  </video>
                ) : null}
              </div>
            ))}
          </div>
          {uploadProgress > 0 && (
            <p className="text-sm text-gray-500 mb-2">
              Progression du téléchargement: {uploadProgress}%
            </p>
          )}
          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded-md"
            disabled={isSubmitting || !userId}
          >
            {isSubmitting ? 'Téléchargement...' : 'Publier'}
          </button>
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-300 text-black px-4 py-2 rounded-md ml-2"
            disabled={isSubmitting}
          >
            Annuler
          </button>
        </form>
      </div>
    </div>
  );
};

export default PostModal;
