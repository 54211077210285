import React from "react";
import { useNavigate } from "react-router-dom";
import NavbarSection from "../components/Navbar";
import FooterSection from "../components/Footer";

const Cgv = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <>
      <NavbarSection />
      <div className="container w-2/3 px-10 mt-10 pt-20 mx-auto text-justify">
        <h1 className="text-2xl font-bold mb-5">CONDITIONS GENERALES D’UTILISATION ET DE VENTE DE BTOB JOB</h1>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 1 - Désignation du Vendeur</h2>
        <p>
          BTOB JOB <br />
          4 rue de la Fabrique <br />
          21560 COUTERNON <br />
          RCS DIJON n° 929 286 078 <br />
          Téléphone : … <br />
          Mail : btobjob2024@gmail.com <br />
          Site internet : <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a>
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 2 - Champ d'application des Conditions générales de vente</h2>
        <p>
          Les présentes Conditions Générales de Vente s'appliquent entre les clients professionnels et le vendeur sus désignés.
          Le client souscrit à un abonnement Premium sur le présent site internet, pour bénéficier des prestations / services suivants, sans restriction ni réserve de :
        </p>
        <ul className="list-disc ml-5">
          <li>Mise en relation entre professionnels : Cela permet aux clients / utilisateurs de se connecter avec d'autres professionnels, que ce soit pour des collaborations, des partenariats, des sous traitances ou des projets communs ;</li>
          <li>Poster des annonces pour la vente de matériels : Les abonnés peuvent proposer des produits ou services à la vente en publiant des annonces spécifiques ;</li>
          <li>Poster des annonces pour la mise à disposition de services de location : Les abonnés peuvent proposer des produits ou services à la location en publiant des annonces spécifiques ;</li>
          <li>Poster des annonces pour un besoin de sous-traitance : Si un utilisateur a un besoin de sous-traitance dans un domaine spécifique, il peut publier une annonce pour trouver des partenaires capables de réaliser ce travail ;</li>
          <li>Réponse aux annonces publiées par des particuliers : Les abonnés peuvent consulter et répondre à des annonces créées par des particuliers. Ces annonces peuvent concerner différents types de services ou demandes ;</li>
          <li>Mise à la disposition de moyens de paiement afin de garantir le paiement de la prestation.</li>
        </ul>
        <p className="mt-4">
          Les Services proposés par BTOB JOB ("le Vendeur") aux consommateurs et Clients non professionnels ("Les Clients ou le Client") 
          (Vendeur et Client étant également désignés comme « les Parties » ou « une Partie ») sur son site Internet 
          <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a>.
        </p>
        <p>
          Elles précisent, notamment, les conditions d'utilisation du site du Vendeur, de passation de commande, de paiement et de fourniture des Services commandés par les Clients.
          Les caractéristiques principales des Services, regroupant l'ensemble des informations substantielles requises par la réglementation applicable, sont présentées sur le site internet 
          <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a>.
        </p>
        <p>
          Pour des raisons de maintenance ou autres, l’accès au site peut être interrompu ou suspendu par l’éditeur sans préavis ni justification.
          Le Client est tenu d'en prendre connaissance avant toute passation de commande. Le choix et l'achat d'un Service est de la seule responsabilité du Client.
        </p>
        <p>
          Les Conditions Générales de Vente sont systématiquement communiquées à tout Client préalablement à la conclusion du contrat de fourniture des Services et prévaudront, 
          le cas échéant, sur toute autre version ou tout autre document contradictoire. Elles sont accessibles à tout moment sur le site Internet 
          <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a> et prévaudront, 
          le cas échéant, sur toute autre version ou tout autre document contradictoire.
        </p>
        <p>
          Ces conditions s'appliquent à l'exclusion de toutes autres conditions, et notamment celles applicables pour d'autres circuits de commercialisation des Services.
        </p>
        <p>
          Elles sont susceptibles d'être complétées par des conditions particulières, énoncées sur le site Internet 
          <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a>, avant toute transaction avec le Client.
        </p>
        <p>
          Sauf preuve contraire, les données enregistrées dans le système informatique du Vendeur constituent la preuve de l'ensemble des transactions conclues avec le Client.
        </p>
        <p>
          Conformément à la réglementation en vigueur, le Client dispose, à tout moment, d'un droit d'accès, de rectification, d'opposition, d'effacement et de portabilité de l'ensemble de 
          ses données personnelles en écrivant, par courrier et en justifiant de son identité, à BTOB JOB, 4 rue de la Fabrique 21560 COUTERNON
        </p>
        <p>
          Le Client déclare avoir pris connaissance des présentes Conditions Générales de Ventes et les avoir acceptées en cochant la case prévue à cet effet 
          avant la mise en œuvre de la procédure de commande en ligne ainsi que des conditions générales d'utilisation du site internet 
          <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a>.
        </p>
        <p>
          La validation de la commande de Services par le Client vaut acceptation sans restriction ni réserve des présentes Conditions Générales de Vente.
        </p>
        <p>
          Ces Conditions Générales de Vente pouvant faire l'objet de modifications ultérieures, la version applicable à l'achat du Client est celle en vigueur 
          au jour de la conclusion du contrat.
        </p>
        <p>
          Le Client reconnaît avoir la capacité requise pour contracter et acquérir les Services proposés sur le site internet 
          <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a>.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 3 - Commandes</h2>
        <p>
          Le Client sélectionne sur le site les Services qu'il désire commander, selon les modalités suivantes :<br />
          L’utilisateur de <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a> doit s’abonner, après avoir créée un compte utilisateur, 
          pour un montant mensuel de 20 euros TTC afin qu’il puisse bénéficier des services proposés par celui-ci.
        </p>
        <p>
          Ensuite, l’utilisateur professionnel répondra aux annonces publiées afin de réaliser tel ou telle prestation. 
          A l’inverse, un client pourra poster des annonces, dans lesquelles il sollicitera des prestations de la part de professionnels 
          (des utilisateurs ayant des compétences spécifiques). Une plateforme où l’offre et la demande se rencontre de manière efficace et accélérée.
        </p>
        <p>
          Les informations contractuelles sont présentées en langue française et font l'objet d'une confirmation au plus tard au moment de la validation de la commande par le Client.
        </p>
        <p>
          L'enregistrement d'une commande sur le site du Vendeur est réalisé lorsque le Client accepte les présentes Conditions Générales de Vente 
          en cochant la case prévue à cet effet et valide sa commande. Le Client a la possibilité de vérifier le détail de sa commande, son prix total 
          et de corriger d'éventuelles erreurs avant de confirmer son acceptation (article 1127-2 du Code Civil).
        </p>
        <p>
          Cette validation implique l'acceptation de l'intégralité des présentes Conditions Générales de Vente et constitue la preuve du contrat de vente.
        </p>
        <p>
          Il appartient au Client de vérifier l'exactitude de la commande et de signaler immédiatement toute erreur.
        </p>
        <p>
          La vente de Services ne sera considérée comme définitive qu'après l'envoi au Client de la confirmation de l'acceptation de la commande par le Vendeur, 
          par courrier électronique et après encaissement par celui-ci de l'intégralité du prix.
        </p>
        <p>
          Toute commande passée sur le site internet <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a> constitue 
          la formation d'un contrat conclu à distance entre le Client et le Vendeur.
        </p>
        <p>
          Le Vendeur se réserve le droit d'annuler ou de refuser toute commande d'un Client avec lequel il existerait un litige relatif 
          au paiement d'une commande antérieure.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 4 - Tarifs</h2>
        <p>
          Les Services proposés par le Vendeur sont fournis aux tarifs en vigueur, tels que sur le site internet 
          <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a> selon le devis établi par le Vendeur, 
          lors de l'enregistrement de la commande par le Vendeur. Les prix sont exprimés en Euros, HT et TTC.
        </p>
        <p>
          Les tarifs tiennent compte d'éventuelles réductions qui seraient consenties par le Vendeur dans les conditions précisées sur 
          le site internet <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a>.
        </p>
        <p>
          Ces tarifs sont fermes et non révisables pendant leur période de validité, telle qu'indiqué sur 
          le site internet <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a>, 
          le Vendeur se réservant le droit, hors cette période de validité, de modifier les prix à tout moment.
        </p>
        <p>
          Ils ne comprennent pas les frais de traitement et de gestion, qui sont facturés en supplément, 
          dans les conditions indiquées sur le site internet <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a> 
          et calculés préalablement à la passation de la commande.
        </p>
        <p>
          Le paiement demandé au Client correspond au montant total de l'achat, y compris ces frais.
          Une facture est établie par le Vendeur et remise au Client lors de la fourniture des Services commandés.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 5 - Conditions de paiement</h2>
        <p>
          Les Services proposés par le Vendeur sont délivrés au Client en contrepartie d'un prix.
          Le prix de 20 Euros TTC est payable comptant et mensuellement, en totalité au jour de la passation de la commande par le Client, 
          selon les modalités précisées à l'article «Commandes» ci-dessus, par voie de paiement sécurisé selon les modalités suivantes :
        </p>
        <ul className="list-disc ml-5">
          <li>par virement bancaire.</li>
        </ul>
        <p>
          Le paiement par carte bancaire est irrévocable, sauf en cas d'utilisation frauduleuse de la carte. Dans ce cas, le Client peut demander l'annulation du paiement 
          et la restitution des sommes correspondantes.
          La mise à l'encaissement du chèque est réalisée à réception.
        </p>
        <p>
          Le Vendeur ne sera pas tenu de procéder à la fourniture des Services commandés par le Client si le prix ne lui a pas été préalablement réglé 
          en totalité dans les conditions et ci-dessus indiquées.
        </p>
        <p>
          Les paiements effectués par le Client ne seront considérés comme définitifs qu'après encaissement effectif des sommes dues par le Vendeur.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 6 - Fourniture des Services</h2>
        <p>
          Les Services commandés par le Client, qui comprennent les prestations :
        </p>
        <ul className="list-disc ml-5">
          <li>Mise en relation entre professionnels ;</li>
          <li>Poster des annonces pour la vente de matériels ;</li>
          <li>Poster des annonces pour la mise à disposition de services de location ;</li>
          <li>Poster des annonces pour un besoin de sous-traitance ;</li>
          <li>Réponse aux annonces publiées par des particuliers ;</li>
          <li>Mise à la disposition de moyens de paiement afin de garantir le paiement de la prestation.</li>
        </ul>
        <p>
          Ces services seront fournis selon les modalités suivantes, un utilisateur client postera une annonce concernant une prestation de service, 
          par la suite, un prestataire (aussi utilisateur de la plateforme) répondra à l’annonce afin de réaliser la prestation (sous-traitance, location…).
          Cela, dans un délai maximum de 30 jours à compter de la validation définitive de la commande du Client, 
          dans les conditions prévues aux présentes Conditions Générales de Vente, à l'adresse indiquée par le Client lors de sa commande sur 
          le site Internet <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a>.
        </p>
        <p>
          Le Vendeur s'engage à faire ses meilleurs efforts pour fournir les Services commandés par le Client, 
          dans le cadre d'une obligation de moyens et dans les délais ci-dessus précisés.
        </p>
        <p>
          Si les Services commandés n'ont pas été fournis dans un délai de 30 jours après la date indicative ci-dessus précisée, 
          pour toute autre cause que la force majeure ou le fait du Client, celui-ci pourra notifier au Vendeur, 
          dans les conditions prévues à l'article L 216-6 du Code de la consommation
        </p>
        <ul className="list-disc ml-5">
          <li>soit la suspension du paiement de tout ou partie du prix jusqu'à ce que le Vendeur s'exécute, dans les conditions prévues aux articles 1219 et 1220 du code civil (exception d'inexécution),</li>
          <li>soit la résolution de la vente, après avoir mis le Vendeur en demeure de s'exécuter dans un délai supplémentaire raisonnable non respecté par le Vendeur.</li>
        </ul>
        <p>
          La résolution peut être immédiate si le Vendeur refuse de s'exécuter ou s'il est manifeste qu'il ne pourra pas fournir les Services 
          ou si le délai d'exécution non respecté constituait, pour le Client, une condition essentielle de la vente.
        </p>
        <p>
          En cas de résolution de la vente, les sommes versées par le Client lui seront alors restituées au plus tard dans les quatorze jours 
          qui suivent la date de dénonciation du contrat à l'exclusion de toute indemnisation ou retenue, 
          sans préjudice de l'allocation éventuelle de dommages et intérêts au profit du Client.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 7 – Services additionnels</h2>
        <p>
          Les Clients, qu’ils soient particuliers ou professionnels, ont la possibilité de souscrire à une option payante pour booster leurs annonces. 
          Cette option permet de mettre en avant l’annonce en y ajoutant une pastille « Urgente » et en augmentant sa visibilité sur le site pour une durée de sept (7) jours consécutifs.
        </p>
        <ul className="list-disc ml-5">
          <li>Coût de l’option : 2 € TTC par annonce et par activation.</li>
          <li>Modalités de paiement : L’activation de l’option nécessite un paiement immédiat et s’effectue exclusivement en ligne. Ce paiement est non remboursable, sauf en cas de dysfonctionnement avéré imputable au Vendeur.</li>
          <li>Conditions d’utilisation : Le Client est seul responsable de l’activation de cette option et doit vérifier que les informations figurant sur l’annonce sont correctes avant sa mise en avant.</li>
          <li>Restrictions : Certaines annonces peuvent être inéligibles à cette option, conformément aux règles de publication du site précisées dans les Conditions Générales d’Utilisation.</li>
        </ul>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 8 – Obligation du client</h2>
        <p>
          Tout Utilisateur des services de B to B Job s’engage, comme demandé dans le formulaire d'inscription ou lors de toute autre demande d'information nécessaire au bon fonctionnement du service, à :
        </p>
        <ul className="list-disc ml-5">
          <li>Fournir des informations réelles, exactes, à jour et complètes comme demandé dans le formulaire d’inscription ;</li>
          <li>Maintenir et mettre à jour régulièrement les données d’inscription afin de garantir qu’elles restent réelles, exactes, à jour et complètes.</li>
        </ul>
        <p>
          Dans l’hypothèse où les informations fournies seraient fausses, inexactes, périmées ou incomplètes, B to B Job se réserve le droit de suspendre ou de fermer immédiatement le compte concerné, 
          et d’interdire l’accès à tout ou partie des services, de manière définitive si nécessaire.
        </p>
        <p>
          Lors de l’inscription, chaque Utilisateur se voit attribuer un nom de compte constitué de son prénom, son nom, ou, le cas échéant, son nom commercial. 
          Le mot de passe est choisi par l’Utilisateur lors de l’inscription.
        </p>
        <p>
          Hors circonstances dans lesquelles les mesures de sécurité de B to B Job seraient mises en cause, l’Utilisateur est entièrement responsable 
          de la sécurité et de la confidentialité de son nom de compte et de son mot de passe, ainsi que de toutes actions réalisées avec ces identifiants.
        </p>
        <p>
          L’inscription implique également l’obligation de fournir une adresse email valide, qui sera utilisée comme principal moyen de communication 
          entre l’Utilisateur et B to B Job. B to B Job décline toute responsabilité en cas de non-réception des messages dus à une adresse obsolète ou incorrecte.
        </p>
        <p>
          L’Utilisateur s’engage :
        </p>
        <ul className="list-disc ml-5">
          <li>À informer immédiatement B to B Job, par email, de tout vol, perte, usage non autorisé de son compte ou de son mot de passe, ainsi que de toute atteinte à la sécurité dont il aurait connaissance. Suite à cette notification, B to B Job procédera à la réinitialisation du mot de passe. Cette déclaration pourrait entraîner la destruction des informations ou fichiers stockés associés au compte, sans droit à indemnisation ;</li>
          <li>À se déconnecter systématiquement à l’issue de chaque session ;</li>
          <li>À ne pas partager, communiquer ou transmettre son nom de compte ou son mot de passe à un tiers. Chaque profil et ses évaluations ou commentaires sont strictement personnels et individuels ;</li>
          <li>À ne pas créer ou utiliser plusieurs comptes, sauf dans le cadre où l’Utilisateur possède deux comptes distincts : l’un enregistré sous le statut Auto-entrepreneur ou Entreprise avec des coordonnées professionnelles, et l’autre pour effectuer des demandes en tant que particulier sur un secteur d’activité différent.</li>
        </ul>
        <p>
          Toute violation des obligations ci-dessus pourra entraîner la fermeture immédiate du ou des comptes concernés, ainsi que des services associés. 
          B to B Job ne pourra être tenu responsable des pertes ou dommages résultant de ces mesures.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 9 - Modalités de résiliation du contrat</h2>
        <p>
          Il est rappelé que, conformément aux dispositions légales, la résiliation du contrat par voie électronique est possible 
          lorsque le contrat a été conclu par voie électronique ou, lorsqu'au jour de la résiliation le Vendeur offre aux Clients la possibilité de conclure des contrats par voie électronique.
        </p>
        <p>
          A cet effet, une fonctionnalité gratuite est mise à la disposition du Client, lui permettant d'accomplir, 
          par voie électronique, la notification et toutes les démarches nécessaires à la résiliation du contrat, 
          dont le Vendeur devra accuser réception en informant le Client, sur un support durable et dans un délai raisonnable, 
          de la date à laquelle le contrat prend fin et des effets de la résiliation.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 10 – Durée et renouvellement du contrat</h2>
        <p>
          Le présent contrat est conclu pour une durée initiale d'un an. Le paiement peut être effectué en une seule fois ou sous forme d’échéances mensuelles.
        </p>
        <p>
          Le contrat se renouvelle automatiquement par tacite reconduction à chaque date anniversaire, sauf résiliation préalable.
        </p>
        <p>
          L’Utilisateur peut mettre fin à son abonnement en adressant une notification écrite à B to B Job 
          au moins trois mois avant la date anniversaire du contrat.
        </p>
        <p>
          L’utilisation des services du site par l’Utilisateur vaut acceptation et entrée en vigueur immédiate du contrat entre B to B Job et l’Utilisateur, 
          qui accepte également les présentes conditions générales.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 11 – Droit de rétractation du consommateur</h2>
        <p>
          Conformément aux dispositions des articles L221-18 et suivants du Code de la consommation, 
          le client consommateur uniquement, dispose d’un délai de 14 jours à compter de la conclusion du contrat conclu avec la société BtoB 
          pour exercer son droit de rétractation, sans avoir à fournir de motif ni à supporter de frais, 
          sauf si le service a été pleinement exécuté avec son accord préalable.
        </p>
        <p>
          Pour exercer ce droit, le consommateur doit notifier sa décision à la société BtoB JOB par couriel ou lettre recommandé avec accusé de réception 
          via le formulaire de rétractation disponible en annexe.
        </p>
        <p>
          En cas de rétractation valide, la société remboursera les sommes versées dans un délai de 14 jours.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 12 - Responsabilité du Vendeur - Garantie</h2>
        <p>
          Les Services proposés à la vente par le Vendeur sont conformes à la réglementation en vigueur en France 
          et ont des performances compatibles avec des usages non professionnels.
          Le Client bénéficie de plein droit et sans paiement complémentaire, de la garantie légale de conformité et de la garantie légale des vices cachés.
        </p>
        <p>
          B to B Job agit en tant que plateforme électronique destinée à héberger les contenus des annonces publiées par les Utilisateurs (textes, images, etc.), 
          offrant un espace d’échanges d’informations et des services accessoires visant à faciliter la mise en relation entre ces Utilisateurs.
        </p>
        <p>
          En aucun cas, B to B Job n’exerce un contrôle préalable sur la qualité, la sécurité ou la légalité des offres et demandes, 
          ni sur l’exactitude ou la véracité des informations figurant dans les annonces publiées, 
          ni sur la capacité des Utilisateurs à conclure ou honorer les transactions, ou à en retirer les bénéfices escomptés.
        </p>
        <p>
          B to B Job ne peut donc garantir qu’une transaction aboutira effectivement entre Utilisateurs. 
          Tout dépôt de garantie ou caution sollicité relève de la responsabilité exclusive de l’Utilisateur qui en fait la demande, 
          et B to B Job n’endosse aucune responsabilité quant à la gestion de ces dépôts ou à la vérification de l’identité des parties impliquées.
        </p>
        <p>
          N’intervenant pas dans l’exécution des transactions entre Utilisateurs, B to B Job, ainsi que ses éventuelles sociétés affiliées, filiales, 
          représentants légaux, directeurs et employés, sont dégagés de toute responsabilité en cas de litige, 
          réclamation ou dommage, qu’il soit actuel ou futur, direct ou indirect, présumé ou avéré, lié à l’utilisation des services proposés par d’autres Utilisateurs via la plateforme de B to B Job.
        </p>
        <p>
          Sauf preuve d’une faute de B to B Job ou de ses prestataires, celle-ci ne pourra être tenue responsable des dommages, 
          qu’ils soient directs ou indirects, matériels ou immatériels, résultant de l’usage des services d’un autre Utilisateur, 
          y compris mais sans s’y limiter, les pertes de profit, de chiffre d’affaires, de temps, ou d’opportunités.
        </p>
        <p>
          Lorsqu’un accord est conclu librement entre des Utilisateurs à partir des annonces publiées sur le site, 
          il en résulte un contrat direct entre eux auquel B to B Job n’est aucunement partie. 
          En cas de non-exécution ou de mauvaise exécution des obligations résultant de ce contrat, 
          la responsabilité incombe exclusivement à l’Utilisateur défaillant, conformément aux engagements pris envers son cocontractant.
        </p>
        <p>
          B to B Job agit uniquement en qualité d’intermédiaire en ligne pour la publication et la consultation d’annonces d’offres et de demandes. En conséquence :
        </p>
        <ul className="list-disc ml-5">
          <li>B to B Job n’est ni recruteur, ni employeur, ni agence d’intérim, ni entreprise de travail temporaire.</li>
          <li>Aucun lien de subordination n’existe entre B to B Job et les Utilisateurs.</li>
          <li>B to B Job n’est pas mandaté par les Utilisateurs pour rechercher ou identifier des tiers pour la réalisation ou la sollicitation de prestations de services.</li>
          <li>B to B Job n’est partie à aucun contrat formé entre les Utilisateurs.</li>
        </ul>
        <p>
          Les Utilisateurs du Site déclarent exonérer B to B Job de toute responsabilité :
        </p>
        <ul className="list-disc ml-5">
          <li>Tenant aux réponses ou à l’absence de réponse obtenue à la suite de la publication d’une Annonce ou d’un Profil;</li>
          <li>Liée à la sélection ou à la non-sélection d’une Annonce déterminée;</li>
          <li>Tenant au bon déroulement ou au résultat de l'exécution d’une offre (par exemple le fait qu’un Utilisateur ne se présente pas, la qualité de service, le délai de service…);</li>
          <li>Liée au contrôle de la qualité et du déroulement de l'exécution de l'offre;</li>
          <li>Ou plus largement en cas de conflit ou de litige survenant entre les Utilisateurs.</li>
        </ul>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 13 - Responsabilité du Client</h2>
        <p>
          L’Utilisateur assume seul la responsabilité de s’assurer de l’exactitude et de l’exhaustivité des informations 
          relatives aux caractéristiques essentielles de l’offre ou de la demande, ainsi que, le cas échéant, 
          de l’évaluation du prix. Il est également exclusivement responsable de la description fournie pour l’offre ou la demande.
        </p>
        <p>
          L’Utilisateur garantit B to B Job contre toute réclamation, litige ou préjudice découlant du contenu ou de la forme de ladite description. 
          À ce titre, il s’engage à procéder à toutes validations, vérifications et à fournir toutes précisions nécessaires afin que la description 
          et les éléments de l’offre soient fidèles, complets et ne puissent induire le bénéficiaire en erreur.
        </p>
        <p>
          En cas d’inexactitude ou d’erreur dans la description, dans les éléments de l’offre et/ou dans l’évaluation du prix, 
          l’Utilisateur assumera seul les éventuelles conséquences financières ou surcoûts. 
          Aucune réclamation ne pourra être dirigée à ce titre contre le bénéficiaire ni contre B to B Job.
        </p>
        <p>
          L’Utilisateur s’interdit, après l’établissement de la relation commerciale, de se prévaloir de la mauvaise foi d’une partie 
          ou de modifier ultérieurement son profil dans le but d’obtenir un avantage indu ou de se soustraire à ses obligations contractuelles.
        </p>
        <p>
          L’Utilisateur qui propose un bien ou un service assume seul la responsabilité de la bonne exécution de son obligation 
          de fournir ou de délivrer l’objet de l’offre conformément aux termes convenus.
        </p>
        <p>
          En cas de paiement en ligne effectué par l’Utilisateur demandeur, il est expressément convenu que la transaction ne pourra être annulée 
          une fois la somme créditée sur le solde de l’Utilisateur offreur.
        </p>
        <p>
          Toute contestation relative à la transaction devra être réglée directement entre les parties, sans engager la responsabilité de la plateforme.
        </p>
        <p>
          Les Utilisateurs sont seuls responsables des dommages directs ou indirects, matériels ou immatériels, 
          qu’ils causent entre eux, à B to B Job et/ou à tout tiers. Ils s’engagent à indemniser B to B Job 
          pour toute demande, réclamation ou condamnation à des dommages et intérêts, ainsi que pour tous frais de défense (procédure, conseils, avocats…), 
          dès lors que ces actions résultent d’informations fournies par les Utilisateurs sur le Site ou de la réalisation d’offres basées sur ces informations.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 14 – Fiabilité des transactions et système de notation</h2>
        <p>
          L’Utilisateur assume seul la responsabilité de s’assurer de l’exactitude et de l’exhaustivité des informations relatives aux caractéristiques essentielles de l’offre ou de la demande.
        </p>
        <p>
          B to B Job ne peut garantir l'identité exacte de chaque Utilisateur, mais met à leur disposition un système de commentaires 
          et de notations permettant d’évaluer leurs interlocuteurs au cours ou à l’issue d’une transaction.
        </p>
        <p>
          La notation réciproque est obligatoire à la fin de chaque transaction. 
          Les Utilisateurs attribuent une note comprise entre 1 et 5 étoiles (5 étoiles étant la note maximale) sur deux critères distincts : Satisfaction et Sympathie.
        </p>
        <p>
          La moyenne pondérée des évaluations obtenues est affichée sur le profil de chaque Utilisateur. 
          En cliquant sur cette évaluation, les moyennes des notes spécifiques pour chaque critère sont accessibles. 
          Par ailleurs, les Utilisateurs peuvent rédiger des commentaires sur les personnes avec lesquelles ils ont conclu une transaction.
        </p>
        <p>
          L’Utilisateur évalué peut répondre aux avis publiés à son sujet, mais il lui est impossible de supprimer un commentaire ou une note.
          Chaque Utilisateur est responsable de ses évaluations, commentaires et de son usage du site, 
          et doit respecter les présentes conditions générales ainsi que les lois en vigueur. 
          Les évaluations doivent être rigoureuses, objectives, et ne contenir aucun jugement de valeur, discrimination, parti-pris, préjugé 
          ou atteinte à l’intégrité physique ou morale. Elles ne doivent pas manipuler ou déformer la valeur réelle d’un Utilisateur de manière mensongère.
        </p>
        <p>
          B to B Job s’engage à surveiller la crédibilité des notations et commentaires. 
          Elle se réserve le droit de supprimer toute évaluation non conforme à ces règles 
          ou de suspendre/exclure le compte de l’Utilisateur concerné.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 15 - Cookies</h2>
        <p>
          Lors de la navigation sur le site, des cookies peuvent être automatiquement installés sur le logiciel de navigation de l’Utilisateur.
          Les cookies sont de petits fichiers temporaires déposés sur le disque dur de l’appareil de l’Utilisateur.
        </p>
        <p>
          Ces cookies sont essentiels pour garantir l’accès et la fluidité de la navigation sur le site.
          Ils ne contiennent aucune donnée personnelle permettant d’identifier l’Utilisateur.
        </p>
        <p>
          Les informations collectées via ces cookies sont utilisées uniquement pour optimiser l’expérience et les performances de navigation.
          En poursuivant la navigation sur le site, l’Utilisateur consent à l’utilisation des cookies.
        </p>
        <p>
          Cependant, il peut désactiver ces cookies à tout moment en configurant les paramètres de son navigateur.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 16 - Protection des données personnelles</h2>
        <p>
          En application de la loi 78-17 du 6 janvier 1978 modifiée par la loi n°2018-493 du 20 juin 2018, 
          il est rappelé que les données nominatives demandées au Client sont nécessaires au traitement de sa commande 
          et à l'établissement des factures, notamment.
        </p>
        <p>
          Ces données peuvent être communiquées aux éventuels partenaires du Vendeur chargés de l'exécution, 
          du traitement, de la gestion et du paiement des commandes.
        </p>
        <p>
          Le traitement des informations communiquées par l'intermédiaire du site internet 
          <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a> répond aux exigences légales 
          en matière de protection des données personnelles, le système d'information utilisé assurant une protection optimale de ces données 
          et notamment le RGPD, ainsi que toute autre disposition législative ou réglementaire en vigueur.
        </p>
        <p>
          Les modalités de collecte et de traitement des données à caractère personnel ainsi que les droits dont disposent les Clients sur celles-ci 
          peuvent être consultés dans la rubrique «Politique de confidentialité du Vendeur », 
          que le Client doit consulter et accepter, notamment au moment de la passation de sa commande.
        </p>
        <p>
          Le Client dispose, conformément aux réglementations nationales et européennes en vigueur d'un droit d'accès permanent, 
          de modification, de rectification, d'opposition de portabilité et de limitation du traitement s'agissant des informations le concernant.
        </p>
        <p>
          Ce droit peut être exercé dans les conditions et selon les modalités définies sur le site internet 
          <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a>.
        </p>
        <p>
          Le Client, constatant qu'une violation au règlement général sur la protection des données personnelles aurait été commise, 
          a la possibilité de mandater une association ou un organisme mentionné au IV de l'article 43 ter de la loi informatique et liberté de 1978, 
          afin d'obtenir contre le responsable de traitement ou sous-traitant, réparation devant une juridiction civile ou administrative 
          ou devant la commission nationale de l'informatique et des libertés.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 17 - Propriété intellectuelle</h2>
        <p>
          Le contenu du site internet <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a> 
          est la propriété du Vendeur et de ses partenaires et est protégé par les lois françaises et internationales relatives à la propriété intellectuelle.
        </p>
        <p>
          Toute reproduction totale ou partielle de ce contenu est strictement interdite et est susceptible de constituer un délit de contrefaçon.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 18 - Imprévision</h2>
        <p>
          En cas de changement de circonstances imprévisibles lors de la conclusion du contrat, conformément aux dispositions de l'article 1195 du Code civil, 
          la Partie qui n'a pas accepté d'assumer un risque d'exécution excessivement onéreuse peut demander une renégociation du contrat à son cocontractant.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 19 - Force majeure</h2>
        <p>
          Les Parties ne pourront être tenues pour responsables si la non-exécution ou le retard dans l'exécution de l'une quelconque de leurs obligations, 
          telles que décrites dans les présentes découle d'un cas de force majeure, au sens de l'article 1218 du Code civil 
          ou d'aléas sanitaires ou climatiques exceptionnels indépendants de la volonté des Parties.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 20 - Droit applicable - Langue</h2>
        <p>
          Les présentes Conditions générales de vente et les opérations qui en découlent sont régies par le droit français.
          Elles sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues, 
          seul le texte français ferait foi en cas de litige.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 21 - Litiges</h2>
        <p>
          Tout litige entre un Client et le Vendeur sera porté devant les juridictions de DIJON (21000).
          La compétence de la juridiction désignée est exclusive de toute autre compétence, notamment :
        </p>
        <ul className="list-disc ml-5">
          <li>celle de la juridiction du lieu du domicile d'une partie ;</li>
          <li>celle, le cas échéant, de la juridiction indiquée sur les documents propres à une partie et échangés entre les parties à l'occasion des relations qu'elles ont nouées en vue de conclure ou d'exécuter le Contrat, telles que les conditions générales des parties.</li>
        </ul>
        <p>
          Le juge de l'urgence de la juridiction désignée est compétent pour statuer sur les demandes provisoires et conservatoires justifiées par l'urgence.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ARTICLE 22 - Information précontractuelle - Acceptation du Client</h2>
        <p>
          Le Client reconnaît avoir été informé, préalablement à la passation de sa commande et à la conclusion de la vente, 
          d'une manière lisible et compréhensible, au moyen de la mise à disposition des présentes Conditions Générales de Vente 
          et conformément aux dispositions de l'article L 221-5 du Code de la consommation :
        </p>
        <ul className="list-disc ml-5">
          <li>sur les caractéristiques essentielles des Services, compte tenu du support de communication utilisé et du Service concerné ;</li>
          <li>sur le prix des Services et l'application d'un prix personnalisé sur la base d'une prise de décision automatisée et des frais annexes ou, en l'absence de paiement d'un prix, sur tout avantage procuré au lieu ou en complément de celui-ci et sur la nature de cet avantage ;</li>
          <li>sur les modalités de paiement, de fourniture et d'exécution du contrat ;</li>
          <li>en l'absence d'exécution immédiate du contrat, sur la date ou le délai auquel le Vendeur s'engage à fournir les Services commandés ;</li>
          <li>sur l'identité du Vendeur, ses coordonnées postales, téléphoniques et électroniques, ainsi que sur ses activités, si elles ne ressortent pas du contexte,</li>
          <li>sur les garanties légales et contractuelles et leurs modalités de mise en oeuvre ;</li>
          <li>sur la possibilité de recourir à une médiation conventionnelle en cas de litige ;</li>
          <li>sur le droit de rétractation (existence, conditions, délai, modalités d'exercice de ce droit et formulaire type de rétractation), les modalités de résiliation et autres conditions contractuelles importantes et, le cas échéant, sur les coûts de l'utilisation de la technique de communication à distance, l'existence de codes de bonne conduite et les cautions et garanties financières ;</li>
          <li>sur les moyens de paiement acceptés.</li>
        </ul>
        <p>
          Le fait pour un Client, de commander sur le site Internet <a href="https://btobjob.com/" className="text-blue-600 underline">https://btobjob.com/</a> 
          emporte adhésion et acceptation pleine et entière des présentes Conditions Générales de Vente et obligation au paiement des Services commandés, 
          ce qui est expressément reconnu par le Client, qui renonce, notamment, à se prévaloir de tout document contradictoire, 
          qui serait inopposable au Vendeur.
        </p>

        <h2 className="text-xl font-semibold mt-8 mb-4">ANNEXE :</h2>
        <p className="font-semibold">Modèle de formulaire de rétractation à l’attention du consommateur :</p>
        <p>
          À l’attention de la société BTOB JOB <br />
          4 rue de la Fabrique – 21560 COUTERNON <br />
          E-mail : btobjob2024@gmail.com
        </p>
        <p>
          Je/Nous vous notifie/notifions par la présente ma/notre (*) rétractation du contrat portant sur la prestation de services suivante :
          <br />
          • Description du service concerné :
          <br />
          • Commandé le/ Reçu le : …
        </p>
        <p>
          Nom du (des) consommateur(s) : …<br />
          Adresse du (des) consommateur(s) : …<br />
          Signature du (des) consommateur(s) : (uniquement en cas de notification sur papier)…<br />
          Date : …
        </p>
      </div>
      <FooterSection />
    </>
  );
};

export default Cgv;
