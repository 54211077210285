import React, { useState, useEffect } from "react";
import { TiTickOutline } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";

import ListingImage from "../assests/newsectionimage.png";
import WrongEllipse from "../assests/wronghomeellipse.png";
import RightEllipse from "../assests/righthomeellipse.png";
import btnArrow from "../assests/inputbtnarrow.png";
import api from "../services/api";

const Homelisting = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const NavigateToChoice = () => {
    navigate("/professionaluser");
  };

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await api.getSession();
        if (response.user) {
          setUser(response.user);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error("Error fetching session:", error);
        setUser(null);
      }
    };
    fetchSession();
  }, []);

  return (
    <div className="bg-white z-10 mb-0 relative ">
      {/* 
        Use flex-col for mobile (stack items),
        and on medium+ screens we switch to a two-column grid if needed.
        But we specifically hide the image container for md+ screens.
      */}
      <div className="flex flex-col items-center md:grid md:grid-cols-1 md:items-center md:pb-10">
        
        {/* TEXT SECTION */}
        <div className="above-listing-section px-4 md:px-0">
          {/* 
            - text-center on mobile
            - text-3xl for mobile, bigger on md+ (40px)
          */}
          {/* <h2 className="
            text-center 
            text-3xl 
            leading-[40px]
            font-semibold 
            sm:text-[50px] 
            sm:leading-[50px]
            translatable-text
          ">
            Trouvez 
            <span className="span-les-travaux-en"> le professionnel </span>
            <br />
            dont vous avez besoin !
          </h2> */}

          <p className="
            
            translatable-text
            
            text-center 
            md:text-center
            
              px-0
            md:px-72
            md:text-2xl
          ">
         
            Déposez Votre Projet Gratuitement sur btobjob.com !
            Particuliers, lancez vos travaux sans stress ! Sur btobjob.com, vous pouvez déposer votre annonce gratuitement et sans engagement. Décrivez simplement vos besoins et recevez des réponses directement dans votre messagerie sécurisée.
            Connectez-vous facilement avec des artisans qualifiés près de chez vous, comparez les offres et choisissez le professionnel qui vous convient le mieux. C’est rapide, simple et totalement gratuit !
          </p>

          <ul className="
            mt-4
            flex
            flex-col
            items-center
            md:ml-11
          
           
            md:text-2xl
          ">
            <li className="flex items-start mb-2 translatable-text">
              <TiTickOutline className="tick-icon" />
              <span className="tick-icon-text ml-1">
                Trouvez les professionnels souhaités en quelques minutes.
              </span>
            </li>
            <li className="flex items-start mb-2 translatable-text">
              <TiTickOutline className="tick-icon" />
              <span className="tick-icon-text ml-1">
                Services disponibles 24h/7j.
              </span>
            </li>
            <li className="flex items-start mb-2 translatable-text">
              <TiTickOutline className="tick-icon" />
              <span className="tick-icon-text ml-1">
                Assistant virtuel/Intelligence artificielle, Jooby !
              </span>
            </li>
          </ul>

          {/* If user is not logged in, show small-screen buttons */}
         
        </div>

        {/* 
          IMAGE SECTION 
          Hidden on md+ (desktop)
          Visible only on mobile/tablet (< md)
        */}
        <div className="block hidden mx-auto mt-10 relative">
          <img
            src={RightEllipse}
            width={40}
            className="right-ellipse absolute -top-4 left-6"
            alt="Right Ellipse"
          />
          <img
            src={WrongEllipse}
            width={40}
            className="wrong-ellipse absolute top-10 -left-2"
            alt="Wrong Ellipse"
          />
          <img
            src={ListingImage}
            width={200}
            className="listing-main-image relative z-10"
            alt="Listing"
          />
        </div>
      </div>
    </div>
  );
};

export default Homelisting;
