import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Rate2 = ({ boolean }) => {
    const [localToggle, setLocalToggle] = useState(boolean);

    // State for hourly rate
    const [hourlyRate, setHourlyRate] = useState(50); // Default value for hourly rate

    useEffect(() => {
        setLocalToggle(boolean);
    }, [boolean]);

    useEffect(() => {
        if (localToggle) {
            console.log('Le formulaire a été activé. Récupération des recommandations...');
            // Fetch recommendations or perform any other necessary actions
        }
    }, [localToggle]);

    const handleSave = async () => {
        console.log(`Tarif horaire défini sur : ${hourlyRate}`);

        try {
            const response = await axios.post('https://btobjob.com/storePrice.php', {
                hourlyRate
            }, { withCredentials: true });

            console.log(response.data.message);

            // Refresh the page after successful save
            window.location.reload();
        } catch (error) {
            console.error('Erreur lors de la sauvegarde des données :', error);
        }

        setLocalToggle(false);
    };

    const handleCancel = () => {
        setLocalToggle(false);
    };

    return (
        <div className={`${localToggle ? "flex" : "hidden"} items-center justify-center fixed inset-0 z-10 bg-black/50 z-[9999]`}>
            <div className="bg-white rounded-md shadow-md p-4">
                <h2 className="text-lg font-bold mb-4">Définissez votre tarif horaire</h2>
                <h3 className="text-lg font-bold mt-6">Tarif Horaire (€)</h3>
                <div className="mt-4 mb-6">
                    <input
                        type="number"
                        min={0}
                        max={1000}
                        value={hourlyRate}
                        onChange={(e) => setHourlyRate(e.target.value)} // Update hourly rate state
                        className="border border-gray-300 rounded-lg px-3 py-2 w-full"
                    />
                </div>
                <button
                    className="bg-[#65A406] hover:bg-[#65A406] text-white font-bold py-2 px-4 rounded-md mt-4"
                    onClick={handleSave} // Handle save button click
                >
                    Sauvegarder
                </button>
                <button
                    className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded-md mt-4 ml-4"
                    onClick={handleCancel} // Handle cancel button click
                >
                    Annuler
                </button>
            </div>
        </div>
    );
};

export default Rate2;
