import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SiretVerification() {
    const [formData, setFormData] = useState({
        siret: '',
        insurance: null,
        civilLiability: null,
    });
    const [noInsurance, setNoInsurance] = useState(false);
    const [noCivilLiability, setNoCivilLiability] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const checkSiret = async () => {
            try {
                const response = await axios.post('https://btobjob.com/check-siret.php', {}, {
                    withCredentials: true,
                });

                if (response.data.status === "success" && response.data.siretExists) {
                    navigate('/profile/viewprofile');
                } else {
                    setMessage(response.data.message || "Veuillez entrer votre SIRET pour continuer.");
                }
            } catch (error) {
                console.error("Erreur lors de la vérification du SIRET :", error);
                setMessage("Une erreur s'est produite lors de la vérification du SIRET.");
            }
        };

        checkSiret();
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value,
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        if (name === 'noInsurance') {
            setNoInsurance(checked);
            if (checked) {
                setFormData({ ...formData, insurance: null });
            }
        } else if (name === 'noCivilLiability') {
            setNoCivilLiability(checked);
            if (checked) {
                setFormData({ ...formData, civilLiability: null });
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage('');

        const form = new FormData();
        form.append('siret', formData.siret);
        if (!noInsurance && formData.insurance) form.append('insurance', formData.insurance);
        if (!noCivilLiability && formData.civilLiability) form.append('civilLiability', formData.civilLiability);

        try {
            const response = await axios.post('https://btobjob.com/siret-signup-pro.php', form, {
                withCredentials: true,
            });

            if (response.data.status === "success") {
                navigate('/profile/viewprofile');
            } else {
                setMessage(response.data.message || "Échec de la vérification du SIRET.");
            }
        } catch (error) {
            console.error("Erreur lors de la vérification du SIRET avec des détails :", error.response || error);
            setMessage("Une erreur s'est produite lors de la vérification du SIRET. Voir la console pour plus de détails.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md border border-lime-500 w-full max-w-md">
                <h2 className="text-xl font-bold mb-6 text-center text-gray-800">Étape finale</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="flex flex-col">
                        <label htmlFor="siret" className="text-gray-700 font-semibold mb-1">
                            Numéro SIRET :
                        </label>
                        <input
                            id="siret"
                            type="text"
                            name="siret"
                            value={formData.siret}
                            onChange={handleChange}
                            placeholder="Entrez votre numéro de SIRET"
                            required
                            className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-lime-500"
                        />
                    </div>

                    <div>
                        <label className="text-gray-700 font-semibold mb-1 block">
                            Téléchargez votre Assurance Décennale :
                        </label>
                        <input
                            type="file"
                            name="insurance"
                            onChange={handleChange}
                            disabled={noInsurance}
                            required={!noInsurance}
                            className="w-full text-sm border border-gray-300 rounded p-2"
                        />
                        <label className="flex items-center mt-2 text-sm text-gray-600">
                            <input
                                type="checkbox"
                                name="noInsurance"
                                onChange={handleCheckboxChange}
                                className="mr-2"
                            />
                            Je n'ai pas d'assurance
                        </label>
                    </div>

                    <div>
                        <label className="text-gray-700 font-semibold mb-1 block">
                            Téléchargez votre Responsabilité Civile :
                        </label>
                        <input
                            type="file"
                            name="civilLiability"
                            onChange={handleChange}
                            disabled={noCivilLiability}
                            required={!noCivilLiability}
                            className="w-full text-sm border border-gray-300 rounded p-2"
                        />
                        <label className="flex items-center mt-2 text-sm text-gray-600">
                            <input
                                type="checkbox"
                                name="noCivilLiability"
                                onChange={handleCheckboxChange}
                                className="mr-2"
                            />
                            Je n'ai pas de responsabilité civile
                        </label>
                    </div>

                    <div className="text-center">
                        <button
                            type="submit"
                            className="px-6 py-2 bg-lime-500 text-white rounded-lg hover:bg-lime-600 disabled:opacity-50"
                            disabled={loading}
                        >
                            {loading ? "Vérification en cours..." : "Soumettre"}
                        </button>
                    </div>
                </form>
                {message && <p className="mt-6 text-center text-red-500">{message}</p>}
            </div>
        </div>
    );
}

export default SiretVerification;
