import React, { useState, useEffect } from 'react';
import { FaArrowLeft } from "react-icons/fa";
import { IoShareSocialOutline } from "react-icons/io5";
import { useNavigate, useLocation } from 'react-router-dom';
import MessageModal from '../OfferPage/MessageModal';
import axios from 'axios';
import { HelmetProvider } from 'react-helmet-async';
import Share from '../Share';
import api from '../../services/api';
const API_URL = 'https://btobjob.com';

// Utility to strip slashes
const stripSlashes = (input) => input.replace(/\\/g, '');

// Utility to decode HTML entities
const decodeHTML = (input) => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = input;
  return textarea.value;
};

const AdsDetails = () => {
  const [ad, setAd] = useState(null); // State to hold ad details
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [openShareModal, setOpenShareModal] = useState(false); // State to control share modal
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState(null); 
  const navigate = useNavigate();
  const location = useLocation();

  // Get ad_id from query params
  const query = new URLSearchParams(location.search);
  const adId = query.get('ad_id');

  useEffect(() => {
    const fetchAdDetails = async () => {
      if (!adId) {
        setError('No ad ID provided.');
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        const response = await axios.get(`${API_URL}/fetch-ad-details.php`, {
          params: { ad_id: adId },
          withCredentials: true,
        });

        if (response.data && response.data.ad) {
          const sanitizedAd = {
            ...response.data.ad,
            title: decodeHTML(stripSlashes(response.data.ad.title)),
            description: decodeHTML(stripSlashes(response.data.ad.description)),
            name: decodeHTML(stripSlashes(response.data.ad.name || '')),
            profession: decodeHTML(stripSlashes(response.data.ad.profession || '')),
            address: decodeHTML(stripSlashes(response.data.ad.address || '')),
            city: decodeHTML(stripSlashes(response.data.ad.city || '')),
            display_price: decodeHTML(stripSlashes(response.data.ad.display_price || '')),
            images: response.data.ad.images || [], // Ensure images array is provided
          };
          setAd(sanitizedAd);
        } else {
          setError('Ad not found.');
        }
      } catch (error) {
        console.error('Error fetching ad details:', error);
        setError('Error fetching ad details.');
      } finally {
        setLoading(false);
      }
    };

    fetchAdDetails();
  }, [adId]);

  const handleSendMessage = async (message) => {
    if (!ad?.user_id) {
      console.error('User ID not found for the ad.');
      return;
    }

    if (!message.trim()) {
      console.error('Message is empty.');
      return;
    }

    try {
      const fullMessage = ad.title
        ? `(En réponse à l'annonce: ${ad.title})\n\n${message}`
        : message;

        const response = await api.sendMessagee({
        receiverId: ad.user_id,
        message: fullMessage,
      });
      alert("Message envoyé avec succès !");
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const openMessageModal = () => {
    setIsMessageModalOpen(true);
  };

  const viewProfile = () => {
    if (ad && ad.user_id) {
      navigate(`/profile/specificUser?professional_id=${ad.user_id}`);
    }
  };

  const currentUrl = window.location.href;

  return (
    <>
     <HelmetProvider>

        <title>{ad?.title || "Détails de l'annonce"}</title>
        <meta
          name="description"
          content={ad?.description || "Consultez les détails de cette annonce."}
        />
        <meta
          name="keywords"
          content={`${ad?.profession || ""}, ${ad?.city || ""}, annonces`}
        />
        <meta property="og:title" content={ad?.title || "Détails de l'annonce"} />
        <meta
          property="og:description"
          content={ad?.description || "Consultez les détails de cette annonce."}
        />
        <meta
          property="og:image"
          content={ad?.images?.[0] || "default-image-url.jpg"}
        />
      </HelmetProvider>
    <div className="max-w-4xl mx-auto p-4 mt-10">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
      >
        <FaArrowLeft className="inline mr-2" /> Retour
      </button>

      {/* Report Ad Button */}
      {ad?.id && (
        <a
          href={`https://btobjob.com/report-ad.php?ad_id=${ad.id}`}
          className="float-right mb-6 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Signaler cette annonce
        </a>
      )}

      {/* Loading and Error States */}
      {loading && <p className="text-center">Chargement...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}

      {/* Ad Details */}
      {ad && (
        <div className="bg-white shadow-md rounded-lg p-4">
          {/* Share and Message Buttons */}
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setOpenShareModal(true)}
              className="flex items-center px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition"
            >
              <IoShareSocialOutline className="mr-2" /> Partager
            </button>
            <button
              onClick={openMessageModal}
              className="ml-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Envoyer un message
            </button>
          </div>

          {/* Profile Picture */}
          <div className="flex items-center justify-center mb-6">
            {ad.ProfilePicture && (
              <img
                src={ad.ProfilePicture}
                alt="Photo de profil"
                className="w-24 h-24 object-cover rounded-full mr-4"
              />
            )}
          </div>

          {/* Ad Information */}
          <h2 className="text-xl font-bold mb-2">Publié par:</h2>
          <h1 className="text-3xl font-bold">{ad.name}</h1>
          <button
            onClick={viewProfile}
            className="mt-2 px-2 py-1 bg-lime-500 text-white rounded hover:bg-lime-600 mb-3"
          >
            Voir le profil
          </button>

          {/* Ad Images */}
           {ad.images.length > 0 && (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
                {ad.images.map((image, index) => (
                  <img key={index} src={image} alt={`Image de l'annonce ${index + 1}`} className="w-full h-60 object-cover rounded-md cursor-pointer"
                    onClick={() => setLightboxImage(image)} />
                ))}
              </div>
            )}

          {/* Ad Content */}
          <h2 className="text-4xl font-bold mb-2">{ad.title}</h2>
          <p className="text-2xl text-gray-700 mb-4">{ad.description}</p>

          <p className="">Profession: {ad.profession}</p>
          <p className="">Adresse: {ad.address}</p>
          <p className="">Ville: {ad.city}</p>
          <p className="">Prix: {ad.display_price}</p>
        </div>
      )}

      {/* Share Modal */}
      {lightboxImage && (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[9999999]">
        <div className="relative max-w-full max-h-full p-4">
            {/* Close Button */}
            <button
                onClick={() => setLightboxImage(null)}
                className="absolute top-2 right-2 text-white text-xl bg-red-500 rounded-full p-2 hover:bg-red-700"
                aria-label="Close Lightbox"
            >
                ✕
            </button>
            {/* Lightbox Image */}
            <img
                src={lightboxImage}
                alt="Lightbox"
                className="max-w-[90vw] max-h-[90vh] rounded-lg object-contain"
            />
        </div>
    </div>
)}

     
      {openShareModal && (
        <Share
          profileUrl={currentUrl}
          closeModal={() => setOpenShareModal(false)}
        />
      )}

      {/* Message Modal */}
      {isMessageModalOpen && (
        <MessageModal
          isOpen={isMessageModalOpen}
          onClose={() => setIsMessageModalOpen(false)}
          onSend={handleSendMessage}
        />
      )}
    </div>
    </>
  );
};

export default AdsDetails;
