import React, { useState, useEffect } from "react";
import axios from "axios";

const BioModal = ({ userId, onClose, onUpdateSuccess }) => {
  const [bio, setBio] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch existing bio and job title on mount
    const fetchProfileData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://btobjob.com/get-professional-data.php`,
          { withCredentials: true }
        );

        if (response.data.success) {
          const { bio, job_title } = response.data.data || {};
          setBio(bio || "");
          setJobTitle(job_title || "");
        } else {
          setMessage({ type: "error", text: response.data.message || "Failed to fetch profile data." });
        }
      } catch (error) {
        setMessage({ type: "error", text: "Error fetching profile data." });
        console.error("Error fetching profile data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (userId) fetchProfileData();
  }, [userId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);

    try {
      const response = await axios.post(
        "https://btobjob.com/update-bio-jsx.php",
        { user_id: userId, bio, job_title: jobTitle },
        { withCredentials: true }
      );

      if (response.data.success) {
        setMessage({ type: "success", text: "Profile updated successfully!" });
        if (onUpdateSuccess) onUpdateSuccess(); // Trigger parent callback
        if (onClose) onClose(); // Close modal on success
      } else {
        setMessage({ type: "error", text: response.data.message || "Failed to update profile." });
      }
    } catch (error) {
      setMessage({ type: "error", text: "Error updating profile." });
      console.error("Error updating profile:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="fixed inset-0 z-[9999] bg-black bg-opacity-50 flex items-center justify-center"
      role="dialog"
      aria-labelledby="bio-modal-title"
      aria-describedby="bio-modal-description"
    >
      <div className="relative bg-white rounded-lg shadow-lg w-full max-w-md sm:max-w-lg p-6 sm:p-8 overflow-auto max-h-full">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 text-2xl"
          aria-label="Close modal"
        >
          &times;
        </button>
        <h2 id="bio-modal-title" className="text-lg font-medium mb-4">
        Mettre à jour la biographie et le titre du poste
        </h2>

        {loading && <p className="text-gray-500 mb-2">Chargement...</p>}
        {message && (
          <p
            className={`mb-2 ${
              message.type === "error" ? "text-red-600" : "text-green-600"
            }`}
          >
            {message.text}
          </p>
        )}

        <form onSubmit={handleUpdate} className="space-y-4">
          <div>
            <label htmlFor="bio-input" className="block text-sm font-medium mb-1">
              Biographie
            </label>
            <textarea
              id="bio-input"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              rows="4"
              maxLength={350}
              required
              className="w-full p-2 border border-gray-300 rounded"
              aria-describedby="bio-help"
            />
            <small id="bio-help" className="text-gray-500">
            350 caractères maximum.
            </small>
          </div>

          <div>
            <label htmlFor="job-title-input" className="block text-sm font-medium mb-1">
              Intitulé de poste
            </label>
            <input
              id="job-title-input"
              type="text"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              maxLength={50}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={loading}
              className="bg-lime-500 text-white px-4 py-2 rounded hover:bg-lime-600"
            >
              {loading ? "Mise à jour..." : "Mise à jour"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BioModal;
