import React from "react";
import { useNavigate } from "react-router-dom";

import btnArrow from "../assests/inputbtnarrow.png";
import LOGO from "../assests/b2bjob logo 1.png";
import Screw from "../assests/screw.png";
import World from "../assests/World.png";
import Alarm from "../assests/Alarm.png";
import Search from "../assests/search.png";
import IdCard from "../assests/id card.png";
import LessColourStar from "../assests/Star 10.png";

const Developers = () => {
  const navigate = useNavigate();

  const NavigateToLogin = () => {
    navigate("/professionaluser");
  };

  return (
    <div className="bg-white py-4 md:py-8">
      {/* Main container for three columns on md+ screens */}
      <div className="max-w-7xl mx-auto px-4">
        {/*
          1) flex-col on mobile => stacked
          2) md:flex-row on md+ => side by side
          3) justify-center (mobile) => horizontally center columns
             md:justify-between (desktop) => distribute columns
          4) items-center (mobile) => center columns vertically/horizontally
             md:items-start => top-align columns on desktop
        */}
        <div className="flex flex-col md:flex-row justify-center md:justify-between items-center md:items-start gap-8">
          
          {/* Left Column: "Trouver vos Clients" */}
          <div className="flex-1 w-full md:w-auto flex flex-col items-center md:items-start text-center md:text-left">
            <h3 className="text-xl sm:text-3xl font-semibold">
              Trouver vos
              <br />
              <span className="text-lime-600">Clients</span>
            </h3>
            <div className="mt-5 flex flex-col gap-4 items-center md:items-start">
              <div className="flex gap-2 items-center">
                <img src={Screw} width={16} alt="Screw icon" />
                <span>Des milliers de demandes chaque jour</span>
              </div>
              <div className="flex gap-2 items-center">
                <img src={World} width={16} alt="World icon" />
                <span>Partout en France</span>
              </div>
              <div className="flex gap-2 items-center">
                <img src={Alarm} width={16} alt="Alarm icon" />
                <span>Connexion instantanée</span>
              </div>
            </div>
          </div>

          {/* Middle Column (hidden on mobile, shown on md+) */}
          <div className="hidden md:flex flex-col items-center justify-center flex-1">
            <h2 className="text-xl sm:text-2xl font-semibold mb-2">
              Développez votre entreprise avec
            </h2>
            <div className="my-4">
              <img src={LOGO} width={180} alt="B2BJob Logo" />
            </div>
            <button
              onClick={NavigateToLogin}
              className="h-10 bg-gradient-to-b from-lime-500 to-lime-800 text-white py-2 px-4 rounded-md flex items-center"
            >
              Se Connecter Professionnel
              <img
                className="ml-2"
                src={btnArrow}
                width={17}
                alt="Arrow"
              />
            </button>
          </div>

          {/* Right Column: "Développer votre visibilité" */}
          <div className="flex-1 w-full md:w-auto flex flex-col items-center md:items-start text-center md:text-left">
            <h3 className="text-xl sm:text-3xl font-semibold">
              Développer
              <br />
              <span className="text-lime-600">Votre visibilité</span>
            </h3>
            <div className="mt-5 flex flex-col gap-4 items-center md:items-start">
              <div className="flex gap-2 items-center">
                <img src={Search} width={16} alt="Search icon" />
                <span>Référencez votre profil sur les moteurs de recherche</span>
              </div>
              <div className="flex gap-2 items-center">
                <img src={LessColourStar} width={16} alt="Star icon" />
                <span>Collecte de vos avis clients</span>
              </div>
              <div className="flex gap-2 items-center">
                <img src={IdCard} width={16} alt="ID Card icon" />
                <span>Cartes de visite et flyers personnalisés</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile-Only Section (shown on small screens, hidden on md+) */}
      <div className="md:hidden flex flex-col items-center gap-y-2 px-4 mt-8 text-center">
        <h2 className="text-xl sm:text-2xl font-semibold mb-2">
          Développez votre entreprise avec
        </h2>
        <div className="mb-4">
          <img src={LOGO} width={180} alt="B2BJob Logo" />
        </div>
        <button
          onClick={NavigateToLogin}
          className="h-10 bg-gradient-to-b from-lime-500 to-lime-800 text-white py-2 px-4 rounded-md flex items-center"
        >
          Se Connecter Professionnel
          <img
            className="ml-2"
            src={btnArrow}
            width={17}
            alt="Arrow"
          />
        </button>
      </div>
    </div>
  );
};

export default Developers;
