import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditPost = () => {
  const { id: postId } = useParams();
  const navigate = useNavigate();

  const [post, setPost] = useState(null);
  const [formData, setFormData] = useState({ text: '', images: [] });
  const [initialMedia, setInitialMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchPost = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(`https://btobjob.com/fetch-posts-by-id.php/?id=${postId}`);
        setPost(data);
        setFormData({ text: data.text || '', images: [] });
        setInitialMedia(data.images || []);
      } catch (error) {
        setError('Échec du chargement du post. Veuillez réessayer plus tard.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchPost();
  }, [postId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const convertImageToJpeg = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(
            (blob) => {
              const jpegFile = new File([blob], file.name.replace(/\.\w+$/, '.jpg'), {
                type: 'image/jpeg',
              });
              resolve(jpegFile);
            },
            'image/jpeg',
            0.8 // Adjust quality as needed
          );
        };
        img.onerror = () => reject(new Error('Failed to load image'));
        img.src = event.target.result;
      };
      reader.onerror = () => reject(new Error('Failed to read file'));
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);

    const allowedMimeTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/webp',
      'video/mp4',
      'video/webm',
      'video/mov',
      'video/avi',
      'video/mpeg',
      'video/ogg',
      'video/x-matroska',
    ];

    const convertedFiles = await Promise.all(
      selectedFiles.map(async (file) => {
        if (file.type.startsWith('image/') && !file.type.endsWith('jpeg')) {
          return await convertImageToJpeg(file);
        }
        return file; // Skip conversion for JPEG and non-image files
      })
    );

    setFormData((prevData) => ({ ...prevData, images: convertedFiles }));
  };

  const handleMediaDelete = async (mediaId) => {
    try {
      const formData = new FormData();
      formData.append('postId', postId);
      formData.append('imageIdToDelete', mediaId);
  
      const response = await axios.post(
        'https://btobjob.com/delete-images.php',
        formData, // Send as FormData if the server expects it
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
  
      if (response.data.success) {
        setInitialMedia(initialMedia.filter((media) => media.id !== mediaId));
      } else {
        setError(response.data.error || 'Failed to delete the media. Please try again.');
      }
    } catch (error) {
      setError('Failed to delete the media. Please check your connection and try again.');
      console.error('Error deleting media:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    setSuccess('');
    const formDataObj = new FormData();
    formDataObj.append('text', formData.text);
    formData.images.forEach((file, index) => {
      formDataObj.append(`images[${index}]`, file);
    });

    try {
      const response = await axios.post(`https://btobjob.com/update-post.php?id=${postId}`, formDataObj, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (response.data.success) {
        setSuccess('Post updated successfully!');
        navigate('/profile/viewprofile');
      } else {
        setError('Failed to update post. ' + (response.data.error || ''));
      }
    } catch (error) {
      setError('An error occurred while updating the post. Please try again.');
      console.error('Submit error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return <p>Chargement...</p>;
  }

  return (
    <div className="max-w-xl mx-auto bg-white p-6 rounded-lg shadow-md mt-20">
      <h1 className="text-2xl font-semibold mb-4">Modifier le Post</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-green-500 mb-4">{success}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Texte du Post</label>
          <textarea
            name="text"
            value={formData.text}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            rows="4"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Télécharger des Images et Vidéos</label>
       <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="w-full mb-2"
            disabled={isSubmitting}
          />
          <p className="text-sm text-gray-600">Taille maximale des fichiers : 2 Mo.</p>
          {initialMedia.length > 0 && (
            <div className="mt-2">
              <p className="text-sm text-gray-600">Médias actuels :</p>
              <div className="flex gap-2 flex-wrap">
                {initialMedia.map((media) => (
                  <div key={media.id} className="relative">
                    {media.url.endsWith('.mp4') || media.url.endsWith('.webm') ? (
                      <video controls className="w-20 h-20 object-cover rounded-md">
                        <source src={media.url} type="video/mp4" />
                        Votre navigateur ne supporte pas la vidéo.
                      </video>
                    ) : (
                      <img
                        src={media.url}
                        alt={`Média ${media.id}`}
                        className="w-20 h-20 object-cover rounded-md"
                      />
                    )}
                    <button
                      type="button"
                      onClick={() => handleMediaDelete(media.id)}
                      className="absolute top-0 right-0 bg-red-500 text-white text-xs px-1 rounded"
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className={`px-4 py-2 rounded text-white ${
            isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-lime-500 hover:bg-blue-600'
          }`}
        >
          {isSubmitting ? 'Mise à jour...' : 'Mettre à jour le Post'}
        </button>
      </form>
    </div>
  );
};

export default EditPost;
