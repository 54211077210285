import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SearchableDropdown from '../SearchableDropdown';
import { HelmetProvider } from 'react-helmet-async';
const API_URL = 'https://btobjob.com';

const PostServices = () => {
  const [services, setServices] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [radius, setRadius] = useState('10');
  const [cities, setCities] = useState([]);
  const [searchTheme, setSearchTheme] = useState('');
  const [clearInput, setClearInput] = useState(false);

  const [profession, setProfession] = useState('');
  const [urgencyFilter, setUrgencyFilter] = useState('all');
  const [loading, setLoading] = useState(false);
  const [isCityLoading, setIsCityLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [noResultsMessage, setNoResultsMessage] = useState('');
  const navigate = useNavigate();

  const itemsPerPage = 12;
  const typeTranslations = {
    rental_equipment: "Matériel de location",
    sale_equipment: "Matériel à vendre",
    rental_real_estate: "Immobilier professionnel (Location)",
    sale_real_estate: "Immobilier professionnel (Vente)",
    rental_utility_vehicle: "Véhicule utilitaire (Location)",
    sale_utility_vehicle: "Véhicule utilitaire (Vente)",
    clearance_sale: "Déstockage",
  };
  // Fetch cities for dropdown
  const fetchInitialCities = async () => {
      try {
        setIsCityLoading(true);
        const response = await axios.get('https://geo.api.gouv.fr/communes', {
          params: {
            fields: 'nom,codesPostaux',
            format: 'json',
            geometry: 'centre',
            limit: 100, // Fetch only 100 cities
          },
        });
        const cityData = response.data.map((region) => ({
          name: region.nom,
          postalCode: region.codesPostaux || 'N/A', // Handle cases where postal code is missing
        })).sort((a, b) => a.name.localeCompare(b.name));
        setCities(cityData);
      } catch (error) {
        console.error('Error fetching cities:', error);
      } finally {
        setIsCityLoading(false);
      }
    };
    useEffect(() => {
      fetchInitialCities();
    }, []);
    
    const fetchCitySuggestions = async (query) => {
      try {
        if (query.length < 3) return; // Fetch suggestions only if the query is 3+ characters
        setIsCityLoading(true);
        const response = await axios.get('https://geo.api.gouv.fr/communes', {
          params: {
            fields: 'nom,codesPostaux',
            format: 'json',
            geometry: 'centre',
            nom: query, // Use query to search for cities
            limit: 100,
          },
        });
        const citySuggestions = response.data.map((region) => ({
          name: region.nom,
          postalCode: region.codesPostaux || 'N/A',
        })).sort((a, b) => a.name.localeCompare(b.name));
        setCities(citySuggestions);
      } catch (error) {
        console.error('Error fetching city suggestions:', error);
      } finally {
        setIsCityLoading(false);
      }
    };

  // Fetch services from API
  const fetchServices = async (page = currentPage) => {
    setLoading(true);
    try {
      const params = {
        page,
        itemsPerPage,
        search: searchTheme || undefined,
        profession: profession || undefined,
        urgency: urgencyFilter !== 'all' ? urgencyFilter : undefined,
        radius: radius || undefined,
        location: selectedCity || undefined,
        latitude: latitude || undefined,
        longitude: longitude || undefined,
      };

      const { data } = await axios.get(`${API_URL}/get-rentals.php`, {
        params,
        withCredentials: true,
      });

      if (data && data.results && Array.isArray(data.results.ads_rental)) {
        setServices((prev) =>
          page === 1 ? data.results.ads_rental : [...prev, ...data.results.ads_rental]
        );
        setHasMore(data.results.ads_rental.length > 0);
        setNoResultsMessage(
          page === 1 && data.results.ads_rental.length === 0
            ? 'No results found.'
            : ''
        );
      } else {
        setServices([]);
        setHasMore(false);
        setNoResultsMessage('No results found.');
      }
    } catch (error) {
      console.error('Error fetching services:', error);
      setServices([]);
      setHasMore(false);
      setNoResultsMessage('Error fetching data.');
    } finally {
      setLoading(false);
    }
  };

  // Trigger data fetch on dependency change
  useEffect(() => {
    fetchServices();
  }, [currentPage, radius, searchTheme, profession, urgencyFilter, latitude, longitude]);
  const handleUrgencyChange = (e) => {
    setUrgencyFilter(e.target.value);
    setCurrentPage(1); // Reset to the first page
  };
  // Handle navigation to service details
  const handleServiceClick = (id) => {
    navigate(`/profile/rentalAds/${id}`);
  };

  // Fetch coordinates for the selected city
  const fetchCoordinates = async (city) => {
    setIsCityLoading(true);
    try {
      const response = await axios.get(`${API_URL}/geocode.php`, {
        params: { q: city, format: 'json', addressdetails: 1 },
      });

      if (response.data.lat && response.data.lon) {
        setLatitude(response.data.lat);
        setLongitude(response.data.lon);
        fetchServices();
      } else {
        alert('City not found.');
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      alert('Unable to fetch coordinates for the city.');
    } finally {
      setIsCityLoading(false);
    }
  };

  // Handle location-based filtering
  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setSelectedCity('');
          setCurrentPage(1);
          fetchServices();
        },
        (error) => {
          console.error('Error fetching location:', error);
          alert('Unable to fetch your current location.');
        }
      );
    } else {
      alert('Geolocation is not supported by your browser.');
    }
  };
  const clearFilters = () => {
    setSelectedCity('');
    setLatitude(null);
    setLongitude(null);
    setRadius('10'); // Reset to default radius
    setSearchTheme('');
    setProfession('');
    setUrgencyFilter('all');
    setCurrentPage(1); // Reset to the first page
    setServices([]); // Clear the current services
    setNoResultsMessage('');
    setClearInput(true); // Trigger input clearing for SearchableDropdown
  
    // Reset the clearInput state after clearing
    setTimeout(() => setClearInput(false), 0);
  
    fetchServices(1); // Fetch services with default filters
  };
  
  
  return (
    <>
     <HelmetProvider>
        <title>Annonces et Demandes | BtoBJob</title>
        <meta
          name="description"
          content="Explorez des annonces professionnelles et des demandes à proximité ou dans une ville spécifique."
        />
        <meta property="og:title" content="Annonces et Demandes | BtoBJob" />
        <meta
          property="og:description"
          content="Filtrez les annonces par métier, urgence, rayon, ou ville pour trouver ce dont vous avez besoin."
        />
        <meta property="og:image" content="https://btobjob.com/logo.png" />
        <meta property="og:url" content="https://btobjob.com/profile/services_rentals" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Annonces et Demandes | BtoBJob" />
        <meta
          name="twitter:description"
          content="Trouvez des annonces professionnelles adaptées à vos besoins avec des filtres avancés."
        />
        <meta name="twitter:image" content="https://btobjob.com/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </HelmetProvider>
    <div className="max-w-6xl mx-auto p-4 mt-10">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Location/Vente</h1>
        <button
          className="bg-gray-800 text-white px-3 py-2 rounded-md focus:outline-none"
          onClick={() => setShowFilters((prev) => !prev)}
        >
       ☰ Filtres
        </button>
      </div>

      {/* Filters Section */}
      <div className="max-w-6xl mx-auto p-4 mt-10">
      {showFilters && (
        <>
          <div className="mb-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {/* Inputs Row */}
            <div className="flex flex-col col-span-1">
            <label htmlFor="radius" className="text-sm font-semibold mb-1">
      Rayon (km): Tous: {radius || 'Toute'}
    </label>
              <input
                type="number"
                value={radius}
                onChange={(e) => setRadius(e.target.value || '')}
                className="w-full px-2 py-1 border rounded"
                onWheel={(e) => e.target.blur()} // Disable scroll increment/decrement
              />
            </div>
            <div className="flex flex-col col-span-1">
              <label className="text-sm font-semibold mb-1">Recherche par mots-clés</label>
              <input
                type="text"
                value={searchTheme}
                onChange={(e) => setSearchTheme(e.target.value)}
                className="w-full px-2 py-1 border rounded"
              />
            </div>
            <div className='flex flex-col col-span-1'>
            <label htmlFor="urgency" className="block text-sm font-semibold mb-1">
              Urgence
            </label>
            <select
              id="urgency"
              value={urgencyFilter}
              onChange={handleUrgencyChange}
              className="w-full px-2 py-1 border rounded"
            >
              <option value="all">Tous</option>
              <option value="urgent">Urgent</option>
              <option value="non-urgent">Non-Urgent</option>
            </select>
          </div>
            <div className="flex flex-col col-span-1">
              <label className="text-sm font-semibold mb-1">Sélectionnez un métier</label>
              <select
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
                className="w-full px-2 py-1 border rounded"
              >
               <option value="">Tous</option>
              <option value="rental_equipment">Matériel de location</option>
              <option value="sale_equipment">Matériel Vente</option>
              <option value="rental_real_estate">Immobilier Pro Location</option>
              <option value="sale_real_estate">Immobilier Pro Vente</option>
              <option value="rental_utility_vehicle">Véhicule utilitaire Location</option>
              <option value="sale_utility_vehicle">Véhicule utilitaire Vente</option>
              <option value="clearance_sale">Déstockage</option>
              </select>
            </div>
            <div className="flex flex-col col-span-1">
              <label className="text-sm font-semibold mb-1">Sélectionnez la ville</label>
              <SearchableDropdown
         options={cities.map((city) => ({
          label: `${city.name} (${city.postalCode})`, // Combine city name and postal code
          value: city.name,
        }))}
        selectedOption={selectedCity}
        setSelectedOption={setSelectedCity}
        fetchSuggestions={fetchCitySuggestions} // Pass the function correctly here
        placeholder="Rechercher ou sélectionner"
        clearInput={clearInput}
      />
            </div>
          </div>

          {/* Buttons Row */}
          <div className="mb-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className="col-span-1">
              <button
                onClick={handleUseCurrentLocation}
                className="bg-blue-500 text-white px-3 py-2 rounded-md w-full"
              >
                Géolocalisation de ma position
              </button>
            </div>
            <div className="col-span-1">
              <button
                className={`bg-green-500 text-white px-3 py-2 rounded-md w-full ${isCityLoading ? 'opacity-70' : ''}`}
                onClick={() => fetchCoordinates(selectedCity)}
                disabled={isCityLoading}
              >
                Rechercher
              </button>
            </div>
            <div className="col-span-1">
              <button
                onClick={clearFilters}
                className="bg-red-500 text-white px-3 py-2 rounded-md w-full"
              >
                Réinitialiser les filtres
              </button>
            </div>
          </div>
        </>
      )}
    </div>

      {/* Results Section */}
      <div className="bg-white rounded-xl max-w-6xl md:mt-16 mt-2 mx-auto">
        <div className="flex flex-col p-4 md:p-2">
          {loading && currentPage === 1 ? (
            <p>Chargement des locations...</p>
          ) : services.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-2 py-4 gap-3">
              {services.map((service) => {
    // Extract the first image from bulk uploads or use a placeholder
    const firstImage = service.image?.split(',')[0] || 'https://btobjob.com/uploads/default-placeholder.png';

    return (
      <div
        key={service.id}
        className="mx-auto w-full shadow-md rounded-lg p-2 px-2 overflow-hidden cursor-pointer"
        onClick={() => handleServiceClick(service.id)}
      >
        <div className="flex h-[250px]">
          <img
            className="h-full object-contain w-32"
            src={firstImage} // Display only the first image from bulk uploads
            alt={service.title}
          />
          <div className="p-1 w-full relative">
            <div className="font-semibold text-base">{service.title}</div>
            <div className="text-gray-600 text-sm font-semibold mt-2">{service.created_at}</div>
            <div className="text-gray-600 text-sm font-semibold mt-2">
              {typeTranslations[service.type] || "Type inconnu"}
            </div>
            <div className="text-gray-600 text-sm font-semibold mt-2">{service.city}</div>
            {service.boosted === 1 && (
              <div className="absolute top-0 right-0 bg-red-600 text-white text-xs px-2 py-1 rounded-md">
                Urgent
              </div>
            )}
            <div className="absolute bottom-0 p-1">
            <span className="bg-[#65A406] text-white px-2 py-1 rounded-md">
            {service.display_price || "Demander un devis"} 
                        </span>
            </div>
          </div>
        </div>
      </div>
    );
  })}
</div>
          ) : !loading ? (
            <p className="text-center text-gray-500">Aucun service trouvé. Essayez d'ajuster vos filtres.</p>
          ) : null}

          {/* Load More Button */}
          {hasMore && !loading && (
            <div className="mt-6 flex justify-center">
              <button
                onClick={() => setCurrentPage((prev) => prev + 1)}
                className="px-4 py-2 rounded-md bg-lime-500 text-white hover:bg-blue-600"
              >
                Charger plus
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default PostServices;
