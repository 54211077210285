import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import api from "../../services/api";

const CoverModal = ({ onClose }) => {
  const [coverImage, setCoverImage] = useState(null);
  const coverCropperRef = useRef(null);

  const handleUpdateCoverImage = async () => {
    const formData = new FormData();

    // Add the cropped cover image
    if (coverCropperRef.current?.cropper) {
      const coverBlob = await new Promise((resolve) => {
        coverCropperRef.current.cropper.getCroppedCanvas().toBlob(resolve, "image/jpeg");
      });
      if (coverBlob) {
        formData.append("coverImage", coverBlob, "cover.jpg");
      }
    }

    // Validate that a cover image is selected
    if (!formData.has("coverImage")) {
      alert("Veuillez sélectionner une image de couverture à mettre à jour.");
      return;
    }

    try {
      const response = await api.updateImages(formData);
      if (response.success) {
        alert("Image de couverture mise à jour avec succès.");
        window.location.reload(); // Reload the page to reflect changes
      } else {
        alert(`La mise à jour a échoué : ${response.message || "Erreur inconnue."}`);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'image de couverture :", error);
      alert("Une erreur s'est produite lors de la mise à jour de l'image de couverture.");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setCoverImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center z-[9999]">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-3xl relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
        >
          &times;
        </button>

        <div className="flex flex-col">
          <div className="px-8">
            {/* Cover Image Cropper */}
            <div className="flex flex-col items-center mb-6">
              <label className="text-lg font-medium mb-2">Image de couverture :</label>
              <input type="file" accept="image/*" onChange={handleImageChange} />
              {coverImage && (
                <Cropper
                  src={coverImage}
                  style={{ height: 200, width: "100%" }}
                  aspectRatio={3 / 1}
                  guides={false}
                  ref={coverCropperRef}
                />
              )}
            </div>

            {/* Update Button */}
            <div className="flex justify-end">
              <button
                onClick={handleUpdateCoverImage}
                className="bg-lime-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Mettre à jour l'image de couverture
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverModal;
