import React, { useState, useEffect } from "react";

const CookiesModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem("cookiesAccepted");
    if (!hasAcceptedCookies) {
      setShowModal(true); // Show the modal only if cookies are not accepted
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", "true"); // Store acceptance in local storage
    setShowModal(false); // Close the modal
  };

  if (!showModal) return null; // Don't render the modal if not needed

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-md max-h-[85vh] overflow-y-auto md:max-h-[90vh]">

        <h1 className="text-2xl font-bold mb-4">Politique de Cookies</h1>
        <p>
          <strong>Date d'entrée en vigueur :</strong> 7 décembre 2024
        </p>
        <p>
          Nous utilisons des cookies sur notre site web btobjob.com afin
          d'améliorer votre expérience de navigation, d'analyser le trafic et
          de personnaliser le contenu. Cette politique explique ce que sont les
          cookies, comment nous les utilisons, les types de cookies que nous
          utilisons et vos choix concernant les cookies.
        </p>

        <h2 className="text-xl font-semibold mt-4">Qu'est-ce qu'un cookie ?</h2>
        <p>
          Un cookie est un petit fichier texte qui est stocké sur votre appareil
          (ordinateur, smartphone, etc.) lorsque vous visitez notre site. Les
          cookies nous aident à vous reconnaître lors de vos prochaines visites
          et à améliorer votre expérience utilisateur.
        </p>

        <h2 className="text-xl font-semibold mt-4">
          Pourquoi utilisons-nous des cookies ?
        </h2>
        <ul className="list-disc list-inside">
          <li>
            <strong>Fonctionnalité essentielle :</strong> Certains cookies sont
            nécessaires au bon fonctionnement de notre site, notamment pour
            gérer votre compte et les processus d'abonnement.
          </li>
          <li>
            <strong>Analyse et performance :</strong> Nous utilisons des
            cookies pour recueillir des informations sur la manière dont les
            visiteurs utilisent notre site, ce qui nous aide à améliorer notre
            contenu et nos services.
          </li>
          <li>
            <strong>Personnalisation :</strong> Nous pouvons utiliser des
            cookies pour vous montrer des contenus et des publicités plus
            pertinents en fonction de vos préférences.
          </li>
          <li>
            <strong>Traitement des paiements :</strong> Si vous souscrivez à un
            abonnement via notre site, des cookies peuvent être utilisés pour
            traiter votre paiement de manière sécurisée via notre partenaire
            Stripe et pour gérer votre statut d'abonnement.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mt-4">
          Types de cookies que nous utilisons
        </h2>
        <ul className="list-disc list-inside">
          <li>
            <strong>Cookies essentiels :</strong> Ces cookies sont
            indispensables au fonctionnement de notre site et incluent des
            fonctionnalités liées à votre session d'abonnement.
          </li>
          <li>
            <strong>Cookies de performance :</strong> Ils nous aident à
            comprendre comment les visiteurs interagissent avec notre site en
            fournissant des informations sur les zones visitées, le temps passé
            sur le site et d'autres statistiques.
          </li>
          <li>
            <strong>Cookies fonctionnels :</strong> Ces cookies permettent de
            se souvenir des choix que vous faites (comme votre nom
            d'utilisateur ou votre langue) pour améliorer votre expérience.
          </li>
          <li>
            <strong>Cookies de ciblage :</strong> Ils sont utilisés pour vous
            proposer des publicités qui sont plus pertinentes pour vous et vos
            intérêts.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mt-4">Gestion des cookies</h2>
        <p>
          Vous pouvez décider de recevoir ou non des cookies. La plupart des
          navigateurs acceptent automatiquement les cookies, mais vous pouvez
          généralement modifier vos paramètres de navigateur pour refuser les
          cookies si vous le souhaitez. Cependant, cela peut affecter votre
          capacité à utiliser certaines fonctionnalités de notre site,
          y compris le processus d'abonnement.
        </p>
        <p>Pour gérer les cookies, vous pouvez suivre les instructions de votre navigateur :</p>
        <ul className="list-disc list-inside">
          <li>
            <a
              href="https://support.google.com/chrome/answer/95647"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Chrome : Instructions
            </a>
          </li>
          <li>
            <a
              href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mozilla Firefox : Instructions
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Microsoft Edge : Instructions
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              target="_blank"
              rel="noopener noreferrer"
            >
              Safari : Instructions
            </a>
          </li>
        </ul>

        <h2 className="text-xl font-semibold mt-4">
          Modifications de notre politique de cookies
        </h2>
        <p>
          Nous pouvons mettre à jour notre politique de cookies de temps à
          autre. Nous vous informerons de toute modification en publiant la
          nouvelle politique sur cette page. Nous vous conseillons de consulter
          régulièrement cette politique de cookies pour rester informé sur
          notre utilisation des cookies.
        </p>

        <h2 className="text-xl font-semibold mt-4">Contact</h2>
        <p>
          Si vous avez des questions concernant notre politique de cookies,
          n'hésitez pas à nous contacter à{" "}
          <a href="mailto:support@btobjob.com">support@btobjob.com</a>.
        </p>

        <div className="flex justify-end mt-6">
          <button
            onClick={handleAccept}
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
          >
            Accepter
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiesModal;
