// Offerpage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import removeAccents from 'remove-accents';
import { HelmetProvider } from 'react-helmet-async';
import api from '../../services/api'; // Adjust the import path
import Star from "../../assests/Star.png";
import PrivateRequestForm from './PrivateRequestForm';

const categories = [
  "Agent immobilier", "Architecte/décorateur", "Autre Maçon", "Carrossier/Peintre",
  "Charpentier/Couvreur", "Chauffagiste/frigoriste", "Chauffeur/Livreur", "Commercial",
  "Community manager", "Conciergerie", "Chauffeur VTC", "Carreleur","Cuisiniste", 
  "Décoration", "Déménagement", "Électricien", "Entreprise de ménage", 
  "Entreprise de sécurité", "Facadier", "Formateur/formatrice", "Garde d’animaux",
  "Informaticien/développeur", "Jardinier", "Maintenance/Bricolage", "Maçonnerie",
  "Manutention/débarras", "Mécanicien", "Ménage","Menuisier", "Métier de secrétariat",
  "Nettoyage automobile","Nettoyage de vitres", "Organisateur d’évènement",
  "Paysagiste/jardinier", "Peintre", "Peintre/décorateur", "Plaquiste/plâtrier",
  "Plomberie", "Poseur de piscine", "Réparateur électronique", "Rénovation",
  "Restauration", "Serrurier/dépannage", "Services à la personne", "Terrassement",
  "Traiteur"
];

// Mapping from your front-end labels (French) to your back-end codes (English)
const categoryMap = [
  { value: "real_estate_agent", label: "Agent immobilier" },
  { value: "architect_decorator", label: "Architecte/décorateur" },
  { value: "other_mason", label: "Autre Maçon" },
  { value: "auto_body_painter", label: "Carrossier/Peintre" },
  { value: "carpenter_roofer", label: "Charpentier/Couvreur" },
  { value: "heating_refrigeration", label: "Chauffagiste/frigoriste" },
  { value: "driver_delivery", label: "Chauffeur/Livreur" },
  { value: "vtc_driver", label: "Chauffeur VTC" },
  { value: "sales", label: "Commercial" },
  { value: "community_manager", label: "Community manager" },
  { value: "concierge", label: "Conciergerie" },
  { value: "kitchen_specialist", label: "Cuisiniste" },
  { value: "decoration", label: "Décoration" },
  { value: "moving_services", label: "Déménagement" },
  { value: "electrician", label: "Électricien" },
  { value: "cleaning_company", label: "Entreprise de ménage" },
  { value: "security_company", label: "Entreprise de sécurité" },
  { value: "facade_specialist", label: "Facadier" },
  { value: "trainer", label: "Formateur/formatrice" },
  { value: "pet_sitter", label: "Garde d’animaux" },
  { value: "it_developer", label: "Informaticien/développeur" },
  { value: "gardener", label: "Jardinier" },
  { value: "maintenance_handyman", label: "Maintenance/Bricolage" },
  { value: "masonry", label: "Maçonnerie" },
  { value: "handling_clearance", label: "Manutention/débarras" },
  { value: "mechanic", label: "Mécanicien" },
  { value: "carpenter", label: "Menuisier" },
  { value: "secretarial", label: "Métier de secrétariat" },
  { value: "car_cleaning", label: "Nettoyage automobile" },
  { value: "window_cleaning", label: "Nettoyage de vitres" },
  { value: "event_organizer", label: "Organisateur d’évènement" },
  { value: "landscaper_gardener", label: "Paysagiste/jardinier" },
  { value: "painter", label: "Peintre" },
  { value: "painter_decorator", label: "Peintre/décorateur" },
  { value: "plasterer", label: "Plaquiste/plâtrier" },
  { value: "plumber", label: "Plomberie" },
  { value: "pool_installer", label: "Poseur de piscine" },
  { value: "electronics_repair", label: "Réparateur électronique" },
  { value: "restaurant", label: "Restauration" },
  { value: "locksmith", label: "Serrurier/dépannage" },
  { value: "personal_services", label: "Services à la personne" },
  { value: "earthwork", label: "Terrassement" },
  { value: "cleaning", label: "Ménage" },
  { value: "renovation", label: "Rénovation" },
  { value: "tiler", label: "Carreleur" },
  { value: "catering", label: "Traiteur" },
];

const Offerpage = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [offers, setOffers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        // If 'all', we send {}. Otherwise, convert the French label to an English code
        const params =
          selectedCategory === 'all'
            ? {}
            : { category: getEnglishValue(selectedCategory) };

        const data = await api.fetchOffers(params);
        setOffers(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Error fetching offers:', error);
      }
    };

    fetchOffers();
  }, [selectedCategory]);

  // Remove accents and lowercase, for easy comparison
  const normalizeCategory = (cat) => removeAccents(cat.trim().toLowerCase());

  // Convert from French label to English code
  const getEnglishValue = (label) => {
    const match = categoryMap.find(
      (c) => normalizeCategory(c.label) === normalizeCategory(label)
    );
    return match ? match.value : null;
  };

  // Group fetched offers by their French category
  const getFilteredOffers = () => {
    const offersPerCategory = {};
    offers.forEach((offer) => {
      // 'offer.category' is now the French label from the PHP mapping
      const catKey = categories.find(
        (cat) => normalizeCategory(cat) === normalizeCategory(offer.category)
      );
      if (catKey) {
        if (!offersPerCategory[catKey]) {
          offersPerCategory[catKey] = [];
        }
        offersPerCategory[catKey].push(offer);
      } else {
        console.warn("No matching category label for:", offer.category);
      }
    });
    return offersPerCategory;
  };

  const filteredOffers = getFilteredOffers();

  return (
    <>
      <HelmetProvider>
        <title>Engager un Professionnel | BtoBJob</title>
        <meta
          name="description"
          content="Trouvez et engagez des professionnels qualifiés dans divers domaines. Parcourez les offres et sélectionnez celle qui correspond à vos besoins."
        />
      </HelmetProvider>

      <div className="">
        <div className="md:mx-auto gap-2 m-2 max-w-6xl md:mt-12 rounded-lg md:flex-row">
          <div className="flex flex-col bg-white rounded-xl">
            <div className="sm:p-6 p-3 border-b-2">
              <p className="font-bold text-2xl">ENGAGER UN PRO</p>
            </div>

            {/* Category Dropdown */}
            <div className="mx-auto sm:p-6 p-4 flex flex-col md:flex-row items-center justify-between gap-2 max-w-full">
              <div className="relative w-full lg:w-2/3 flex flex-col gap-2">
                <label className="block font-medium text-lg">Recherche</label>
                <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="block w-full border border-gray-300 p-3 rounded-md focus:ring focus:ring-blue-500"
                >
                  <option value="all">Tous</option>
                  {categories.map((cat, index) => (
                    <option key={index} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Group offers by category */}
            {Object.entries(filteredOffers).map(([category, categoryOffers], idx) => (
              <div key={idx} className="flex flex-col gap-2 sm:p-6 p-3 border-t-2 max-w-full">
                <div className="flex justify-between items-center mt-6 mb-3">
                  <div>
                    <h2 className="text-xl font-medium">Service de {category}</h2>
                    {categoryOffers?.length > 0 && (
                      <p className="text-sm text-gray-500">
                        Créé le {new Date(categoryOffers[0].created_at).toLocaleDateString()}
                      </p>
                    )}
                  </div>
                  <button
                    onClick={() => {
                      const englishValue = getEnglishValue(category);
                      if (englishValue) {
                        navigate(`/profile/allthemes?category=${englishValue}`);
                      } else {
                        console.error('Category mapping failed for:', category);
                      }
                    }}
                    className="bg-gray-100 hover:bg-gray-200 text-gray-800 font-medium text-sm sm:text-base rounded-xl inline-flex items-center"
                  >
                    <span>Voir tout</span>
                  </button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  {categoryOffers.slice(0, 2).map((offer, i) => (
                    <ProfileCard key={i} offer={offer} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

// The card that displays each offer
const ProfileCard = ({ offer }) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  // The main offer image
  const imageUrl = `https://btobjob.com/${offer.image}`;

  const handleNavigateToProfile = () => {
    navigate(`/profile/specificUser?professional_id=${offer.professional_id}`);
  };

  return (
    <div className="bg-white rounded-xl shadow-2xl p-3 sm:p-4 max-w-full">
      <div className="flex items-center">
        <div className="flex justify-between items-start w-full">
          <div className="flex items-center">
            <img
              src={offer.professional_Picture}
              alt="Professional"
              className="w-12 h-12 rounded-full mr-4"
            />
            <div>
              <h2 className="text-lg font-bold text-gray-800">{offer.professional_name}</h2>
              <span className="text-sm text-gray-500">{offer.professional_city}</span>
              <br />
              <span className="text-sm text-gray-500">{offer.created_at}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Star Rating */}
      <div className="flex justify-between items-center">
        <div className="flex items-center mt-2">
          <img src={Star} className="h-3 sm:h-4 mt-1" alt="Star" />
          <p className="text-xs ml-2">
            {offer.average_rating ? parseFloat(offer.average_rating).toFixed(1) : 'N/A'}
          </p>
        </div>
      </div>

      {/* Description */}
      <p className="text-sm mt-2 text-gray-700 h-14 overflow-hidden">
        {offer.description}
      </p>

      {/* Main image (click to enlarge) */}
      <div className="flex justify-center items-center h-96 mb-4">
        <img
          src={imageUrl}
          alt="Offer"
          className="w-96 h-96 object-contain rounded-lg my-10 cursor-pointer"
          onClick={() => setShowPopup(true)}
        />
      </div>

      {/* Action buttons */}
      <div className="flex justify-between">
        <button
          onClick={handleNavigateToProfile}
          className="bg-[#65A406] text-white font-medium text-sm sm:text-base py-2 px-4 rounded-xl inline-flex items-center"
        >
          Voir le profil
        </button>

        {/* Show "Demande privée" ONLY if this offer is NOT by the current user */}
        {!offer.is_my_post && (
          <button
            onClick={() => setOpenForm(true)}
            className="bg-gray-100 hover:bg-gray-200 text-gray-800 font-medium text-sm sm:text-base py-2 px-4 rounded-xl inline-flex items-center"
          >
            Demande privée
          </button>
        )}
      </div>

      {/* Private Request Modal/Form */}
      {openForm && (
        <PrivateRequestForm
          boolean={true}
          professionalId={offer.professional_id}
          onClose={() => setOpenForm(false)}
        />
      )}

      {/* Popup for the main image */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-[999999999999] bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg">
            <img src={imageUrl} alt="Offer Full" className="w-full h-auto max-w-md" />
            <button
              onClick={() => setShowPopup(false)}
              className="mt-2 px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-md"
            >
              Fermer
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Offerpage;
