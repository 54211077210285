import React, { useState, useRef, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { CiUser, CiLocationOn } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import { BiHide, BiShow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import api from "../../services/api";

const ProfileEdit = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [currentPassword, setCurrentPassword] = useState(""); 
  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  const profileCropperRef = useRef(null);
  const coverCropperRef = useRef(null);

  useEffect(() => {
    api
      .getUser()
      .then((data) => {
        if (data.user) {
          setFullName(data.user.Name || "");
          setUsername(data.user.username || "");
          setPhoneNumber(data.user.Phone || "");
          setAddress(data.user.Address || "");
          setCity(data.user.city || "");
          setState(data.user.state || "");
          setZip(data.user.zip || "");
          setPassword("");
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  const handleUpdateInfo = async () => {
    const formData = {
      fullName,
      username,
      phoneNumber,
      address,
      city,
      state,
      zip,
      currentPassword, // Include current password in the request
      password,
    };

    try {
      const response = await api.updateProfile(formData);
      if (response.status === "success") {
        alert("Les informations de profil ont été mises à jour avec succès");
        navigate(-1);
      } else {
        alert(`La mise à jour a échoué: ${response.message || "Erreur inconnue"}`);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du profil:", error);
      alert("Une erreur s'est produite lors de la mise à jour des informations de profil");
    }
  };

  const handleUpdateImages = async () => {
    const formData = new FormData();
  
    // Check if the profile image cropper exists and add the cropped image
    if (profileCropperRef.current?.cropper) {
      const profileBlob = await new Promise((resolve) => {
        profileCropperRef.current.cropper.getCroppedCanvas().toBlob(resolve, "image/jpeg");
      });
      if (profileBlob) {
        formData.append("profileImage", profileBlob, "profile.jpg");
      }
    }
  
    // Check if the cover image cropper exists and add the cropped image
    if (coverCropperRef.current?.cropper) {
      const coverBlob = await new Promise((resolve) => {
        coverCropperRef.current.cropper.getCroppedCanvas().toBlob(resolve, "image/jpeg");
      });
      if (coverBlob) {
        formData.append("coverImage", coverBlob, "cover.jpg");
      }
    }
  
    // If neither image is selected, alert the user
    if (!formData.has("profileImage") && !formData.has("coverImage")) {
      alert("Veuillez sélectionner au moins une image à mettre à jour.");
      return;
    }
  
    try {
      const response = await api.updateImages(formData);
      if (response.success) {
        alert("Images mises à jour avec succès.");
        // Navigate to a specific page or reload
        window.location.href = "https://btobjob.com/profile/viewprofile";
      } else {
        alert(`La mise à jour a échoué : ${response.message || "Erreur inconnue."}`);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour des images :", error);
      alert("Une erreur s'est produite lors de la mise à jour des images.");
    }
  };
  
  const handleImageChange = (setImage) => (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="mx-auto md:max-w-3xl lg:max-w-4xl bg-white rounded-lg md:mt-16 md:px-0 px-2">
      <div className="flex flex-col">
        <div className="p-6 border-b-2 flex flex-col">
          <div onClick={() => navigate(-1)} className="flex items-center cursor-pointer">
            <FaArrowLeft className="h-6 w-6 pr-2" />
            <p className="text-xl font-semibold">Informations personnelles</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-5 px-8 mb-5">
          <InputField value={fullName} onChange={setFullName} placeholder="Nom et prénom" Icon={CiUser} />
          <InputField value={username} onChange={setUsername} placeholder="Nom d'utilisateur" Icon={CiUser} />
         <InputField
  value={phoneNumber}
  onChange={(value) => {
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
    }
  }}
  placeholder="Numéro de téléphone"
  Icon={BsTelephone}
  type="tel"
/>

          <PasswordInput
  label="Mot de passe actuel"
  value={currentPassword}
  onChange={setCurrentPassword}
  showPassword={showCurrentPassword}
  setShowPassword={setShowCurrentPassword}
/>
            <InputField value={address} onChange={setAddress} placeholder="Adresse" Icon={CiLocationOn} />

<PasswordInput
  label="Nouveau mot de passe"
  value={password}
  onChange={setPassword}
  showPassword={showPassword}
  setShowPassword={setShowPassword}
/>
        
          <InputField value={city} onChange={setCity} placeholder="Ville" Icon={CiLocationOn} />
          <InputField value={state} onChange={setState} placeholder="Pays" Icon={CiLocationOn} />
          <InputField value={zip} onChange={setZip} placeholder="Code postal" Icon={CiLocationOn} />
        </div>
        <div className="flex justify-end px-8 mb-6">
      <button
        onClick={handleUpdateInfo}
        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
      >
        Mettre à jour les informations
      </button>
    </div>
        <div className="px-8">
          <div className="flex flex-col items-center mb-6">
            <label className="text-lg font-medium mb-2">Image de profil :</label>
            <input type="file" accept="image/*" onChange={handleImageChange(setProfileImage)} />
            {profileImage && (
              <Cropper
                src={profileImage}
                style={{ height: 200, width: "100%" }}
                aspectRatio={1}
                guides={false}
                ref={profileCropperRef}
              />
            )}
          </div>
          <div className="flex flex-col items-center mb-6">
            <label className="text-lg font-medium mb-2">Image de couverture :</label>
            <input type="file" accept="image/*" onChange={handleImageChange(setCoverImage)} />
            {coverImage && (
              <Cropper
                src={coverImage}
                style={{ height: 200, width: "100%" }}
                aspectRatio={3 / 1}
                guides={false}
                ref={coverCropperRef}
              />
            )}
          </div>

          <div className="flex justify-end">
            <button
              onClick={handleUpdateImages}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Mettre à jour les images
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const InputField = ({ value, onChange, placeholder, Icon, type = "text" }) => (
  <div className="relative mb-3">
    <input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      type={type}
      className="peer block w-full rounded border bg-transparent px-3 py-2 outline-none"
      placeholder={placeholder}
    />
    <Icon className="absolute right-2 top-2 text-gray-500" size={24} />
  </div>
);

const PasswordInput = ({ label, value, onChange, showPassword, setShowPassword }) => (
  <div className="relative mb-3">
    
    <input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      type={showPassword ? "text" : "password"}
      className="peer block w-full rounded border bg-transparent px-3 py-2 outline-none"
      placeholder={label} // This uses the label as a placeholder
    />
    <div className="absolute right-2 top-2 flex items-center">
      {showPassword ? (
        <BiShow className="text-gray-500 cursor-pointer" size={24} onClick={() => setShowPassword(false)} />
      ) : (
        <BiHide className="text-gray-500 cursor-pointer" size={24} onClick={() => setShowPassword(true)} />
      )}
    </div>
  </div>
);

export default ProfileEdit;
