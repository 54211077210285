import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';
import photo from "../assests/hero-image.png";
import vd from "../assests/hero_video.mp4";
import LOGO from "../assests/b2bUpdated.png";

const CenteredLogoWithParagraphs = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await api.getSession();
        if (response.user) {
          setUser(response.user);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error fetching session:', error);
        setUser(null);
      }
    };

    fetchSession();
  }, []);

  // Use a media query to check for screen size
  const isDesktop = window.matchMedia("(min-width: 768px)").matches;

  return (
    <div className="relative w-full  flex flex-col justify-start items-center bg-[#fff]">
      <div className='flex flex-row justify-between items-center w-[80%]'>
        {user ? (
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-700">Bonjour, {user.name}!</span>
            {/* Optional: Add a logout button or additional user links here */}
          </div>
        ) : (
          <>
            <button className="hidden md:flex professional-navbar">
              <Link className="professional-navbar-link text-sm translatable-text" to="/login">Se Connecter Particulier</Link>
            </button>
            <button className="hidden md:flex professional-navbar">
              <Link className="professional-navbar-link text-sm translatable-text" to="/login-pro">Se Connecter Professionnel</Link>
            </button>
          </>
        )}
      </div>
{/* 
      <div className='media-container flex items-center justify-center'> */}
        {/* Conditionally render the video only for desktop */}
        {/* {isDesktop ? ( */}
          {/* <video className="w-[50%] mb-20" autoPlay loop muted>
            <source src={vd} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
        {/* ) : ( */}
          {/* <div className="w-[100%]  flex hidden justify-center ">
            <img src={LOGO} alt="Company Logo" />
          </div> */}
        {/* )} */}
      {/* </div> */}
    </div>
  );
};

export default CenteredLogoWithParagraphs;
