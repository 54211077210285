import React, { useState, useRef, useEffect , useLayoutEffect  } from 'react';
import UserProfile from "../../assests/profileuser.png";
import PostingPicture from '../../assests/postingpicture.png';
import { TiWorld } from "react-icons/ti";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import btnArrow from '../../assests/inputbtnarrow.png';
import Recommend from './Recommend';
import PublicRequest from './PublicRequest';
import AddressCard from './AddressCard';
import PublicRequestCategories from './PublicRequestCategories';
import { useNavigate } from "react-router-dom";
import { FaRegCommentDots, FaRegHeart, FaShareAlt,FaHeart } from "react-icons/fa"; 
import PublicRequestCompleted from './PublicRequestCompleted';
import api from '../../services/api'; // Import API
import { CiTwitter } from "react-icons/ci";
import { PiTelegramLogoLight, PiWhatsappLogoLight } from "react-icons/pi";
import { MdAlternateEmail } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import PostShare from './SharePost';
import ReactPlayer from 'react-player';
const CommentsModal = ({ postId, isOpen, onClose, comments, totalComments, fetchComments, handleAddComment, newComment, setNewComment }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-11/12 max-w-md relative">
                <h2 className="text-xl font-bold mb-4">Comments ({totalComments})</h2> {/* Display total comments here */}
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-black"
                    onClick={onClose}
                >
                    ✕
                </button>
                <div className="space-y-2 mb-4 max-h-64 overflow-y-auto">
                    {Array.isArray(comments[postId]) && comments[postId].map(comment => (
                        <div key={comment.id} className="bg-gray-100 p-2 rounded-md">
                            <p className="text-sm font-semibold">{comment.user_name}</p>
                            <p className="text-sm text-gray-700">{comment.comment_text}</p>
                            <p className="text-xs text-gray-500">{comment.created_at}</p>
                        </div>
                    ))}
                </div>
                <input
                    type="text"
                    placeholder="Add a comment..."
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    className="p-2 border rounded w-full mb-2"
                />
                <button
                    onClick={() => handleAddComment(postId)}
                    className="bg-lime-500 text-white px-4 py-1 rounded w-full"
                >
                    Add Comment
                </button>
            </div>
        </div>
    );
};

const Request = () => {
    const [posts, setPosts] = useState([]); // State for posts
    const [likesStatus, setLikesStatus] = useState({}); // State for like statuses
    const [openForm1, setOpenForm1] = useState(false); // State to control form visibility
    const [openForm2, setOpenForm2] = useState(false); // State to control form visibility
    const [isLightboxOpen, setIsLightboxOpen] = useState(false); // State for Lightbox
    const [lightboxImage, setLightboxImage] = useState(''); // Current image in Lightbox
    const [comments, setComments] = useState({}); // State to manage comments for each post
    const [newComment, setNewComment] = useState(''); // State to manage new comment input
    const [selectedPostId, setSelectedPostId] = useState(null); // State to manage selected post for modal
    const [searchTerm, setSearchTerm] = useState(''); // State for search input
    const [isDropdownOpen, setIsDropdownOpen] = useState({}); 
    const [likeCounts, setLikeCounts] = useState({}); // State to manage total likes count
const [commentCounts, setCommentCounts] = useState({}); // State to manage total comments count
const dropdownRefs = useRef({}); 
    const fileInputRef = useRef(null); // Reference to the file input
    const [isShareOpen, setIsShareOpen] = useState(false); 
    const [sharePostData, setSharePostData] = useState(null); 
    const [openDropdown, setOpenDropdown] = useState(null);
    const [displayLimit, setDisplayLimit] = useState(5); 
    const [scrollPosition, setScrollPosition] = useState(0); // State for scroll position
    const feedRef = useRef(null);
    const navigate = useNavigate(); // For navigation
    const toggleDropdown = (postId) => {
        if (openDropdown === postId) {
            // If the clicked dropdown is already open, close it
            setOpenDropdown(null);
        } else {
            // Otherwise, open the clicked dropdown and close others
            setOpenDropdown(postId);
        }
    };
// State for managing dropdown visibility
    const handleButtonClick = () => {
        fileInputRef.current.click(); // Handle button click to open file input
    };

    const handleFileChange = (event) => {
        const files = event.target.files; // Handle file input change
        if (files.length > 0) {
            console.log('Selected file:', files[0]);
        }
    };
 
    const checkLikeStatus = async (postId) => {
        try {
            const response = await axios.post('https://btobjob.com/check-like.php', { postId }, { withCredentials: true });
            return {
                liked: response.data.liked,
                totalLikes: response.data.totalLikes
            };
        } catch (error) {
            console.error('Error checking like status:', error);
            return { liked: false, totalLikes: 0 };
        }
    };

     useEffect(() => {
        const fetchPosts = async () => {
            try {
                const postsData = await api.fetchAllPosts();
                setPosts(postsData);

                const likeStatusPromises = postsData.map((post) => checkLikeStatus(post.id));
                const likeStatusResults = await Promise.all(likeStatusPromises);

                const newLikeStatus = {};
                const newLikeCounts = {};
                const newCommentCounts = {};

                postsData.forEach((post, index) => {
                    newLikeStatus[post.id] = likeStatusResults[index].liked;
                    newLikeCounts[post.id] = likeStatusResults[index].totalLikes;
                });

                const commentPromises = postsData.map((post) => fetchComments(post.id));
                const commentsResults = await Promise.all(commentPromises);

                postsData.forEach((post, index) => {
                    newCommentCounts[post.id] = commentsResults[index]?.totalComments || 0;
                });

                setLikesStatus(newLikeStatus);
                setLikeCounts(newLikeCounts);
                setCommentCounts(newCommentCounts);
            } catch (error) {
                console.error("Error fetching posts:", error);
            }
        };

        fetchPosts();
    }, []);


    const handleLike = async (postId) => {
        try {
            const response = await axios.post(
                "https://btobjob.com/like-post.php",
                { postId },
                { withCredentials: true }
            );

            setLikesStatus((prev) => ({ ...prev, [postId]: response.data.liked }));
            setLikeCounts((prev) => ({
                ...prev,
                [postId]: response.data.liked
                    ? (prev[postId] || 0) + 1
                    : (prev[postId] || 0) - 1,
            }));
        } catch (error) {
            console.error("Error liking the post:", error);
        }
    };

    
    const SharePost = ({ shareUrl, onClose }) => {
        if (!shareUrl) return null; // Guard clause for missing URL
    }
    const handleAddComment = async (postId) => {
        if (!newComment.trim()) return;
        try {
            const response = await axios.post('https://btobjob.com/add-post-comment.php', {
                postId,
                commentText: newComment
            }, { withCredentials: true });
    
            if (response.data.success) {
                setNewComment('');
                fetchComments(postId); // Refresh comments and count
            } else {
                console.error('Error adding comment:', response.data.error);
            }
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    const fetchComments = async (postId) => {
        try {
            const response = await axios.post('https://btobjob.com/fetch-post-comments.php', { postId }, { withCredentials: true });
            if (response.status === 200) {
                setComments(prevComments => ({
                    ...prevComments,
                    [postId]: response.data.comments
                }));
                setCommentCounts(prevCounts => ({
                    ...prevCounts,
                    [postId]: response.data.totalComments
                }));
                return response.data; // Return response data to use `totalComments`
            } else {
                console.error('Error fetching comments:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

    const openLightbox = (imageUrl) => {
        setLightboxImage(imageUrl);
        setIsLightboxOpen(true);
    };

    const closeLightbox = () => {
        setIsLightboxOpen(false);
        setLightboxImage('');
    };
    const handleReport = (postId) => {
        window.location.href = `https://btobjob.com/report-post.php?id=${postId}`;
    };
// Open modal with the selected post's comments and count
const openCommentsModal = (postId) => {
    setSelectedPostId(postId);
    fetchComments(postId); // Fetch comments for the selected post
};

    const closeCommentsModal = () => {
        setSelectedPostId(null);
    };

    // Filter posts based on the search term
    const filteredPosts = posts.filter(post =>
        post.text.toLowerCase().includes(searchTerm.toLowerCase()) || 
        post.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


 
    
 
const handleLoadMore = async () => {
    try {
        const currentPage = Math.ceil(posts.length / displayLimit);
        const nextPage = currentPage + 1; // This should always increment

        const newPosts = await api.fetchAllPosts('', nextPage, displayLimit); // Make sure this method uses nextPage properly
        if (newPosts.length > 0) {
            setPosts(prevPosts => [...prevPosts, ...newPosts]); // Append new posts only if they are not empty
        } else {
            console.log("No more posts to load.");
        }
    } catch (error) {
        console.error("Error loading more posts:", error);
    }
};

    
    
    const navigateToPostDetails = (postId) => {
   
        navigate(`/profile/posts/${postId}`);
    };
        
    const handlePublicRequestClick = () => {
        window.location.href = 'https://btobjob.com/boost.php'; // Redirect to boost.php
    };
    const toggleShareModal = (post) => {
        setSharePostData(post); // Set the post data to be shared
        setIsShareOpen(true);   // Open the share modal
    };
   
    

    // Close Share Modal
    const closeShareModal = () => {
        setIsShareOpen(false);
        setSharePostData(null); // Clear share data
    };  

        useEffect(() => {
            const handleClickOutside = (event) => {
                Object.keys(dropdownRefs.current).forEach((postId) => {
                    if (
                        dropdownRefs.current[postId] && // Check if the dropdown reference exists
                        !dropdownRefs.current[postId].contains(event.target) // Check if click is outside
                    ) {
                        setIsDropdownOpen((prev) => ({
                            ...prev,
                            [postId]: false, // Close the dropdown
                        }));
                    }
                });
            };
        
            document.addEventListener("mousedown", handleClickOutside); // Attach the event listener
        
            return () => {
                document.removeEventListener("mousedown", handleClickOutside); // Clean up on component unmount
            };
        }, []);

    return (
        <>
            <HelmetProvider>
                <title>Fil d'actualité | BtoBJob</title>
                <meta
                    name="description"
                    content="Explorez les dernières publications, commentez et partagez le contenu de votre réseau professionnel."
                />
                <meta property="og:title" content="Fil d'actualité | BtoBJob" />
                <meta
                    property="og:description"
                    content="Découvrez les dernières mises à jour, connectez-vous avec des professionnels et partagez vos réflexions sur BtoBJob."
                />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://btobjob.com/logo.png" />
                <meta name="twitter:title" content="Fil d'actualité | BtoBJob" />
                <meta
                    name="twitter:description"
                    content="Explorez les dernières publications, commentez et partagez le contenu de votre réseau professionnel."
                />
                <meta name="twitter:card" content="summary_large_image" />
            </HelmetProvider>
        <div ref={feedRef} className="feed-container max-w-6xl mx-auto md:mt-10 px-2 py-2 overflow-y-auto">

            <div className='flex gap-3 md:flex-row flex-col-reverse'>
                <div className='flex flex-col w-full md:w-2/3'>
                    <div className='rounded-xl bg-white'>
                        <div className='sm:p-6 p-3 border-b-2'>
                            <h4 className='text-xl font-bold'>Fil d'actualité</h4>
                        </div>
                    </div>
                    <div className='rounded-xl bg-white'>
                        <div className='sm:p-6 p-3 border-b-2'>
                            {/* Search Bar */}
                            <input
                                type="text"
                                placeholder="Rechercher des articles..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="  w-full p-2 border rounded-md"
                            />
                        </div>
                    </div>
                    <div className="mt-4">
                <div   className="flex flex-col gap-1"> {filteredPosts.map((post) => (
      <div
        key={post.id}
        className="bg-white p-8 shadow-md rounded-lg w-full cursor-pointer relative"
        
        onClick={() => navigateToPostDetails(post.id)}
      >
        {/* Post Header */}
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <img src={post.profile_picture} alt="User Avatar" className="w-8 h-8 rounded-full" />
            <div>
              <p className="text-gray-800 font-semibold">{post.name}</p>
              <p className="text-gray-500 text-sm">{post.created_at}</p>
            </div>
          </div>
          {/* Three-dot menu */}
          <div className="relative">
          <button
        onClick={(e) => {
            e.stopPropagation();
            toggleDropdown(post.id);
        }}
        className="text-gray-500 hover:text-black text-4xl"
    >
        &#x2026; {/* Unicode for three dots */}
    </button>
    {openDropdown === post.id && ( // Check if this dropdown should be open
        <div
            className="absolute right-0 mt-2 w-36 bg-white border border-gray-200 rounded-md shadow-lg z-10"
        >
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    handleReport(post.id); // Handle the report action
                }}
                className="text-red-500 w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
            >
                Signaler
            </button>
    </div>
)}
          </div>
        </div>

        {/* Post Content */}
        <div className="mb-4">
          <p className="text-gray-800">{post.text}</p>
        </div>

        {/* Media Content */}
        {post.media.length > 0 && (
          <div className={`mb-4 ${post.media.length > 1 ? 'grid grid-cols-2 gap-2' : ''}`}>
            {post.media.map((file, index) => (
  <div key={index} className="relative">
    {file.type.startsWith('image/') ? (
      <img
        src={file.url}
        alt={`Post Media ${index + 1}`}
        className="w-full h-full object-contain rounded-md mb-2 cursor-pointer"
        onClick={() => openLightbox(file.url)}
      />
    ) : file.type.startsWith('video/') ? (
    <ReactPlayer
                           url={file.url} // Ensure this is a valid and accessible video/audio URL
                           controls
                           width="100%"
                           light={file.thumbnail_url} 
                           height="100vh"
                           className="rounded-md mb-2"
                         />
    ) : null}
  </div>
))}
          </div>
        )}

                            {/* Like and Comment Counts */}
                            <div className="flex items-center justify-between gap-4 text-gray-500">
                                        {/* Like Button on the left */}
         {/* Like Button */}
<button
    className={`flex items-center gap-2 ${likesStatus[post.id] ? 'text-red-600' : 'text-gray-500'}`}
    onClick={(e) => {
        e.stopPropagation();
        handleLike(post.id);
    }}
>
    {likeCounts[post.id] || 0} {/* Display the number of likes */}
    {likesStatus[post.id] ? (
        <FaHeart className="w-5 h-5" /> // Filled heart
    ) : (
        <FaRegHeart className="w-5 h-5" /> // Outlined heart
    )}
      <span className="text-xs sm:text-base">{likesStatus[post.id] ? " J'aime" : " J'aime"}</span>
</button>

{/* Comments Button */}
<button
    onClick={() => navigateToPostDetails(post.id)}
    className="text-blue-500 flex items-center gap-2"
>
    {commentCounts[post.id] || 0} {/* Display the number of comments */}
    <FaRegCommentDots className="w-5 h-5" />
    <span className="text-xs sm:text-base">Commentaires</span>
</button>


                                        {/* Share Button on the right */}
                                        <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleShareModal(post);
                                    }}
                                    className="text-gray-500 hover:text-blue-500 flex items-center gap-2"
                                >
                                    <FaShareAlt className="w-5 h-5" />
                                    <span className="text-xs sm:text-base">Partager</span>
                                </button>
                                    </div>
                                </div>
                    ))}
                    {displayLimit  && (
                    <button
                        onClick={handleLoadMore}
                        className="mt-4 px-4 py-2 bg-lime-500 text-white rounded-md w-full"
                    >
                        Charger plus
                    </button>
                )}
                </div>
            </div>
                </div>
                <div className="hidden md:block md:w-1/3 w-full flex flex-col gap-2">
    {/* Wrapper for the Button */}
    <div className="sm:p-8 p-3 shadow-md rounded-xl bg-white fixed top-32 z-50">
        <div className="flex items-center justify-between mb-1 sm:mb-4">
            <div className="flex items-center mr-5">
                <TiWorld className="w-6 h-6" />
                <h2 className="text-2xl font-bold sm:font-bold">Demande</h2>
            </div>
        </div>
        {/* Fixed Button */}
        <div>
            <button 
                onClick={handlePublicRequestClick} // Redirects to boost.php
                className="w-full rounded-xl bg-gradient-to-r text-white from-lime-400 to-lime-600 px-4 py-2 flex items-center justify-center shadow-lg"
            >
                Postez votre demande
                <img className="ml-2" src={btnArrow} width={17} alt="Arrow" />
            </button>
        </div>
    </div>


</div>



            </div>
            {isShareOpen && (
    <PostShare
        isOpen={isShareOpen}
        onClose={closeShareModal}
        shareUrl={sharePostData ? `https://btobjob.com/profile/posts/${sharePostData.id}` : ''}
    />
)}
            <Recommend toggleboolean={openForm1} />
            <PublicRequest toggleboolean={openForm2} updateFormState={setOpenForm2} />

            {/* Lightbox for displaying images */}
            {isLightboxOpen && (
                <div className="  fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <div className="  relative">
                        <img src={lightboxImage} alt="Lightbox" className="  max-w-full max-h-screen rounded-lg" />
                        <button
                            className="  absolute top-2 right-2 text-white bg-gray-800 rounded-full p-1"
                            onClick={closeLightbox}
                        >
                            ✕
                        </button>
                    </div>
                </div>
            )}

            {/* Comments Modal for displaying comments */}
            <CommentsModal
    postId={selectedPostId}
    isOpen={selectedPostId !== null}
    onClose={closeCommentsModal}
    comments={comments}
    totalComments={commentCounts[selectedPostId] || 0} // Pass the total comments count
    fetchComments={fetchComments}
    handleAddComment={handleAddComment}
    newComment={newComment}
    setNewComment={setNewComment}
/>
        </div>
        </>
    );
};

export default Request;