import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import api from "../../services/api";

const ViewAllRequests = () => {
  const [requests, setRequests] = useState([]);
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalRequests, setTotalRequests] = useState(0);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // For the modal

  const navigate = useNavigate();
  const limit = 10;

  useEffect(() => {
    const fetchRequestsAndAds = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.getRequests(page, limit);
        setRequests(response.requests || []);
        setTotalRequests(response.totalRequests || 0);
        setAds(response.ads || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchRequestsAndAds();
  }, [page]);

  const handleRequestClick = (professionalId) => {
    navigate(`/profile/specificUser?professional_id=${professionalId}`);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl); // Open modal with clicked image
  };

  const closeImageModal = () => {
    setSelectedImage(null); // Close modal
  };

  const handleNextPage = () => {
    if (page * limit < totalRequests) setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <div className="w-full max-w-2xl mx-auto p-4 sm:p-6 md:p-8 bg-white shadow-lg rounded-lg mt-10">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
      >
        <FaArrowLeft className="inline mr-2" /> Retour
      </button>

      {/* Title: Public Requests */}
      <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center text-gray-700">
        Mes demandes publiques
      </h2>

      {/* Loading or Error */}
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : error ? (
        <p className="text-center text-red-500">{error}</p>
      ) : (
        <>
          {/* Requests Section */}
          <div className="space-y-6">
            {requests.length === 0 ? (
              <p className="text-center">No requests found.</p>
            ) : (
              requests.map((request) => (
                <div
                  key={request.id}
                  className="p-4 border border-gray-300 rounded-lg"
                >
                  {/* Request Title / Description */}
                  <h3 className="text-base sm:text-lg mb-2">
                    <b>Description :</b> {request.request}
                  </h3>

                  {/* Info Section */}
                  <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-4 mb-4">
                    <div>
                      <span className="font-semibold">Category:</span>
                      <div>{request.category}</div>
                    </div>
                    <div>
                      <span className="font-semibold">Price:</span>
                      <div>{request.price} €</div>
                    </div>
                    <div>
                      <span className="font-semibold">Address:</span>
                      <div>{request.address}</div>
                    </div>
                    <div>
                      <span className="font-semibold">Date:</span>
                      <div>{request.time}</div>
                    </div>
                  </div>

                  {/* Images */}
                  <div className="flex flex-wrap gap-4">
                    {request.file1 && (
                      <img
                        src={`https://btobjob.com/${request.file1}`}
                        alt="File 1"
                        className="w-20 h-20 object-cover rounded-lg cursor-pointer"
                        onClick={() =>
                          handleImageClick(`https://btobjob.com/${request.file1}`)
                        }
                      />
                    )}
                    {request.file2 && (
                      <img
                        src={`https://btobjob.com/${request.file2}`}
                        alt="File 2"
                        className="w-20 h-20 object-cover rounded-lg cursor-pointer"
                        onClick={() =>
                          handleImageClick(`https://btobjob.com/${request.file2}`)
                        }
                      />
                    )}
                    {request.file3 && (
                      <img
                        src={`https://btobjob.com/${request.file3}`}
                        alt="File 3"
                        className="w-20 h-20 object-cover rounded-lg cursor-pointer"
                        onClick={() =>
                          handleImageClick(`https://btobjob.com/${request.file3}`)
                        }
                      />
                    )}
                  </div>
                </div>
              ))
            )}
          </div>

          {/* Ads Section */}
          <h2 className="text-2xl sm:text-3xl font-bold mt-10 mb-6 text-center text-gray-700">
            Mes annonces
          </h2>
          <div className="space-y-6">
            {ads.length === 0 ? (
              <p className="text-center">No ads found.</p>
            ) : (
              ads.map((ad) => (
                <div key={ad.id} className="p-4 border border-gray-300 rounded-lg">
                  <h3 className="text-base sm:text-lg font-bold mb-2">
                    {ad.title}
                  </h3>
                  <p className="mb-4">{ad.description}</p>
                  <div className="flex flex-col sm:flex-row justify-between gap-2 sm:gap-4">
                    <span>
                      <b>Profession:</b> {ad.profession}
                    </span>
                    <span>
                      <b>Location:</b> {ad.location}
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        </>
      )}

      {/* Pagination */}
      <div className="flex justify-between mt-6">
        <button
          onClick={handlePrevPage}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-300 disabled:opacity-50"
          disabled={page === 1}
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-300 disabled:opacity-50"
          disabled={page * limit >= totalRequests}
        >
          Next
        </button>
      </div>

      {/* Image Modal */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[99999999]">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-full max-h-full">
            <img
              src={selectedImage}
              alt="Full View"
              className="w-auto h-auto max-w-[88vw] max-h-[88vh] object-contain"
            />
            <button
              onClick={closeImageModal}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
            >
              Fermer
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewAllRequests;