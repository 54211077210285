import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';

const AddForum = () => {
  const [heading, setHeading] = useState('');
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([]);
  const [responseMessage, setResponseMessage] = useState('');
  const navigate = useNavigate();

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('heading', heading);
    formData.append('description', description);
    images.forEach((image) => {
      formData.append('images[]', image);
    });

    try {
      const response = await api.createForumTopic(formData);
      console.log('Response:', response); // Log the response for debugging
      if (response && response.data) {
        setResponseMessage(response.data.message);
        window.location.href = 'https://btobjob.com/profile/forum';
 // Redirect to the home forum page
      } else {
        navigate('/profile/forum');
      }
      setHeading('');
      setDescription('');
      setImages([]);
    } catch (error) {
      console.error('Error:', error); // Log the error for debugging
      if (error.response && error.response.data) {
        setResponseMessage('Error creating forum topic: ' + error.response.data.message);
      } else {
        setResponseMessage('Error creating forum topic');
      }
    }
  };

  return (
    <div className="  container mx-auto p-4">
       <h1  ClassName="    text-2xl font-bold mb-4 text-center">Créer un nouveau sujet</h1>
      <form onSubmit={handleSubmit}>
        <div className="  mb-4">
          <label className="  block text-sm font-bold mb-2" htmlFor="heading">
            Titre du sujet
          </label>
          <input
            type="text"
            id="heading"
            className="  shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
            required
          />
        </div>
        <div className="  mb-4">
          <label className="  block text-sm font-bold mb-2" htmlFor="description">
            Description
          </label>
          <textarea
            id="description"
            className="  shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={description}
            onChange={handleDescriptionChange}
            required
            rows="10"
          />
        </div>
        <div className="  mb-4">
          <label className="  block text-sm font-bold mb-2" htmlFor="images">
            Ajouter des images (jusqu'à 3)
          </label>
          <input
            type="file"
            id="images"
            className="  shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            onChange={handleImageChange}
            multiple
            accept="image/*"
          />
        </div>
        <div className="  mb-4">
          {images.length > 0 && (
            <div className="  flex flex-wrap -mx-2">
              {images.map((image, index) => (
                <div key={index} className="  w-1/2 md:w-1/4 p-2">
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`Preview ${index}`}
                    className="  w-full h-32 object-cover rounded shadow"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="  flex flex-col md:flex-row items-center justify-between">
          <button
            type="submit"
            className="  bg-lime-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Créer le sujet
          </button>
        </div>
      </form>
      {responseMessage && (
        <div className="  mt-4 p-2 bg-gray-100 border border-gray-300 rounded">
          {responseMessage}
        </div>
      )}
    </div>
  );
};

export default AddForum;
