import React, { useState, useEffect, useRef } from 'react';

const SearchableDropdown = ({
  options,
  selectedOption,
  setSelectedOption,
  fetchSuggestions,
  placeholder = 'Recherchez ou sélectionnez...',
  clearInput,
}) => {
  const [searchText, setSearchText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) => {
        const label = typeof option === 'string' ? option : option.label;
        return label.toLowerCase().includes(searchText.toLowerCase());
      })
    );
  }, [options, searchText]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const capitalizeFirstLetter = (text) =>
    text.charAt(0).toUpperCase() + text.slice(1);

  const handleSearchChange = (e) => {
    const text = e.target.value;
    const capitalizedText = capitalizeFirstLetter(text); // Capitalize the first letter
    setSearchText(capitalizedText);
    if (text.length >= 3 && fetchSuggestions) {
      fetchSuggestions(capitalizedText); // Fetch suggestions dynamically
    }
    setIsDropdownOpen(true);
  };

  useEffect(() => {
    if (clearInput) {
      setSearchText(''); // Clear the search text when clearInput is true
      setSelectedOption(''); // Reset the selected option
    }
  }, [clearInput, setSelectedOption]);

  const handleOptionSelect = (option) => {
    const label = typeof option === 'string' ? option : option.label;
    setSelectedOption(label);
    setSearchText(label);
    setIsDropdownOpen(false);
  };

  return (
    <div ref={dropdownRef} className="relative">
      <input
        type="text"
        value={searchText}
        onChange={handleSearchChange}
        placeholder={placeholder}
        className="w-full px-2 py-1 text-sm border border-gray-300 rounded-md"
        onFocus={() => setIsDropdownOpen(true)}
      />
      {isDropdownOpen && (
        <ul className="absolute z-10 bg-white border border-gray-300 rounded-md w-full max-h-32 overflow-y-auto text-sm">
          {filteredOptions.map((option, index) => {
            const label = typeof option === 'string' ? option : option.label;
            return (
              <li
                key={index}
                className="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleOptionSelect(option)}
              >
                {label}
              </li>
            );
          })}
          {filteredOptions.length === 0 && (
            <li className="px-2 py-1 text-gray-500">Aucun résultat trouvé</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SearchableDropdown;
