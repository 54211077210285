import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoEllipsisVertical, IoWarningOutline } from "react-icons/io5";
import { AiOutlineDelete } from "react-icons/ai"; 
import api from '../../services/api';

const CommentList = ({ comments }) => {
  if (!Array.isArray(comments) || comments.length === 0) {
    return <p className="text-gray-500 text-center">Aucun commentaire disponible</p>;
  }

  return (
    <div className="mt-4 space-y-4">
      {comments.map((comment) => (
        <CommentItem key={comment.id} comment={comment} />
      ))}
    </div>
  );
};

const CommentItem = ({ comment }) => {
  const [replies, setReplies] = useState([]);
  const [newReply, setNewReply] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReplies = async () => {
      try {
        const repliesData = await api.fetchReplies(comment.id);
        setReplies(Array.isArray(repliesData) ? repliesData : []);
      } catch (error) {
        console.error('Erreur lors de la récupération des réponses :', error);
      }
    };

    fetchReplies();
  }, [comment.id]);

  const handleReplySubmit = () => {
    api.submitReply({ comment_id: comment.id, reply: newReply })
      .then(response => {
        if (response && response.success) {
          const newReplyObject = {
            id: response.reply_id,
            reply: newReply,
            professional_name: response.professional_name,
            profile_picture: response.profile_picture,
          };
          setReplies(prev => [...prev, newReplyObject]);
          setNewReply('');
        } else {
          console.error('Erreur lors de l\'envoi de la réponse :', response.message);
        }
      })
      .catch(error => {
        console.error('Erreur lors de l\'envoi de la réponse :', error);
      });
  };

  const handleViewProfile = (reply) => {
    if (reply && reply.user_id) {
      navigate(`/profile/specificUser?professional_id=${reply.user_id}`);
    }
  };

  const handleViewCommentProfile = () => {
    if (comment && comment.user_id) {
      navigate(`/profile/specificUser?professional_id=${comment.user_id}`);
    }
  };

  // Example "Report" handler
  const handleReport = () => {
    console.log('Commentaire signalé avec ID :', comment.id);
    setMenuOpen(false);
  };

  // Delete the comment
  const handleDeleteComment = async () => {
    setMenuOpen(false);

    if (window.confirm('Voulez-vous vraiment supprimer ce commentaire ?')) {
      try {
        // We do a direct fetch POST to delete_comment.php
        const response = await fetch("https://btobjob.com/delete_comment.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          credentials: "include", // Include session cookies if needed
          body: new URLSearchParams({
            comment_id: comment.id
          })
        });

        const result = await response.json();

        if (result.success) {
          setDeleted(true); // Hide the comment from the UI
        } else {
          alert(result.message || 'Erreur lors de la suppression du commentaire');
        }
      } catch (error) {
        console.error('Erreur lors de la suppression du commentaire :', error);
      }
    }
  };


  // If user just deleted, hide the comment
  if (deleted) return null;

  return (
    <div className="p-4 border rounded-lg shadow-sm bg-white flex flex-col space-y-3 relative">
      <div className="flex items-start">
        {comment.profile_picture ? (
          <img
            src={comment.profile_picture}
            alt={`Photo de profil de ${comment.professional_name}`}
            className="w-10 h-10 rounded-full mr-3"
          />
        ) : (
          <div className="w-10 h-10 bg-gray-300 rounded-full mr-3" />
        )}
        <div className="flex-1">
          <p
            onClick={handleViewCommentProfile}
            className="cursor-pointer font-semibold hover:underline"
          >
            {comment.professional_name || 'Anonyme'}
          </p>
          <p className="text-gray-700 text-sm">{comment.comment}</p>
          <p className="text-xs text-gray-500">{comment.created_at}</p>
        </div>
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="text-gray-500 sm:text-base text-sm"
        >
          <IoEllipsisVertical size={20} />
        </button>
      </div>

      {menuOpen && (
        <div className="absolute right-0 top-8 bg-white border rounded-md shadow-lg p-2 z-50">
          <button
            onClick={handleReport}
            className="flex items-center px-4 py-2 hover:bg-gray-100 w-full text-left text-red-500 text-sm"
          >
            <IoWarningOutline className="mr-2" /> Signaler
          </button>

          {/* Only show the Delete button if comment.canDelete == true */}
          {comment.canDelete && (
            <button
              onClick={handleDeleteComment}
              className="flex items-center px-4 py-2 hover:bg-gray-100 w-full text-left text-red-500 text-sm"
            >
              <AiOutlineDelete className="mr-2" /> Supprimer
            </button>
          )}
        </div>
      )}

      {/* Replies */}
      <div className="ml-10 mt-2 w-full">
        {replies.length > 0 ? (
          replies.map((reply) => (
            <div key={reply.id} className="flex items-start my-2">
              {reply.profile_picture ? (
                <img
                  src={reply.profile_picture}
                  alt={`Photo de profil de ${reply.professional_name}`}
                  className="w-8 h-8 rounded-full mr-2"
                />
              ) : (
                <div className="w-8 h-8 bg-gray-300 rounded-full mr-2" />
              )}
              <div>
                <p
                  onClick={() => handleViewProfile(reply)}
                  className="cursor-pointer font-semibold text-sm hover:underline"
                >
                  {reply.professional_name || 'Anonyme'}
                </p>
                <p className="text-gray-700 text-sm">{reply.reply}</p>
              </div>
              <p className="ml-3 text-xs sm:text-sm text-gray-500">
                {reply.created_at}
              </p>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-sm">Aucune réponse disponible</p>
        )}
      </div>

      {/* Reply Input */}
      <div className="ml-10 mt-2 w-full">
        <textarea
          className="w-72 lg:w-2/3 p-2 border rounded-md text-sm"
          rows="2"
          value={newReply}
          onChange={e => setNewReply(e.target.value)}
          placeholder="Écrire une réponse..."
        />
        <button
          className="mt-1 px-4 py-1 bg-lime-500 text-white rounded-md text-sm"
          onClick={handleReplySubmit}
        >
          Répondre
        </button>
      </div>
    </div>
  );
};

export default CommentList;
