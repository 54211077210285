import React from 'react';
import { PiTelegramLogoLight, PiWhatsappLogoLight } from "react-icons/pi";
import { MdAlternateEmail } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";

const Share = ({ profileUrl, closeModal, itemType }) => {
    
    const getTitle = () => {

        
        return itemType === 'business-card' ? "Check out my business card" : "Check out my flyer";
    };

    const openShareLink = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (!newWindow) window.location.href = url;
    };

    // Prepare the URL with the item type for sharing
    const shareUrlWithMessage = `${profileUrl}&itemType=${itemType}`;

    // Update the copyToClipboard function to include the itemType in the URL
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(shareUrlWithMessage);
            alert('Link copied to clipboard with item type!');
        } catch (error) {
            alert('Unable to copy link. Please copy it manually.');
        }
    };

    const shareViaEmail = () => {
        const emailBody = encodeURIComponent(`${getTitle()} at ${shareUrlWithMessage}`);
        const url = `mailto:?subject=${getTitle()}&body=${emailBody}`;
        openShareLink(url);
    };

    const shareViaWhatsApp = () => {
        const url = `https://wa.me/?text=${encodeURIComponent(`${getTitle()} at ${shareUrlWithMessage}`)}`;
        openShareLink(url);
    };

    const shareViaTelegram = () => {
        const url = `https://t.me/share/url?url=${encodeURIComponent(shareUrlWithMessage)}&text=${getTitle()}`;
        openShareLink(url);
    };

    const shareViaFacebook = () => {
        const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrlWithMessage)}`;
        openShareLink(url);
    };

    const shareViaTwitter = () => {
        const url = `https://twitter.com/intent/tweet?text=${getTitle()}&url=${encodeURIComponent(shareUrlWithMessage)}`;
        openShareLink(url);
    };

    const shareViaLinkedIn = () => {
        const url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrlWithMessage)}`;
        openShareLink(url);
    };

    return (
        <div className="fixed inset-0 bg-black/50 flex justify-center items-center z-10">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[400px] relative">
                <div className="absolute top-4 right-4 text-gray-500 cursor-pointer" onClick={closeModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                <h2 className="text-lg font-semibold mb-4 text-center">Share With</h2>
                <div className="flex justify-around mb-6 flex-wrap gap-4">
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaEmail}>
                        <div className="bg-gray-200 rounded-full p-4">
                            <MdAlternateEmail className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">Email</span>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaWhatsApp}>
                        <div className="bg-gray-200 rounded-full p-4">
                            <PiWhatsappLogoLight className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">WhatsApp</span>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaTelegram}>
                        <div className="bg-gray-200 rounded-full p-4">
                            <PiTelegramLogoLight className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">Telegram</span>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaTwitter}>
                        <div className="bg-gray-200 rounded-full p-4">
                            <FaTwitter className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">Twitter</span>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaFacebook}>
                        <div className="bg-gray-200 rounded-full p-4">
                            <FaFacebook className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">Facebook</span>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaLinkedIn}>
                        <div className="bg-gray-200 rounded-full p-4">
                            <FaLinkedin className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">LinkedIn</span>
                    </div>
                </div>
                <div className="text-center mb-4 text-gray-500">Copy Link</div>
                <div className="flex items-center justify-between bg-gray-100 p-2 rounded-lg">
                    <input
                        type="text"
                        value={profileUrl}
                        className="bg-gray-100 text-gray-700 text-sm w-full focus:outline-none"
                        readOnly
                    />
                    <button className="bg-red-100 text-red-500 p-2 rounded-full ml-2" onClick={copyToClipboard}>
                        <IoShareSocialOutline className="h-6 w-6" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Share;
