import React, { useState, useEffect } from "react";
import User1 from '../assests/people1.png';
import User2 from '../assests/people2.png';
import User3 from '../assests/people3.png';
import User4 from '../assests/people4.png';
import User5 from '../assests/people5.png';
import User6 from '../assests/people6.png';
import User7 from '../assests/people7.png';
import User8 from '../assests/people8.png';
import User9 from '../assests/people9.png';
import User10 from '../assests/people10.png';
import User11 from '../assests/people11.png';
import User12 from '../assests/people12.png';
import { Link, useNavigate } from "react-router-dom";
import icoon1 from "../assests/imggg/icoon1.png"
import icoon2 from "../assests/imggg/icoon2.png"
import icoon3 from "../assests/imggg/icoon3.png"
import api from "../services/api";
import axios from "axios";
import LoginModal from "./loginmodal";

const Users = () => {
  const names = [
    'ent immobillier',
    'Carrossier Peintre',
    'Community Manager',
    'Dave',
    'Eve',
    'Frank',
    'Grace',
    'Helen',
    'Isaac',
    'Judy',
    'Kyle',
    'Lila'
  ];

  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const NavigateToChoice = () => {
    navigate("/professionaluser");
  };

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await api.getSession();
        if (response.user) {
          setUser(response.user);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error("Error fetching session:", error);
        setUser(null);
      }
    };
    fetchSession();
  }, []);

  const handlePostAnnounce = (e) => {
    e.preventDefault();
    if (!user) {
      setShowModal(true);
    } else {
      window.location.href = "https://btobjob.com/boost.php";
    }
  };

  return (
    <div className="ml-0 mt-32 bg-[#FFF] sm:ml-10">
      <div className="translatable-text text-center mt-5 px-0 sm:px-72 mb-12">
        <h2
          className="text-center text-3xl leading-[40px] font-semibold sm:text-[60px] sm:leading-[50px] translatable-text mb-5"
        >
          Trouvez
          <span className="span-les-travaux-en"> le professionnel </span>
          <br />
          dont vous avez besoin!
        </h2>
        <p className="text-center text-xl sm:text-2xl leading-relaxed">
          Bienvenue sur notre plateforme, votre destination ultime pour trouver
          et embaucher des professionnels qualifiés pour tous vos besoins en
          services. Que vous recherchiez un plombier, un architecte, un
          électricien, un mécanicien, un secrétaire ou tout autre expert, nous
          avons la solution parfaite pour vous.
        </p>
      </div>
      <div className="flex justify-center mb-10">
        <button
          className="cursor-pointer hover:text-[#65a406] bg-lime-500 text-white px-4 py-2 rounded"
          onClick={handlePostAnnounce}
        >
          Poster Une Annonce
        </button>
      </div>
      <div className="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-12">
        <div>
          <img src={User1} width={100} height={100}   className="ml-4 w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24" />
          <div className="text-xs">Agent immobilier</div>
        </div>
        <div>
          <img  className="users-image-setting ml-4 w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24" src={User2} width={100} />
          <div className="text-xs">Carrossier Peintre</div>
        </div>
        <div>
          <img src={User3} width={100} height={100}   className="ml-4 w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24" />
          <div className="text-xs">Community Manager</div>
        </div>
        <div>
          <img  className="users-image-setting ml-4 w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24" src={User4} width={100} />
          <div className="text-xs">Conciergerie</div>
        </div>
        <div>
          <img src={User5} width={100} height={100}   className="ml-4 w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24" />
          <div className="text-xs">Architecte d'intérieur</div>
        </div>
        <div>
          <img  className="users-image-setting ml-4 w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24" src={User6} width={100} />
          <div className="text-xs">Entreprise de sécurité</div>
        </div>
        <div>
          <img src={User7} width={100} height={100}   className="ml-4 w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24" />
          <div className="text-xs">Secrétaire polyvalent</div>
        </div>
        <div>
          <img  className="users-image-setting ml-4 w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24" src={User8} width={100} />
          <div className="text-xs">Nettoyeur automobile</div>
        </div>
        <div>
          <img src={User9} width={100} height={100}   className="ml-4 w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24" />
          <div className="text-xs">Décoratice d'intérieur</div>
        </div>
        <div>
          <img  className="users-image-setting ml-4 w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24" src={User10} width={100} />
          <div className="text-xs">Rénovation tout corps d'État</div>
        </div>
        <div>
          <img src={User11} width={100} height={100}   className="ml-4 w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24" />
          <div className="text-xs">Chauffer VTC</div>
        </div>
        <div>
          <img  className="users-image-setting ml-4 w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24" src={User12} width={100} />
          <div className="text-xs">Traiteur</div>
        </div>
      </div>

      <div className="flex flex-row gap-4 items-center mt-6 justify-center">
        {user ? (
          <div className="text-sm text-gray-700">{/* Bonjour, {user.name}! */}</div>
        ) : (
          <>
            <button className="md:hidden professional-navbar">
              <Link
                className="professional-navbar-link text-xs translatable-text"
                to="/login"
              >
                Se Connecter Particulier
              </Link>
            </button>
            <button className="md:hidden professional-navbar">
              <Link
                className="professional-navbar-link text-xs translatable-text"
                to="/login-pro"
              >
                Se Connecter Professionnel
              </Link>
            </button>
          </>
        )}
      </div>

      <div className="max-w-7xl mx-auto px-4 py-8 mt-10">
        <h1 className="text-5xl leading-[60px] font-bold text-center mb-8">
          Comment engager <span className="text-lime-500"> le bon professionnel</span>
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[icoon1, icoon2, icoon3].map((icon, index) => (
            <div key={index} className="flex flex-col items-center">
              <img src={icon} alt={`Step ${index + 1}`} className="w-48 h-48 object-cover mb-4" />
              <h2 className="text-xl text-gray-800 font-semibold mb-2">
                ÉTAPE {index + 1}
              </h2>
              <p className="text-gray-700 text-center font-bold text-2xl">
                {index === 0
                  ? "Publiez votre projet gratuitement"
                  : index === 1
                  ? "Les artisans vous répondent"
                  : "Consultez leurs profils et faites votre choix"}
              </p>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-10">
          <button
            className="cursor-pointer hover:text-[#65a406] bg-lime-500 text-white px-4 py-2 rounded"
            onClick={handlePostAnnounce}
          >
            Poster Une Annonce
          </button>
        </div>
      </div>
      {showModal && (
        <LoginModal
          onClose={() => setShowModal(false)}
          onSuccess={() => {
            setShowModal(false);
            window.location.href = "https://btobjob.com/boost.php";
          }}
        />
      )}
    </div>
  );
};

export default Users;
