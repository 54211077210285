import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaArrowLeft, FaFile } from "react-icons/fa";

const ViewRequestsAndAds = () => {
  const [requests, setRequests] = useState([]);
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null); // For modal
  const limit = 10;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRequestsAndAds = async () => {
      try {
        const response = await axios.get(
          `https://btobjob.com/get-requests-by-id.php?page=${page}&limit=${limit}`,
          { withCredentials: true }
        );

        const { privateRequests = [], ads = [], total = 0 } = response.data;
        setRequests(privateRequests);
        setAds(ads);
        setTotal(total);
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
        setLoading(false);
      }
    };

    fetchRequestsAndAds();
  }, [page]);

  const handleRequestClick = (userId) => {
    navigate(`/profile/specificUser?professional_id=${userId}`);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl); // Open modal with clicked image
  };

  const closeImageModal = () => {
    setSelectedImage(null); // Close modal
  };

  const handleNextPage = () => {
    if (page * limit < total) setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const isImageFile = (fileName) => {
    const imageExtensions = /\.(jpg|jpeg|png|gif|webp)$/i;
    return imageExtensions.test(fileName);
  };

  const handleImageError = (event) => {
    event.target.onerror = null; // Prevent infinite loop
    event.target.style.display = "none"; // Hide broken image
  };

  return (
    <div className="w-full max-w-2xl mx-auto p-4 sm:p-6 md:p-8 bg-white shadow-lg rounded-lg mt-10">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
      >
        <FaArrowLeft className="inline mr-2" /> Retour
      </button>

      {/* Title: Requests */}
      <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center text-gray-700">
        Demandes privées reçues
      </h2>

      {/* Loading State */}
      {loading ? (
        <p className="text-center">Chargement...</p>
      ) : (
        <>
          {/* Requests Section */}
          <div className="space-y-6">
            {requests.length === 0 ? (
              <p className="text-center">Aucune demande trouvée.</p>
            ) : (
              requests.map((request) => (
                <div
                  key={request.id}
                  className="p-4 border border-gray-300 rounded-lg"
                >
                  <h3 className="text-base sm:text-lg mb-2">
                    <b>Description : </b>
                    {request.description}
                  </h3>

                  {/* Info Grid */}
                  <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-4 mb-4">
                    <div>
                      <span className="font-semibold">Catégorie :</span>
                      <div>{request.category}</div>
                    </div>
                    <div>
                      <span className="font-semibold">Prix :</span>
                      <div>{request.price} €</div>
                    </div>
                    <div>
                      <span className="font-semibold">Adresse :</span>
                      <div>{request.address}</div>
                    </div>
                    <div>
                      <span className="font-semibold">Heure :</span>
                      <div>{request.created_at}</div>
                    </div>
                  </div>

                  {/* Files / Images */}
                  <div className="flex flex-wrap gap-4">
                    {[request.file1, request.file2, request.file3].map(
                      (file, index) =>
                        file && (
                          <div key={index} className="relative">
                            {isImageFile(file) ? (
                              <img
                                src={file}
                                alt={`File ${index + 1}`}
                                className="w-20 h-20 object-cover rounded-lg cursor-pointer"
                                onClick={() => handleImageClick(file)}
                                onError={handleImageError}
                              />
                            ) : (
                              <div
                                className="w-20 h-20 bg-gray-200 rounded-lg flex items-center justify-center text-gray-500 cursor-pointer"
                                onClick={() => handleImageClick(file)}
                              >
                                <FaFile size={24} />
                              </div>
                            )}
                          </div>
                        )
                    )}
                  </div>

                  {/* Profile Button */}
                  <button
                    onClick={() => handleRequestClick(request.user_id)}
                    className="bg-lime-500 text-white px-4 py-2 mt-4 rounded hover:bg-blue-600 transition duration-300"
                  >
                    Voir le profil
                  </button>
                </div>
              ))
            )}
          </div>

          {/* Ads Section */}
          <div className="space-y-6 mt-8">
            <h3 className="text-xl sm:text-2xl font-bold text-center text-gray-700">
              Annonces reçues
            </h3>

            {ads.length === 0 ? (
              <p className="text-center">Aucune annonce trouvée.</p>
            ) : (
              ads.map((ad) => (
                <div key={ad.id} className="p-4 border border-gray-300 rounded-lg">
                  <h3 className="text-base sm:text-lg font-bold mb-2">
                    {ad.title}
                  </h3>
                  <p>{ad.description}</p>

                  {/* Ad Images */}
                  {ad.images && (
                    <div className="flex flex-wrap gap-4 mt-2">
                      {ad.images.map((image, index) => (
                        <div className="relative" key={index}>
                          {isImageFile(image) ? (
                            <img
                              src={image}
                              alt={`Image d'annonce ${index + 1}`}
                              className="w-20 h-20 object-cover rounded-lg cursor-pointer"
                              onClick={() => handleImageClick(image)}
                              onError={handleImageError}
                            />
                          ) : (
                            <div
                              className="w-20 h-20 bg-gray-200 rounded-lg flex items-center justify-center text-gray-500 cursor-pointer"
                              onClick={() => handleImageClick(image)}
                            >
                              <FaFile size={24} />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}

                  <p className="text-sm mt-2 text-gray-500">
                    Soumis le : {ad.created_at}
                  </p>
                  <button
                    onClick={() => handleRequestClick(ad.user_id)}
                    className="bg-lime-500 text-white px-4 py-2 mt-4 rounded hover:bg-blue-600 transition duration-300"
                  >
                    Voir le profil
                  </button>
                </div>
              ))
            )}
          </div>
        </>
      )}

      {/* Pagination */}
      <div className="flex justify-between mt-6">
        <button
          onClick={handlePrevPage}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-300 disabled:opacity-50"
          disabled={page === 1}
        >
          Précédent
        </button>
        <button
          onClick={handleNextPage}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-300 disabled:opacity-50"
          disabled={page * limit >= total}
        >
          Suivant
        </button>
      </div>

      {/* Image Modal */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[99999999]">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-full max-h-full">
            <img
              src={selectedImage}
              alt="Full View"
              className="w-auto h-auto max-w-[88vw] max-h-[88vh] object-contain"
            />
            <button
              onClick={closeImageModal}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
            >
              Fermer
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewRequestsAndAds;
